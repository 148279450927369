import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Select } from "antd";
import { withTranslation } from "react-i18next";
import {DownOutlined} from "@ant-design/icons";

import availableLinkedRecordActions from "../../../../actions/availableLinkedRecordActions";
import { connect } from "../../../StateProvider";
import Icon from "../../../common/UI/Icon";
// import SelectWithFilter from '../common/UI/SelectWithFilter'

import styles from "./ObjectField.less";

const Option = Select.Option;

class SelectSource extends React.PureComponent {
  constructor(props) {
    super(props);
    let items = this.getItems();
    if (typeof this.props.itemsMapper === "function") {
      items = items.map(this.props.itemsMapper);
    }
    this.state = {
      text: "",
      loading: this.getLoadig(),
      items
    };
  }

  getType = (props = this.props) => [].concat(props.type); // convert to Array

  getItems = (props = this.props) => {
    return _(this.getType(props))
      .map(type =>
        props.dropdownCollections.getIn([type, this.props.storeKey, "items"])
      )
      .map(items => (items ? items.toJS() : []))
      .flatten()
      .value();
  };

  getLoadig = (props = this.props) => {
    return this.getType(props).some(type =>
      props.dropdownCollections.getIn([type, this.props.storeKey, "loading"])
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateStateItems(this.getItems(nextProps));
  }

  updateStateItems = items => {
    let noEmptyRemoteItems = !(
      items.length === 0 && this.state.items.length > 0
    );
    if (!_.isEqual(this.state.items, items) && noEmptyRemoteItems) {
      this.setState({
        items,
        loading: this.getLoadig()
      });
    }
  };

  onOpenChange = isOpen => {
    if (isOpen) {
      let params = _.extend(
        { title: this.state.text },
        this.props.requestParams
      );
      this.getType().forEach(type =>
        availableLinkedRecordActions.loadAvailableItems(
          type,
          this.props.storeKey,
          params
        )
      );
      this.setState({
        loading: true
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.onLoadItems &&
      this.state.items.length &&
      this.state.items !== prevState.items
    ) {
      this.props.onLoadItems(this.state.items);
    }
  }

  onChange = itemKey => {
    let items = this.state.items.concat(this.props.value);
    items = _.uniqBy(items, "key");

    const item = _.find(items, i => i.key == itemKey);

    this.props.onChange(item);
  };

  render() {
    const value = this.props.value;
    let items = this.state.items;
    if (value.key) {
      items = items.concat(value);
    }

    if (this.props.filterFn) {
      items = items.filter(this.props.filterFn);
    }

    let sortBy = this.props.sortBy;
    items = _.uniqBy(items, "key");
    if (sortBy === undefined || sortBy === true) {
      items = _.sortBy(items, "text");
    } else if (sortBy) {
      items = _.sortBy(items, sortBy);
    }

    return (
      <Select
        showSearch
        className={styles.select}
        value={this.props.value.key}
        onChange={this.onChange}
        filterOption={(inputValue, option) =>
          _.toLower(option.props.children).indexOf(_.toLower(inputValue)) >= 0
        }
        placeholder={this.props.placeholder}
        autocomplete={true}
        withButton={false}
        notFoundContent={this.props.t("dropdown.noitems")}
        onFocus={() => this.onOpenChange(true)}
        onBlur={() => this.onOpenChange(false)}
        autoFocus={this.props.autoFocus}
        disabled={this.props.disabled}
        getPopupContainer={() => document.getElementById("fieldListEditor")}
        suffixIcon={<DownOutlined />}
      >
        {items.map((item, i) => (
          <Option key={item.key}>
            <Icon className={styles.selectIcon} type={"icon " + item.icon} />
            {item.text}
          </Option>
        ))}
      </Select>
    );
  }
}

SelectSource.defaultProps = {
  storeKey: String(Math.random())
};

SelectSource.propTypes = {
  sortBy: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
    PropTypes.string
  ]),

  type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  // additionalItems: PropTypes.array,
  onLoadItems: PropTypes.func,
  filterFn: PropTypes.func,
  itemsMapper: PropTypes.func,
  inMapper: PropTypes.func,
  outMapper: PropTypes.func,
  requestParams: PropTypes.object,
  blockForceUpdateForEmpty: PropTypes.bool,
  storeKey: PropTypes.string.isRequired
};

export default withTranslation()(
  connect(
    SelectSource,
    ["dropdownCollections"]
  )
);
