import React from "react";
import { Form, Input, Button, message } from "antd";
import cn from "classnames";
import { withTranslation } from 'react-i18next';

import { NOT_FOUND_STATUS_404 } from '../helpers/const';
import LoadingSpin from "../../common/LoadingSpin";
import styles from "../styles.less";

const SETPASS_FIELDS = {
  PASSWORD: "password",
  PASSWORD_REPEAT: "passwordRepeat"
};

class SetPassForm extends React.Component {
  constructor(_props) {
    super();

    this.state = {
      loading: false,
      token: _props.token,
    };
  }

  validatePassword(password, password2) {
    const { t } = this.props;
    if (!(password === password2)) {
      message.error(t("auth.errors.notEqual"));
      return false;
    }
    if (!(password.length > 5)) {
      message.error(t("auth.errors.minLength"));
      return false;
    }
    return true;
  }

  handleSubmit = values => {
    if (!this.validatePassword(values.password, values.passwordRepeat)){
      return;
    }

    this.setState({ loading: true });
    this.props.onPasswordSet(values.password).then(response => {
      this.setState({ loading: false });
    })["catch"](err => {
      this.setState({ loading: false });
      console.log(err.message);
      if (err.status === NOT_FOUND_STATUS_404) {
        message.error(this.props.t("auth.errors.tokenNotFound"));
      } else {
        message.error(err.message);
      }
    });
  };

  render() {
    const { t } = this.props;
    const { loading } = this.state;
    return (

      <Form 
        name="setPass"
        className={cn(styles.form, styles.setForm)} 
        onFinish={this.handleSubmit}
      >
        <h1>{this.props.title}</h1>
        <div className={styles.itemGroup}>
          <Form.Item
            name={SETPASS_FIELDS.PASSWORD}
            className={cn(styles.formItem)}
            rules={[
              { required: true, message: t("auth.fieldHintMessage") }
            ]}
          >
            <div>
              <div className={styles.formLabel}>{t("auth.password")}</div>
              <Input.Password
                disabled={loading}
                type="password"
                className={styles.formField}
                placeholder=""
              />
            </div>
          </Form.Item>
          <Form.Item
            name={SETPASS_FIELDS.PASSWORD_REPEAT}
            className={cn(styles.formItem)}
            dependencies={[SETPASS_FIELDS.PASSWORD]}
            rules={[
              { required: true, message: t("auth.fieldHintMessage") },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue(SETPASS_FIELDS.PASSWORD) === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t("auth.errors.notEqual")));
                },
              }),
            ]}
          >
            <div>
              <div className={styles.formLabel}>
                {t("auth.repeatPassword")}
              </div>
              <Input.Password
                disabled={loading}
                type="password"
                className={styles.formField}
                placeholder=""
              />
            </div>
          </Form.Item>
        </div>

        <Form.Item className={styles.createCompanyButton}>
          <Button
            disabled={loading}
            tabIndex={0}
            key="submit"
            type="primary"
            className={styles.loginButton}
            htmlType="submit"
          >
            {t("auth.create")}
          </Button>
          {loading && <div>
            <LoadingSpin spin={true} />{" "}{t("loadingText")}
          </div>}
        </Form.Item>
      </Form>
    );
  }
}

export default withTranslation()(SetPassForm);
