import _ from "lodash";
import React from "react";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import { Responsive, WidthProvider } from "react-grid-layout";

import reportsActions from "../../../actions/reports";
import appActions from "../../../actions/appActions";
import Loading from "../../common/Loading";
import { renderModaltoBody } from "../../common/Modal";

import Widget from "./Widget";
import AddWidget from "./addWidget";
import EditWidgetPopup from "./popup/edit";
import canEditBoard from "../canEditBoard";

import {
  XS_WIDTH,
  XXS_WIDTH,
  SM_WIDTH,
  MD_WIDTH
} from "../../../configs/reports/colsWidth";
import { ADD_WIDGET } from "../../../configs/reports/widget/types";

import cssHandler from "./Widget/widget.less";

const ResponsiveReactGridLayout = Responsive;

const LAYOUT_SETTINGS = {
  COLS_WIDTH: { lg: 1440, md: 1440, sm: 1200, xs: 600, xxs: 0 },
  COLS: {
    lg: MD_WIDTH,
    md: MD_WIDTH,
    sm: SM_WIDTH,
    xs: XS_WIDTH,
    xxs: XXS_WIDTH
  },
  MARGIN: [20, 20],
  CONTAINER_PADDING: [10, 10],
  ROW_HEIGHT: 50
};

const FullWrapper = props => {
  const padding = LAYOUT_SETTINGS.CONTAINER_PADDING[0 || 1];
  return (
    <div
      style={{
        position: "absolute",
        top: padding,
        bottom: padding,
        right: padding,
        left: padding
      }}
    >
      {props.content}
    </div>
  );
};

class Board extends React.PureComponent {
  constructor(props) {
    super(props);
    this.board = React.createRef();
    this.state = {
      dragging: false,
      moving: false,
      readonly: this.getReadonlyFlag(),
      fullWidgets: []
    };
  }

  isFull = () => {
    const widgets = this.props.board.getIn(["widgets", "list"]);
    let res = false;
    if (widgets) {
      res = widgets
        .toJS()
        .some(widget => this.state.fullWidgets.includes(widget.uid));
    }
    return res;
  };

  addFullWidgetUid = uid => {
    this.scrollTop = this.board.current.scrollTop;
    const fullWidgets = this.state.fullWidgets.concat();
    fullWidgets.push(uid);
    this.setState({ fullWidgets });
  };
  removeFullWidgetUid = uid => {
    let fullWidgets = this.state.fullWidgets.concat();
    fullWidgets = fullWidgets.filter(fullUid => fullUid !== uid);
    this.setState({ fullWidgets });
  };

  onGridChange = (gridLayout, grid) => this.saveGrid(grid);
  // todo clear update interval to don't update board while user move widgets

  componentWillReceiveProps(nextProps) {
    const { board: prevBoard, catalog: prevCatalog } = this.props;
    const { board: nextBoard, catalog: nextCatalog } = nextProps;

    if (prevBoard.get("id") !== nextBoard.get("id")) {
      this.load(nextBoard);
    }

    if (prevCatalog.get("privilegeCode") !== nextCatalog.get("privilegeCode")) {
      this.setState({
        readonly: this.getReadonlyFlag(nextCatalog)
      });
    }
  }

  load = (board = this.props.board) => {
    const boardId = board.get("id");
    reportsActions.getBoardWithWidgets(boardId);
  };

  saveGrid = grid => {
    if (this.state.readonly) {
      return;
    }

    const { board } = this.props;
    const boardId = board.get("id");
    reportsActions.updateBoardGrid(boardId, grid);
  };

  getReadonlyFlag = (catalog = this.props.catalog) => {
    return !canEditBoard(catalog);
  };

  _onDragStart = (props = {}) => {
    this.setState({
      dragging: true,
      ...props
    });
    appActions.startDragging();
  };

  onDragStart = e => this._onDragStart();

  onMoveStart = e => this._onDragStart({ moving: true });

  onDragStop = e => {
    setTimeout(() => {
      this.setState({
        dragging: false,
        moving: false
      });
    }, 100);
    appActions.stopDragging();
  };

  componentDidUpdate() {
    if (this.board.current && !this.isFull() && this.scrollTop) {
      this.board.current.scrollTop = this.scrollTop;
      this.scrollTop = null;
    }
  }

  componentDidMount() {
    this.load();
  }

  render() {
    const { catalog, scene, board, fullScreen, width, viewId } = this.props;
    const { readonly, dragging, moving, fullWidgets } = this.state;
    const widgets = board.getIn(["widgets", "list"]) || Immutable.List();
    const loadingWidgets =
      board.getIn(["widgets", "loading"], true) && !(widgets && widgets.size);
    const loadingBoard = board.get("loading") && !board.get("loaded");

    const license =
      this.props.modules &&
      this.props.modules.findIndex(code => code === "reports") > -1
        ? {}
        : null;
    if (loadingWidgets || loadingBoard) {
      return <Loading />;
    }

    const notEditable = readonly || dragging || fullScreen.active;
    let boardContent = [];
    const fullWidget = widgets
      .toArray()
      .find(widget => fullWidgets.some(uid => widget.get("id") === uid));

    const widgetData = {
      addFullWidgetUid: this.addFullWidgetUid,
      removeFullWidgetUid: this.removeFullWidgetUid,
      isFull: false,
      widget: null,
      catalog,
      board,
      readonly,
      editable: !notEditable,
      moving,
      license,
      viewId
    };

    if (fullWidget) {
      widgetData.widget = fullWidget;
      widgetData.isFull = true;
      boardContent = <Widget scene={scene} {...widgetData} />;
    } else {
      boardContent = widgets.toArray().map(widget => {
        widgetData.widget = widget;
        return (
          <div key={widget.get("uid")}>
            <Widget scene={scene} {...widgetData} />
          </div>
        );
      });

      if (!notEditable) {
        const widgetLayout = {
          x: 0,
          y: Infinity,
          w: width >= LAYOUT_SETTINGS.COLS_WIDTH.sm ? 4 : 1,
          h: 1,
          isResizable: false
        };

        boardContent.push(
          // in props no catalog because modal component not get changes on catalog changed
          <div key={ADD_WIDGET} data-grid={widgetLayout}>
            <AddWidget
              onClick={() =>
                renderModaltoBody(EditWidgetPopup, {
                  catalog,
                  scene,
                  board,
                  license
                })
              }
            />
          </div>
        );
      }
    }

    return (
      <div ref={this.board} className={this.props.className}>
        {!fullWidget ? (
          <ResponsiveReactGridLayout
            width={width}
            layouts={board.get("grid").toJS()}
            margin={LAYOUT_SETTINGS.MARGIN}
            containerPadding={LAYOUT_SETTINGS.CONTAINER_PADDING}
            breakpoints={LAYOUT_SETTINGS.COLS_WIDTH}
            cols={LAYOUT_SETTINGS.COLS}
            rowHeight={LAYOUT_SETTINGS.ROW_HEIGHT}
            onLayoutChange={this.onGridChange}
            isDraggable={!readonly}
            isResizable={!readonly}
            onDragStart={this.onMoveStart}
            onResizeStart={this.onDragStart}
            onDragStop={this.onDragStop}
            onResizeStop={this.onDragStop}
            draggableHandle={`.${cssHandler.widget}`}
            // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
            // and set `measureBeforeMount={true}`.
            useCSSTransforms={board.getIn(["widgets", "loaded"], false)}
          >
            {boardContent}
          </ResponsiveReactGridLayout>
        ) : (
          <FullWrapper content={boardContent} />
        )}
      </div>
    );
  }
}

Board.propTypes = {
  board: ImmutablePropTypes.map.isRequired
};

export default WidthProvider(Board);
