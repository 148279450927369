import { message } from "antd";
import i18next from "i18next";
import sceneActions from "../../actions/sceneActions";
import widgets from "./widgets";

const v1 = {
  record: {
    open(catalogId, recordId, callbacks) {
      if (
        catalogId.hasOwnProperty("catalogId") &&
        catalogId.hasOwnProperty("recordId")
      ) {
        recordId = catalogId["recordId"];
        catalogId = catalogId["catalogId"];
      }

      if (!catalogId || !recordId) {
        message.error(i18next.t("modals.loadRecordError.headerText"));
      } else {
        sceneActions.openRecord(
          {
            catalogId: String(catalogId),
            recordId: String(recordId)
          },
          callbacks
        );
      }
    },
    create(catalogId, values, callbacks) {
      if (catalogId.hasOwnProperty("values")) {
        values = catalogId["values"];
      }
      if (catalogId.hasOwnProperty("catalogId")) {
        catalogId = catalogId["catalogId"];
      }

      if (!catalogId) {
        message.error(i18next.t("modals.createRecordError.headerText"));
      } else {
        sceneActions.openNewRecord(
          { catalogId: String(catalogId) },
          values,
          callbacks
        );
      }
    }
  },
  widgets
};

const api = { v1 };

Object.assign(window, {
  bpium: { api }
});
