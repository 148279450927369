import React from "react";

import ResetPassForm from "./ui/ResetPassForm";
import styles from "../styles.less";

const ResetPassPage = () => {
  return (
    <div className={styles.formContainer}>
      <div className={styles.formInner}>
        <ResetPassForm />
      </div>
    </div>
  );
};

export default ResetPassPage;
