import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import routes from "../../../../routes";
import Icon from "../../../common/UI/Icon";
import mapRouteParams from "../../../common/router/mapRouteParams";
import ButtonTransparent from "../../../common/UI/ButtonTransparent";

import styles from "./companies.less";

const CatalogItem = props => {
  const {
    sectionCatalog,
    isFavorite,
    onFavoriteClick,
    hidePopover,
    catalogId: currentCatalogId
  } = props;
  const { t } = useTranslation();

  const catalogId = sectionCatalog.get("id");
  const sectionId = sectionCatalog.get("sectionId");

  const _onFavoriteClick = (e, catalogId) => {
    e.preventDefault();
    e.stopPropagation();
    props.onFavoriteClick(catalogId);
  };

  return (
    <li
      className={cn(styles.catalogItem, {
        [styles.catalogItemSelected]: currentCatalogId === catalogId
      })}
    >
      <Link
        onClick={hidePopover}
        className={cn(styles.catalogLink, {
          [styles.favoriteCatalogText]: isFavorite
        })}
        to={`/section/${sectionId}/catalog/${catalogId}/view/0/records`}
      >

        <Icon
          className={cn(styles.catalogIcon, {
            [styles.favoriteCataloIcon]: isFavorite
          })}
          type={"icon " + sectionCatalog.get("icon")}
        />

        {sectionCatalog && sectionCatalog.get("name")}

        <ButtonTransparent
          title={
            isFavorite
              ? t("menuPopover.removeFavorite")
              : t("menuPopover.addFavorite")
          }
          onClick={(e) => _onFavoriteClick(e, catalogId)}
          className={cn(styles.favoriteIcon, {
            [styles.favoriteIconSelected]: isFavorite
          })}
        >
          <Icon type="icon vote-38" />
        </ButtonTransparent>

      </Link>
    </li>
  );
};

CatalogItem.propTypes = {
  sectionCatalog: PropTypes.object,
  isFavorite: PropTypes.bool,
  onFavoriteClick: PropTypes.func,
  hidePopover: PropTypes.func
};

export default mapRouteParams(CatalogItem, routes.catalog);
