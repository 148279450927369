import React from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import _ from "lodash";
import { matchPath, withRouter } from "react-router";
import { withTranslation } from "react-i18next";

import apiActions from "../../../actions/apiActions";
import modalsActions from "../../../actions/modalsActions";
import { confirm } from "../../common/Modal";
import getLink from "../../common/router/getLink";
import routes from "../../../routes";

import PRIVILEGE_CODES from "../../../configs/privilegeCodes";
import RESOURCE_TYPES from "../../../configs/resourceTypes";
import { checkAccessOnObject } from "../../../utils/rights";

import Header from "../Header";

class RecordHeader extends React.Component {
  static propTypes = {
    record: PropTypes.object.isRequired,
    catalog: PropTypes.object.isRequired,
    scene: PropTypes.object.isRequired,
    hasBeenEdit: PropTypes.bool.isRequired,
    onRefresh: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    onCreateRecord: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    isWebForm: PropTypes.bool,
    isNew: PropTypes.bool,
    isLoading: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  getExtParams = (props = this.props) => {
    const { record, catalog } = props;
    return {
      sectionId: catalog.get("sectionId"),
      catalogId: catalog.get("id"),
      recordId: record.get("isNew") ? "$new" : record.get("id")
    };
  };

  onClickAccess = () => {
    let recordId = this.props.record.get("id");
    let catalogId = this.props.catalog.get("id");
    if (catalogId && recordId) {
      let isAdmin = checkAccessOnObject(
        RESOURCE_TYPES.RECORD,
        this.props.record,
        PRIVILEGE_CODES.ADMIN
      );
      let readOnly = !checkAccessOnObject(
        RESOURCE_TYPES.RECORD,
        this.props.record,
        PRIVILEGE_CODES.ACCESS
      );
      let object = { catalogId, recordId };
      let parents = [
        {
          sectionId: this.props.catalog.get("sectionId")
        },
        {
          catalogId
        }
      ];
      modalsActions.openAccessModal(
        { object, parents },
        RESOURCE_TYPES.RECORD,
        { readOnly, isAdmin }
      );
    }
  };

  onRemove = () => {
    const { scene, t } = this.props;
    const sceneId = scene && scene.get("sceneId");

    confirm({
      headerText: t("modals.removeRecordConfirm.header"),
      text: t("modals.removeRecordConfirm.text"),
      okText: t("modals.removeRecordConfirm.okText"),
      cancelText: t("modals.removeRecordConfirm.cancelText"),
      onOk: () => {
        apiActions
          .deleteRecord(
            {
              catalogId: this.props.catalog.get("id"),
              recordId: this.props.record.get("id")
            },
            {
              sceneId,
              viewId: this.props.match && this.props.match.params.viewId
            }
          )
          .then(() => {
            this.props.onDelete && this.props.onDelete(sceneId);
          });
      }
    });
  };

  onClose = () => {
    const { scene } = this.props;
    const sceneId = scene && scene.get("sceneId");

    this.props.onClose && this.props.onClose(sceneId);
  };

  componentDidMount() {
    const extParams = this.getExtParams && this.getExtParams();

    const match =
      matchPath(this.props.location.pathname, {
        path: routes.record.path,
        exact: true,
        strict: false
      }) ||
      matchPath(this.props.location.pathname, {
        // For WebFrom and Modals
        path: "/",
        exact: true,
        strict: false
      });
    // If not select one of tab
    if (match) {
      this.props.history.push(
        getLink(this.props.location, routes.recordMain, extParams)
      );
    }
  }

  componentDidUpdate(prevProps) {
    const extParams = this.getExtParams && this.getExtParams();

    const newRecordId = this.props.record.get("id");
    const prevRecordId = prevProps.record.get("id");
    if (
      newRecordId !== prevRecordId ||
      matchPath(this.props.location.pathname, {
        // if was click repeated on selected record
        path: routes.record.path,
        exact: true,
        strict: false
      })
    ) {
      this.props.history.push(
        getLink(this.props.location, routes.recordMain, extParams)
      );
    }
  }

  render() {
    const {
      record,
      isWebForm,
      scene,
      catalog,
      match,
      history,
      hasBeenEdit,
      onRefresh,
      onCreateRecord,
      onSave,
      isLoading,
      t
    } = this.props;

    const isNew = record.get("isNew");
    const headerText = isNew ? t("record.newRecord") : record.get("title");

    // tabs
    let tabs = Immutable.List([
      Immutable.Map({
        id: "main",
        name: t("record.tabs.main"),
        route: routes.recordMain
      }),
      Immutable.Map({
        id: "links",
        name: t("record.tabs.links"),
        route: routes.recordLinks
      }),
      Immutable.Map({
        id: "history",
        name: t("record.tabs.history"),
        route: routes.recordHistory
      })
    ]);
    return (
      <Header
        tabs={tabs}
        record={record}
        catalog={catalog}
        scene={scene}
        viewId={match && match.params.viewId}
        hasBeenEdit={hasBeenEdit}
        withTabsMenu={!isNew}
        isWebForm={isWebForm}
        headerText={headerText}
        getExtParams={this.getExtParams}
        onClose={this.onClose}
        //
        history={history}
        onSave={onSave}
        isLoading={isLoading}
        onRefresh={onRefresh}
        onRemove={this.onRemove}
        onClickCreate={onCreateRecord}
        onClickAccess={this.onClickAccess}
        onClear={this.props.onClear}
      />
    );
  }
}

export default withTranslation()(withRouter(RecordHeader));
