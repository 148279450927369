import React from "react";
import { withRouter } from "react-router";

import sceneActions from "../../../../../actions/sceneActions";
import SCENE_CONTAINER from "../../../../../configs/sceneContainer";
import Catalog from "./Catalog";

class CatalogSceneController extends React.Component {
  state = {};
  componentDidMount() {
    this.createScene();
  }

  componentDidUpdate(prevProps, prevState) {
    /* при смене каталога удаляем предыдущую сцену и создаем новую */
    const prevCatalogId = prevProps.match.params.catalogId;
    const currentCatalogId = this.props.match.params.catalogId;

    if (prevCatalogId !== currentCatalogId) {
      sceneActions.deleteScene(prevState.sceneId);
      this.createScene();
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    // rerender происходит в случае, если стейт не изменился
    return nextState.sceneId === this.state.sceneId;
  }
  componentWillUnmount() {
    sceneActions.deleteScene(this.state.sceneId);
  }

  getSceneId = ({ sceneId }) => {
    this.setState({
      sceneId: sceneId
    });
  };

  createScene = () => {
    const params = {
      catalogId: this.props.match.params.catalogId
    };

    const data = {};

    sceneActions.openCatalog(
      params,
      data,
      { onCreate: this.getSceneId },
      SCENE_CONTAINER.WINDOW
    );
  };

  render() {
    return this.state && this.state.sceneId ? (
      <Catalog sceneId={this.state.sceneId} {...this.props} />
    ) : null;
  }
}

export default withRouter(CatalogSceneController);
