import React, { Component } from "react";
import cn from "classnames";
import Immutable from "immutable";
import { withTranslation } from "react-i18next";
import { Button } from "antd";
import _ from "lodash";

import apiActions from "../../actions/apiActions";
import sceneActions from "../../actions/sceneActions";
import SCENE_CONTAINER from "../../configs/sceneContainer";
import ModalSceneContainer from "../common/Modal/MultiModal/ModalSceneContainer";

import * as ROUTE_PARAMS from "../../configs/routes";
import { connect } from "../StateProvider";
import Icon from '../common/UI/Icon';
import Logo from "../App/AppHeader/Logo";
import { confirm } from "../common/Modal";
import Timer from "../common/Timer";

import styles from "./webform.less";

const BpiumLogo = ({ site, wrapperClassName, className }) => {
  return site ? (
    <a href={"http://" + site} target="_blank" className={wrapperClassName}>
      <Logo className={className} />
    </a>
  ) : null;
};
const updateInterval = 10 * 30 * 100; // 100

class WebForm extends Component {
  state = {
    loading: true,
    isFormSend: false,
    nextUpdate: null
  };

  componentDidMount() {
    Promise.all([
      apiActions.getVendor(),
      apiActions.getProfile(),
      apiActions.getPrivileges(),
      apiActions.getCompanyInfo(),
      apiActions.getCatalogs()
    ]).then(() => {
      this.setState({
        loading: false
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const sceneId = this.state.sceneId;
    const scene = sceneId && this.props.scenes.get(sceneId);

    if (!scene && !this.state.loading && !this.state.isFormSend) {
      this.createScene();
    }
  }

  componentWillUnmount() {
    sceneActions.deleteScene(this.state.sceneId);
  }

  createScene = () => {
    if (this.props.action === ROUTE_PARAMS.ACTION_RECORD_NEW) {
      sceneActions.openNewRecord(
        { catalogId: this.props.catalog },
        {},
        {
          onCreate: this.getSceneId,
          onCreateRecord: this.onCreateRecord
        },
        SCENE_CONTAINER.WINDOW
      );
    } else if (this.props.action === ROUTE_PARAMS.ACTION_RECORD_OPEN) {
      sceneActions.openRecord(
        {
          catalogId: this.props.catalog,
          recordId: this.props.record
        },
        {
          onCreate: this.getSceneId
        },
        SCENE_CONTAINER.WINDOW
      );
    } else if (this.props.action === ROUTE_PARAMS.ACTION_CATALOG_OPEN) {
      sceneActions.openCatalog(
        { catalogId: this.props.catalog },
        undefined,
        {
          onCreate: this.getSceneId
        },
        SCENE_CONTAINER.WINDOW
      );
    }
  };
  sendAnotherForm = () => {
    const sceneId = this.state.sceneId;
    apiActions.getCatalog({ catalogId: this.props.catalog }).then(() => {
      this.setState({
        isFormSend: false,
        nextUpdate: null
      });
    });
    sceneActions.deleteScene(sceneId);
  };

  onClear = () => {
    const { t } = this.props;
    confirm({
      onOk: () => {
        const sceneId = this.state.sceneId;
        apiActions.getCatalog({ catalogId: this.props.catalog }).then(() => {
          sceneActions.deleteScene(sceneId);
        });
      },
      headerText: t("modals.closeEditor.headerTextClear"),
      text: t("modals.closeEditor.textClear"),
      okText: t("modals.closeEditor.okClear"),
      cancelText: t("modals.closeEditor.cancelClear")
    });
  };

  getSceneId = ({ sceneId, params }) => {
    this.setState({
      sceneId,
      recordId: params.recordId && params.recordId
    });
  };

  onCreateRecord = () => {
    this.setState({
      isFormSend: true,
      message: this.props.t("record.webform.success.message"),
      nextUpdate: Date.now() + updateInterval
    });
  };

  render() {
    const { sceneId, message, isFormSend } = this.state;
    const { screen, action, catalogsHaveBeenLoaded, site, t } = this.props;
    let { defaultViewMode } = this.props;
    const stylesContainer =
      screen == "full"
        ? styles.containerBackground
        : styles.containerBackgroundFull;
    let stylesFormContainer =
      screen == "full" ? styles.fullScreen : styles.defaultScreen;
    stylesFormContainer = cn(stylesFormContainer, styles[action]);

    // becouse we need loaded catalogs when we create new record
    if (!catalogsHaveBeenLoaded) {
      return null;
    }

    defaultViewMode = Immutable.Map({ value: defaultViewMode });

    return (
      <div className={stylesContainer}>
        <BpiumLogo
          site={site}
          wrapperClassName={styles.logoWrapper}
          className={styles.logo}
        />
        {!isFormSend ? (
          <div className={stylesFormContainer}>
            <ModalSceneContainer
              sceneId={sceneId}
              isActive={true}
              isWebForm={true}
              defaultViewMode={defaultViewMode}
              onClear={this.onClear}
            />
          </div>
        ) : (
          <div className={styles.message}>
            <Icon type="icon status-17" className={styles.messageIcon} />
            <h2 className={styles.messageText}>{message}</h2>
            <Button
              size="large"
              className={styles.buttonSend}
              onClick={this.sendAnotherForm}
            >
              {t("record.webform.success.action")}
              <Icon type="icon time-4" className={styles.timerIcon} />
              <Timer
                nextTime={this.state.nextUpdate}
                onAlarm={this.sendAnotherForm}
                format="s"
                className={styles.timer}
              />
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(
  connect(
    WebForm,
    {
      catalogs: ["catalogs"],
      catalogsLoading: ["catalogsLoading"],
      site: ["vendor", "site"],
      scenes: ["scenes"]
    },
    (props, { catalogs, catalogsLoading, site, scenes }) => {
      const catalogsHaveBeenLoaded =
        catalogs && catalogs.size && !catalogsLoading;
      return {
        catalogsHaveBeenLoaded,
        site,
        scenes,
        ...props
      };
    }
  )
);
