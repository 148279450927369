import _ from "lodash";
import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import cn from "classnames";
import { withTranslation } from 'react-i18next';

import ButtonClose from "../../../../../ButtonClose";
import { secToTime } from "./helpers/secToTime";

import styles from "./audio.less";

const log = require("debug")("CRM:Component:AudioViewer");

const playEvents = "play playing".split(" ");
const changePositionEvents = "timeupdate".split(" ");
const pauseEvents = "pause".split(" ");
const errorEvents = "error abort".split(" ");

const handlers = [];
const globalEvents = {
  trigger: id => handlers.forEach(fn => fn(id)),
  on: fn => handlers.push(fn),
  off: fn => _.remove(handlers, handler => fn === handler)
};

class Player extends React.PureComponent {
  constructor(props) {
    super(props);
    this.id = _.uniqueId();
    this.audio = new Audio();

    this.state = {
      playError: false,
      playErrorMessage: null,
      isPlaying: false,

      currentTime: 0,
      totalTime: 0,
      progress: 0
    };

    this.refBar = React.createRef(null);
  }

  play = () => {
    log("play start", this.props.file.id);

    if (!this.audio.src) {
      this.audio.src = this.props.file.url;
    }

    // тормозим другие плееры
    globalEvents.trigger(this.id);

    this.audio.play();
  };

  pause = () => {
    //log('play pause', this.props.file.id);
    this.audio.pause();
  };

  togglePlay = () => {
    if (this.state.isPlaying) {
      this.pause();
    } else {
      this.play();
    }
  };

  onPlay = () => {
    log("play onStart", this.props.file.id);

    this.setState({
      playError: false,
      isPlaying: true
    });
  };

  onPause = () => {
    log("play onPause", this.props.file.id);

    this.setState({
      isPlaying: false
    });
  };

  onError = () => {
    // todo correct handle this event, problem on replay the play

    let error = this.audio.error;
    let errorMsg = "";

    switch (error.code) {
      case error.MEDIA_ERR_ABORTED:
        errorMsg = "media err aborted";
        break;
      case error.MEDIA_ERR_NETWORK:
        errorMsg = "media err network";
        break;
      case error.MEDIA_ERR_DECODE:
        errorMsg = "media err decode";
        break;
      case error.MEDIA_ERR_SRC_NOT_SUPPORTED:
        errorMsg = "media err src not supported";
        break;
    }

    log("play error", this.props.file.id, error);

    this.setState({
      isPlaying: false,
      playError: true,
      playErrorMessage: errorMsg
    });
  };

  onGlobalPlay = id => {
    if (id !== this.id) {
      this.pause();
    }
  };

  updateTimeInState = () => {
    let currentTime = Math.floor(this.audio.currentTime);
    let totalTime = Math.floor(this.audio.duration);

    this.setState({
      currentTime: currentTime,
      totalTime: totalTime,
      progress: totalTime ? currentTime / totalTime : 0
    });
  };

  onPositionChange = _.throttle(() => this.updateTimeInState(), 1000);

  onBarMouseDown = e => {
    log("bar mouse down - begin seeking of new position");
    
    let bar = $(this.refBar.current);
    this.barWidth = bar.width();
    this.barLeftOffset = bar.offset().left;
    this.pause();

    $(document).on("mousemove.audio-viewer", this.onMouseMove);
    $(document).on("mouseup.audio-viewer", this.onMouseUp);
  };

  getProgress = mouseX => {
    return Math.min(
      Math.max(mouseX - this.barLeftOffset, 0) / this.barWidth,
      1
    );
  };

  onMouseUp = e => {
    $(document).off("mousemove.audio-viewer", this.onMouseMove);
    $(document).off("mouseup.audio-viewer", this.onMouseUp);

    let progress = this.getProgress(e.pageX);

    if (this.state.totalTime) {
      this.setState({
        progress: progress,
        currentTime: Math.round(this.state.totalTime * progress)
      });
      this.audio.currentTime = this.state.totalTime * progress;
      this.play();
    } else {
      this.setState({
        progress: progress
      });

      this.play();

      // если аудио еще не загружено, подписываемся на собиыте timeupdate именно там появляется длинна аудио
      // после устанавлеваем корректную позицию старта и возвращаем прежний слушатель
      // пс. можно было не сбрасывать текущих подписчиков :)
      let oldHandlers = changePositionEvents.map(
        event => this.audio["on" + event]
      );
      changePositionEvents.forEach(event => {
        this.audio["on" + event] = () => {
          if (this.audio.duration) {
            changePositionEvents.forEach(
              (event, i) => (this.audio["on" + event] = oldHandlers[i])
            );
            this.audio.currentTime = this.audio.duration * progress;
          }
        };
      });
    }
  };

  onMouseMove = _.throttle(e => {
    this.setState({
      progress: this.getProgress(e.pageX)
    });
  }, 20);

  onRemove = () => this.props.removeFn(this.props.file);

  componentDidMount() {
    globalEvents.on(this.onGlobalPlay);

    playEvents.forEach(
      eventName => (this.audio["on" + eventName] = this.onPlay)
    );
    pauseEvents.forEach(
      eventName => (this.audio["on" + eventName] = this.onPause)
    );
    errorEvents.forEach(
      eventName => (this.audio["on" + eventName] = this.onError)
    );
    changePositionEvents.forEach(
      eventName => (this.audio["on" + eventName] = this.onPositionChange)
    );
  }

  componentWillUnmount() {
    this.pause();

    globalEvents.off(this.onGlobalPlay);

    playEvents.forEach(eventName => (this.audio["on" + eventName] = null));
    pauseEvents.forEach(eventName => (this.audio["on" + eventName] = null));
    errorEvents.forEach(eventName => (this.audio["on" + eventName] = null));
    changePositionEvents.forEach(
      eventName => (this.audio["on" + eventName] = null)
    );
  }

  render() {
    const { t } = this.props;
    let totalTimeFormatted = secToTime(this.state.totalTime);
    let currentTimeFormatted = secToTime(
      this.state.currentTime,
      totalTimeFormatted
    );
    let progressFormatted = Math.round(this.state.progress * 10000) / 100 + "%";

    return (
      <div className={styles.audioPlayer}>
        <span
          className={cn(
            styles.audioPlayerControls,
            "anticon-icon " +
            (this.state.isPlaying ? "multimedia-72" : "multimedia-73"),
            { [styles.audioPlayerControlsDisabled]: this.state.playError }
          )}
          onClick={this.togglePlay}
        />

        <div
          className={cn(styles.audioPlayerTitle, {
            [styles.audioPlayerTitleError]: this.state.playError
          })}
          title={this.props.file.title}
        >
          <div className={styles.audioPlayerFileTitle}>
            {this.props.file.title}
          </div>

          {this.state.playError ? (
            <span
              className={styles.audioPlayerError}
              title={this.state.playErrorMessage}
            >
              {t("audioPlayer.error")}
            </span>
          ) : (
            <div className={styles.audioPlayerTiming}>
              <span>{currentTimeFormatted}</span>
              <span className={styles.audioPlayerTimingTotal}>
                {totalTimeFormatted}
              </span>
            </div>
          )}
        </div>

        <a
          href={this.props.file.url}
          target="_blank"
          className={cn(
            styles.audioPlayerDownloadBtn,
            "anticon-icon transfers-41"
          )}
          title={t("audioPlayer.download")}
        />

        {!this.props.readOnly && (
          <ButtonClose
            className={styles.audioButtonRemove}
            title={t("record.fields.file.remove")}
            small
            onClick={this.onRemove}
          />
        )}

        <div className={cn(styles.jouele, "jouele")}>
          <div className={cn(styles.joueleInfoArea, "jouele-info-area")}>
            <div className="jouele-time">
              <div className="jouele-play-time">{currentTimeFormatted}</div>
              <div className="jouele-total-time">{totalTimeFormatted}</div>
            </div>
          </div>
          <div
            className={cn(styles.joueleProgressArea, "jouele-progress-area")}
          >
            <div
              className={cn(styles.joueleMine, "jouele-mine")}
              onMouseDown={this.onBarMouseDown}
              ref={this.refBar}
            >
              <div
                className={cn(styles.joueleLoadBar, "jouele-load-bar")}
                style={{ width: "100%" }}
              />
              <div
                className={cn(styles.jouelePlayBar, "jouele-play-bar")}
                style={{ width: progressFormatted }}
              />
              <div
                className={cn(styles.jouelePlayLift, "jouele-play-lift")}
                style={{ left: progressFormatted }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedPlayer = withTranslation()(Player);

class AudioViewer extends React.Component {
  static viewerName = "AudioViewer";
  static priority = 2;

  render() {
    if (!this.props.files) {
      return null;
    }
    return (
      <div>
        {this.props.files.map((file, i) => {
          return <WrappedPlayer {...this.props} key={i} file={file} />;
        })}
      </div>
    );
  }
}

export default AudioViewer;
