// styles for fast compile
import "../../styles/App.less";
import "./configure";

import React, { Component } from "react";
import { HashRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import ruRu from "antd/lib/locale-provider/ru_RU";
import enEn from "antd/lib/locale-provider/en_US"
import { withTranslation } from "react-i18next";

import Layout from "./layout";
import getConfirmation from "../common/Modal/getConfirmation";

import "../../configs/i18n";

const locales = {
  'ru': ruRu,
  'en': enEn
}

class App extends Component {
  render() {
    const { language } = this.props.i18n
    return (
      <ConfigProvider locale={locales[language]}>
        <HashRouter
          getUserConfirmation={(message, callback) =>
            getConfirmation(message, callback, null, undefined, this.props.t)
          }
        >
          <Layout />
        </HashRouter>
      </ConfigProvider>
    );
  }
}

export default withTranslation()(App);
