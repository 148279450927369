// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".config__config___0DGSC {\n  display: flex;\n  align-items: center;\n}\n.config__buttonConfig___m6wes {\n  height: 34px;\n}\n.config__overlay___ogDk8 {\n  width: 238px;\n  padding: 10px;\n  padding-bottom: 10px;\n  background: #e6e8ec;\n}\n.config__controlRow___8ejoF {\n  margin-bottom: 15px;\n  display: block;\n}\n.config__controlLabel___vKpey {\n  display: inline-block;\n  margin-bottom: 2px;\n  color: #666b70;\n}\n.config__tag___nHB9N {\n  cursor: pointer;\n}\n.config__tagSelected___Ulij8 {\n  background-color: #5ca199;\n}\n.config__suggestRecordsIcon___bYDTO {\n  transform: scale(-1, 1);\n}\n.config__activeIcon___JkTNV {\n  color: #5ca199;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Calendar/Header/Config/config.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;AADF;AAIA;EACE,YAAA;AAFF;AAKA;EACE,YAAA;EACA,aAAA;EACA,oBAAA;EACA,mBAAA;AAHF;AAMA;EACE,mBAAA;EACA,cAAA;AAJF;AAMA;EACE,qBAAA;EACA,kBAAA;EACA,cAAA;AAJF;AAOA;EACE,eAAA;AALF;AAQA;EACE,yBAAA;AANF;AASA;EACE,uBAAA;AAPF;AAUA;EACE,cAAA;AARF","sourcesContent":["@import (reference) \"../../../../styles/helpers/index.less\";\n\n.config {\n  display: flex;\n  align-items: center;\n}\n\n.buttonConfig {\n  height: @RECORD_DATA_HEADER - 1px; // Otherwise, the border of the header is invisible\n}\n\n.overlay {\n  width: 238px;\n  padding: @PADDING_BASE;\n  padding-bottom: @PADDING_BASE;\n  background: @BG_PANELS;\n}\n\n.controlRow {\n  margin-bottom: @PADDING_BASE*1.5;\n  display: block;\n}\n.controlLabel {\n  display: inline-block;\n  margin-bottom: 2px;\n  color: @TEXT_SUB;\n}\n\n.tag {\n  cursor: pointer;\n}\n\n.tagSelected {\n  background-color: @TEXT_ACCENT;\n}\n\n.suggestRecordsIcon {\n  transform: scale(-1, 1);\n}\n\n.activeIcon {\n  color: @TEXT_ACCENT;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"config": "config__config___0DGSC",
	"buttonConfig": "config__buttonConfig___m6wes",
	"overlay": "config__overlay___ogDk8",
	"controlRow": "config__controlRow___8ejoF",
	"controlLabel": "config__controlLabel___vKpey",
	"tag": "config__tag___nHB9N",
	"tagSelected": "config__tagSelected___Ulij8",
	"suggestRecordsIcon": "config__suggestRecordsIcon___bYDTO",
	"activeIcon": "config__activeIcon___JkTNV"
};
export default ___CSS_LOADER_EXPORT___;
