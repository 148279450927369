import React from "react";
import Immutable from "immutable";
import _ from "lodash";

import FIELD_TYPES from "../../configs/fieldTypes";

export default class Button {
    static type = FIELD_TYPES.BUTTON;

    static components = {
        control: require("../../components/common/UI/ControlList/controls/Button").default
    };

    static getComponent = type => Button.components[type];

    static createComponent = (field, value, type) => {
        const Component = Button.components[type];
        return ({ containerClassName }) => {
            if (Button.isEmpty(value)) {
                return null;
            };
            return (
                <Component
                    config={field.get("config")}
                    value={value}
                    containerClassName={containerClassName}
                />
            );
        };
    };

    static convertFilterToRecordValue = () => undefined;

    static getDefaultValue = field => {
        const defaultValue = field.getIn(["config", "defaultEmptyValue"]);
        return defaultValue;
    };

    static validateRequired = (value) => !value;

    static validateValue = (value, field) => {
        if (value == null) {
            return true;
        } else {
            return false;
        };
    };

    static isEmpty = value => !value;

    static getEmptyValue = () => null;

}