import { plural } from "./plural";

/**
 * Prepare description object
 * for Activate page, display license information
 * TODO: Add trs for all russians fields
 */
export function prepareDescription(params, locale, t) {
  const users = Number(params.get("users")) || 0;
  const extUsers = Number(params.get("extUsers")) || 0;
  const records = Number(params.get("records")) || 0;
  const catalogs = Number(params.get("catalogs")) || 0;
  const expires = params.get("expires") || 0;

  const description = {
    title: t("auth.activate.license"),
    users: t("auth.activate.users.count", { count: users }),
    extUsers: extUsers
      ? t("auth.activate.extUsers.count", { count: extUsers })
      : "",
    records:
      t("auth.activate.records") +
      (records ? records : t("auth.activate.notLimited")),
    catalogs:
      t("auth.activate.catalogs") +
      (catalogs ? catalogs : t("auth.activate.notLimited")),
    active:
      t("auth.activate.active") +
      ": " +
      (expires
        ? new Date(expires).toLocaleDateString(locale, {
            month: "long",
            day: "numeric",
            year: "numeric"
          })
        : t("auth.activate.unlimited"))
  };
  return description;
}
