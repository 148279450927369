export default (recordsLimit, recordsCount) => {

    if (!recordsLimit || !recordsCount) {
        return {};
    };

    const recordsRemained = Math.max(Number(recordsLimit) -  Number(recordsCount), 0);
    const charge = recordsRemained / recordsLimit;
    const recordsRemainingPercentage = Math.round(charge * 100);
    let redTitlePercentage = false;
    
    if (recordsRemainingPercentage <= 10) {
        redTitlePercentage = true;
    };
     
    return {
        recordsRemained,
        recordsRemainingPercentage,
        redTitlePercentage
    };
};