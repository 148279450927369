import _ from "lodash";
import React from "react";
import appActions from "../../../actions/appActions";
import { connect } from "../../StateProvider";

import styles from "./appHeader.less";
class Item extends React.Component {
  trigger(methodName) {
    const { props, node } = this;
    const method = props.widget.get(methodName);
    if (method) {
      method(node);
    }
  }

  componentDidMount() {
    this.trigger("mount");
  }

  componentWillUnmount() {
    this.trigger("unmount");
  }

  render() {
    return <div ref={node => (this.node = node)} />;
  }
}

class Widgets extends React.Component {
  componentDidUpdate(prevProps) {
    const { extensions: prevExtensions } = prevProps;
    const { extensions } = this.props;

    if (prevExtensions !== extensions) {
      appActions.loadExtensions();
    }
  }

  render() {
    const { headerSubMenu: list } = this.props;
    return (
      <div className={styles.widgetItem}>
        {list.map(widget => {
          const id = widget.get("id");
          return <Item key={id} widget={widget} />;
        })}
      </div>
    );
  }
}

export default connect(
  Widgets,
  {
    extensions: ["company", "extensions"],
    headerSubMenu: ["headerSubMenu"]
  }
);
