import React from "react";
import _ from "lodash";

import FIELD_TYPES from "../../configs/fieldTypes";
import { VALUE_STATUSES } from "../../configs/import";
import i18n from "../../configs/i18n";


export default class NumberField {
  static MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER || Math.pow(2, 53) - 1;
  static MIN_SAFE_INTEGER =
    Number.MIN_SAFE_INTEGER || -NumberField.MAX_SAFE_INTEGER;

  static type = FIELD_TYPES.NumberField;
  static components = {
    inline: require("../../components/common/dataTypes/NumberField").default,
    control: require("../../components/common/UI/ControlList/controls/Number")
      .default
  };

  static getComponent = type => NumberField.components[type];

  static parseValue = (value, field) => {
    // clean
    value = _.trim(value);

    // try parse as number
    const parsedValue = parseFloat(value);
    if (NumberField.validateValue(parsedValue, field)) {
      return { value: parsedValue, status: VALUE_STATUSES.VALID };
    }

    // try parse as empty value
    if (NumberField.isEmpty(value)) {
      value = NumberField.getEmptyValue();
      return { value, status: VALUE_STATUSES.VALID };
    }

    return { value, status: VALUE_STATUSES.INVALID };
  };

  static validateValue = (value, field, outError) => {
    let error = "";
    let isValid = true;

    if (!_.isNull(value) && outError) {
      let min = field.getIn(["config", "min"]);
      min =
        min || min === 0 || min === "0" ? min * 1 : Number.MIN_SAFE_INTEGER;
      let max = field.getIn(["config", "max"]);
      max =
        max || max === 0 || max === "0" ? max * 1 : Number.MAX_SAFE_INTEGER;

      value = value * 1;
      if (value < min) {
        error = i18n.t("fieldTypes.number.min.error") + " " + min;
        isValid = false;
      }
      if (value > max) {
        error = i18n.t("fieldTypes.number.max.error") + " " + max;
        isValid = false;
      }
    }

    return outError ? error : isValid;
  };

  static getEmptyValue = () => null;

  static isEmpty = value => !(value || value == 0);

  static compare = (value1, value2) => {
    return value1 === value2;
  };

  static createComponent = (field, value, type) => {
    const Component = NumberField.components[type];
    return ({ containerClassName }) => {
      if (NumberField.isEmpty(value)) {
        return null;
      }
      return (
        <Component
          config={field.get("config")}
          value={value}
          containerClassName={containerClassName}
        />
      );
    };
  };
  static convertFilterToRecordValue = () => {
    return undefined;
  };
  static getDefaultValue = field => {
    const defaultValue = field.getIn(["config", "defaultEmptyValue"]);
    return defaultValue;
  };

  static validateRequired = (value) => {
    const empty = _.isNull(value) || value === "" || value === undefined;
    return empty;
  }

  static boardWitchColor = () => {
    return false;
  }

  static visibleRules = (value) => {
    let v = value;
    let newV = {};
    if (v.at || v.at === 0) {
      newV.$gte = parseFloat(v.at);
    }
    if (v.to || v.to === 0) {
      newV.$lte = parseFloat(v.to);
    }
    v = newV;
    
    return v;
  }

  static sortRecordsInCards = (field, records) => {
    const fieldId = field.get("id");
    // sort by value
    return records.sortBy(r => r.getIn(["values", fieldId]));
  }
}
