import { Badge, Space } from 'antd';
import React from 'react';

import Icon from '../../../../common/UI/Icon';
import color from "../../../../../utils/colorFromString";

import styles from "./userAvatar.less"

const UserAvatar = ({ styleUserMenuBadge, userName, userShortName, recordsEnds, licenseEnds }) => {
    return (
        <Badge style={styleUserMenuBadge} dot={recordsEnds || licenseEnds}>
            <Space size={5} className={styles.containerAvatar}>
                <div
                    className={styles.userIcon}
                    style={{ background: color.colorUserIcon(userName) }}
                >
                    {userShortName}
                </div>
                <Icon type="icon arrows-triangle-big-1-01" className={styles.arrow} />
            </Space>
        </Badge>
    );
};

export default UserAvatar;