import guid from "guid";
import _ from "lodash";
import Immutable from "immutable";

import SCENE_TYPE from "../configs/sceneTypes";
import SCENE_CONTAINER from "../configs/sceneContainer";
import SceneFactory from "../models/SceneFactory";
import RecordFactory from "../models/RecordFactory";
import recordActions from "../actions/recordActions";
import apiActions from "../actions/apiActions";
import userSettingsActions from "../actions/userSettingsActions";
import makeApiRequest from "../utils/makeApiRequest";
import filtersUtils from "../utils/filters";
import getFilterValues from "../utils/getFilterValues";
import getDefaultValues from "../utils/getDefaultValues";

const sceneMixin = {
  callbacks: {},
  /*
  sceneId
  type - to identity viewer (record or catalog) from this.SCENE_TYPE,
  container - popup, windowm ...
  params = {
    recordId - to identity initial record,
    catalogId - to identity initial catalog,
  },   
  data = { filters, sorting, records: [{…}], visible: true, etc... },
  parentSceneId - to identity parent item (prelast child in array),
  callbacks={
    onCreate, 
    onClose,
    onCreateRecord, (?)
    ...callbacks
  }
  */

  /* 
    Базовые взаимодействия со сценой 
  */

  /* on open records, catalogs, multimodals */
  createScene(scene, callbacks) {
    scene.sceneId = scene.sceneId || guid.raw();

    this.setIn(["scenes", scene.sceneId], SceneFactory.create(scene));

    // onCreate - дать такое название колбеку, где создается сцена
    this.setCallbacks(scene.sceneId, callbacks);

    // возвращает id сцены
    const callback = this.getCallback(scene.sceneId, "onCreate");
    callback && callback(scene);

    this.changed();
  },

  /* on changing filters, sorting, records, etc... */
  updateSceneData(sceneId, data = {}, callbacks) {
    this.mergeIn(["scenes", sceneId, "data"], data);
    callbacks && this.setCallbacks(sceneId, callbacks);
    this.changed();
  },

  formationFilters(viewId, scene) {
    let filters = scene.getIn(["data", "filters"]) || Immutable.Map({});
    const viewsFilters = scene.getIn(["views", viewId, "filters"]);
    filters = filtersUtils.mergeFilters(filters, viewsFilters);
    const searchText = scene.get("searchText");
    return {
      filters,
      searchText
    };
  },

  /* удаление сцены с изменением видимости */
  deleteScene(sceneId) {
    const parentSceneId = this.getIn(["scenes", sceneId, "parentSceneId"]);
    const isActive = this.getIn(["modal", "activeScene"]) === sceneId;
    const scene = this.getIn(["scenes", sceneId]);
    const sceneType = scene && scene.get("type");
    const sceneContainer = scene && scene.get("container");

    if (!scene) {
      return;
    }

    const childScene = this.get("scenes").find(
      scene => scene.get("parentSceneId") === sceneId
    );

    /* удаление из списка сцен */
    this.deleteIn(["scenes", sceneId]);

    const lastChildId = this._findSceneToDelete();

    /* могут быть кейсы удаления неактивных сцен */
    if (isActive) {
      /* в случае удаления последнего модального окна из очереди на удаление, происходит смена видимости модальног окна */
      if (lastChildId) {
        lastChildId && this.switchModal(lastChildId);
      } else {
        /* если не было найдено lastChildId, происходит удаление записи или каталога, поэтому могут быть проблемы в виде смены видимости модальных окон */
        if (sceneContainer === SCENE_CONTAINER.POPUP && childScene) {
          this.switchModal(childScene.get("sceneId"));
        } else {
          // если еще есть модальные окна, переключай на любое из них
          // this.switchFirstModal();
          const parentScene = this.getIn(["scenes", parentSceneId]);
          parentScene
            ? this.switchModal(parentSceneId)
            : this.switchFirstModal();
        }
      }
    }

    // при удалении сцены, вызывается функция подписки
    const onClose = this.getCallback(sceneId, "onClose");
    onClose && onClose(scene);

    // очистка памяти от колбеков
    this.deleteCallbacks(sceneId);

    /* для очистки аппстейта от закрытых записей */
    if (
      sceneType === SCENE_TYPE.RECORD ||
      sceneType === SCENE_TYPE.RECORDS_BATCH_UPDATE || sceneType === SCENE_TYPE.RECORDS_BATCH_DELETE
    ) {
      const catalogId = scene.getIn(["params", "catalogId"]);
      const recordId = scene.getIn(["params", "recordId"]);
      this.clearRecord(catalogId, recordId);
    }

    this.changed();
  },

  /* снятие флага shouldClose */
  cancelDeleteScene() {
    this.get("scenes")
      .filter(scene => scene.get("shouldClose") === true)
      .forEach((_scene, sceneId) => {
        this.setIn(["scenes", sceneId, "shouldClose"], false);
      });
    this.changed();
  },

  // store scenes callbacks
  setCallbacks(sceneId, callbacks) {
    if (!this.callbacks[sceneId]) {
      this.callbacks[sceneId] = {};
    }
    this.callbacks[sceneId] = _.merge(this.callbacks[sceneId], callbacks);
  },
  getCallbacks(sceneId) {
    return this.callbacks[sceneId] ? this.callbacks[sceneId] : null;
  },
  getCallback(sceneId, callbackName) {
    const callbacks = this.getCallbacks(sceneId);
    return callbacks && callbacks[callbackName];
  },
  deleteCallbacks(sceneId) {
    if (this.callbacks[sceneId]) delete this.callbacks[sceneId];
  },

  /* 
    Манипуляция со сценами (открытие, закрытие)
  */

  async openNewRecord(
    params,
    values,
    callbacks,
    container = SCENE_CONTAINER.POPUP
  ) {
    let { parentSceneId, catalogId, viewId } = params;

    // полседняя активная сцена будет установленна в качестве сцены-родителя
    parentSceneId = parentSceneId || this.getCurrentParentScene();

    // из родительской сцены получаем значения фильтров
    let viewFilters = this.getIn([
      "scenes",
      parentSceneId,
      "views",
      viewId,
      "filters"
    ]);
    let sceneFilters = this.getIn(["scenes", parentSceneId, "data", "filters"]);
    let catalogs = this.get("catalogs");

    // user нужен для обработки Сотрудник.Я...
    let user = this.get("user");

    // объединение фильтров с двух разных источников
    let filters = filtersUtils.mergeFilters(viewFilters, sceneFilters);
    // приводим фильтры в один формат js
    filters = filters && filters.toJS ? filters.toJS() : filters;

    if (filters) {
      const filterValues = getFilterValues(filters, catalogs, catalogId, user);
      const defaultValues = getDefaultValues(filters, catalogs, catalogId);

      values = _.assign({}, filterValues, defaultValues, values);
    };
    /* 
      заклинаю на страдания тех кто посмеет убрать эту запись
        иногда айди каталога не всегда число, например каталог сценариев "$scripts", при создании нового сценария из редактора сценариев
    */
    if (!/^\d+$/.test(params.catalogId)) {
      // Если каталог задан не числовым id, то отдельно получаем его реальный id и секцию
      await makeApiRequest("catalogs/" + params.catalogId).then(result => {
        if (result.ok) {
          params.catalogId = result.body.id;
        }
      });
    }
    // создание новой записи
    const recordId = guid.raw();
    recordActions.generateNewRecord(params.catalogId, recordId, values);

    // создание сцены с новой записью в модальном окне
    this.openRecord({ ...params, recordId }, callbacks, container);
  },

  openCloneRecord(params, callbacks, container = SCENE_CONTAINER.POPUP) {
    // определение клонированной записи, в качестве нового элемента
    const newRecordId = guid.raw();
    this.cloneRecord(params.catalogId, params.recordId, newRecordId);

    // создание сцены с новой записью в модальном окне
    this.openRecord({ ...params, recordId: newRecordId }, callbacks, container);
  },

  _createRecordParentSceneInModal(sceneId) {
    let scene = this.getIn(["scenes", sceneId]);

    const sceneContainer = scene && scene.get("container");
    const sceneType = scene && scene.get("type");

    if (
      sceneContainer === SCENE_CONTAINER.WINDOW &&
      sceneType === SCENE_TYPE.RECORD
    ) {
      const parentSceneId = guid.raw();
      scene = scene
        .set("container", SCENE_CONTAINER.POPUP)
        .set("sceneId", parentSceneId)
        .set("notReturnInitialValues", true);
      this.setIn(["scenes", parentSceneId], scene);

      const onClose = () => {
        this.deleteScene(parentSceneId);
      };

      return { parentSceneId, onClose };
    }
    return { parentSceneId: sceneId };
  },

  async openRecord(params, callbacks = {}, container = SCENE_CONTAINER.POPUP) {
    let { parentSceneId, ...oterParams } = params;

    // полседняя активная сцена будет установленна в качестве сцены-родителя
    parentSceneId = parentSceneId || this.getCurrentParentScene();

    // создание сцены
    const type = SCENE_TYPE.RECORD;
    let sceneId = guid.raw();

    const catalogSetByNumbers = /^\d+$/.test(params.catalogId);
    const catalogExist = this.getIn(["catalogs", params.catalogId]);

    // загружаем каталог, если его нет в стейте (кейс с генерируемыми каталогами через апи)
    if (catalogSetByNumbers && !catalogExist) {
      // Если каталог задан не числовым id, то отдельно получаем его реальный id и секцию
      await makeApiRequest("catalogs/" + params.catalogId);
    }

    // check if record opened from right panel and provides parentSceneId
    if (container == SCENE_CONTAINER.POPUP) {
      const {
        parentSceneId: newParentSceneId,
        onClose
      } = this._createRecordParentSceneInModal(parentSceneId);
      parentSceneId = newParentSceneId;

      // set callback, which will remove parentScene
      if (callbacks && callbacks.onClose) {
        const initialOnCloseCallback = callbacks.onClose;

        callbacks.onClose = (...args) => {
          onClose && onClose(...args);
          initialOnCloseCallback(...args);
        };
      } else {
        callbacks.onClose = onClose;
      }
    }

    // проверяем не открыт ли же этот рекорд в корневом уровне
    const scenes = this.get("scenes");
    const sameScene =
      scenes &&
      scenes.find(
        s =>
          s.get("container") == SCENE_CONTAINER.POPUP &&
          s.get("parentSceneId") == "" &&
          s.get("type") == SCENE_TYPE.RECORD &&
          s.getIn(["params", "catalogId"]) == params.catalogId &&
          s.getIn(["params", "recordId"]) == params.recordId
      );
    // запрещаем открывать повтрно Записи только в Попапе и только на 0 уровне
    if (
      sameScene &&
      container === SCENE_CONTAINER.POPUP &&
      parentSceneId == ""
    ) {
      sceneId = sameScene.get("sceneId");
    } else {
      // create new Scene for Record
      this.createScene(
        { sceneId, params: oterParams, type, parentSceneId, container },
        callbacks
      );
    }

    // изменяет активную сцену на текущую
    this.switchModal(sceneId);

    if (container === SCENE_CONTAINER.WINDOW) {
      /* прописать действия для открытия записи в окне, если установлен флаг container = window*/
    } else if (container === SCENE_CONTAINER.POPUP) {
      this.setVisible(true);
    }
  },

  openRecordsBatchUpdate(
    parentSceneId,
    params,
    callbacks,
    container = SCENE_CONTAINER.POPUP
  ) {
    // создание сцены
    const sceneId = guid.raw();
    const type = SCENE_TYPE.RECORDS_BATCH_UPDATE;
    // создание новой записи только для массового изменения
    const recordId = "batchUpdate:" + guid.raw();
    params.recordId = recordId;
    const batchUpdateRecord = {
      id: recordId,
      catalogId: params.catalogId,
      isNew: false
    };

    const scene = this.getIn(["scenes", parentSceneId]);

    const data = this.formationFilters(params.viewId, scene);

    const record = RecordFactory.create(batchUpdateRecord);
    this.setIn(["records", params.catalogId, recordId], record);


    this.createScene(
      { sceneId, params, data, type, parentSceneId, container },
      callbacks
    );

    // изменяет активную сцену на текущую
    this.switchModal(sceneId);

    if (container === SCENE_CONTAINER.WINDOW) {
      /* прописать действия для открытия записи в окне, если установлен флаг container = window*/
    } else if (container === SCENE_CONTAINER.POPUP) {
      this.setVisible(true);
    }
  },

  openRecordsBatchDelete(
    parentSceneId,
    params,
    callbacks,
    container = SCENE_CONTAINER.POPUP
  ) {
    // создание сцены
    const sceneId = guid.raw();
    const type = SCENE_TYPE.RECORDS_BATCH_DELETE;

    const scene = this.getIn(["scenes", parentSceneId]);

    const data = this.formationFilters(params.viewId, scene);

    this.createScene(
      { sceneId, params, data, type, parentSceneId, container },
      callbacks
    );

    // изменяет активную сцену на текущую
    this.switchModal(sceneId);

    if (container === SCENE_CONTAINER.WINDOW) {
      /* прописать действия для открытия записи в окне, если установлен флаг container = window*/
    } else if (container === SCENE_CONTAINER.POPUP) {
      this.setVisible(true);
    }
  },

  copyViews(initialSceneId, currentSceneId) {
    if (!initialSceneId || !currentSceneId) return;

    const sameCatalog =
      this.getIn(["scenes", initialSceneId, "params", "catalogId"]) ==
      this.getIn(["scenes", currentSceneId, "params", "catalogId"]);
    const initialViews = this.getIn(["scenes", initialSceneId, "views"]);
    const currentViews = this.getIn(["scenes", currentSceneId, "views"]);

    const views = currentViews.merge(initialViews);

    if (sameCatalog) {
      this.setIn(["scenes", currentSceneId, "views"], views);
      this.changed();
    }
  },

  async openCatalog(
    params,
    data,
    callbacks,
    container = SCENE_CONTAINER.POPUP
  ) {
    let { parentSceneId } = params;
    // полседняя активная сцена будет установленна в качестве сцены-родителя
    parentSceneId = parentSceneId || this.getCurrentParentScene();

    if (container == SCENE_CONTAINER.POPUP) {
      // создаем запись родительской сцены
      const {
        parentSceneId: newParentSceneId,
        onClose
      } = this._createRecordParentSceneInModal(parentSceneId); // получаем новую сцену, и колбэк onClose
      parentSceneId = newParentSceneId;

      if (callbacks && callbacks.onClose) {
        // если есть колбек, оставляем его
        const initialOnCloseCallback = callbacks.onClose;

        callbacks.onClose = (...args) => {
          onClose && onClose(...args);
          initialOnCloseCallback(...args);
        };
      } else {
        // если колбэка нет в аргументах, записываем в колбэк тот колбэк который мы получили из функции _createRecordParentSceneInModal
        callbacks.onClose = onClose;
      }
    }

    // создание сцены
    const type = SCENE_TYPE.CATALOG;
    const sceneId = guid.raw();

    this.createScene(
      { sceneId, params, data, type, parentSceneId, container },
      callbacks
    );

    // изменяет активную сцену на текущую
    this.switchModal(sceneId);

    if (container === SCENE_CONTAINER.WINDOW) {
      /* прописать действия для открытия записи в окне, если установлен флаг container = window*/
    } else if (container === SCENE_CONTAINER.POPUP) {
      this.setVisible(true);
    }

    await userSettingsActions.getUserSettingsForCatalog({
      catalogId: params.catalogId
    });
    await apiActions.getCatalog({ catalogId: params.catalogId }, undefined, {
      sceneId
    });
    await apiActions.getViews({ catalogId: params.catalogId }, undefined, {
      sceneId
    });
  },

  // actions to manipulate active record
  closeScene(sceneId) {
    /* указатель на то, что все дочение и текущая сцены должны быть удалены */
    this._markSceneToDelete(sceneId);

    /* определение последнего элемента в дереве */
    const lastChildSceneId = this._findSceneToDelete();

    /* переключение сцены */
    this.switchModal(lastChildSceneId);

    this.changed();
  },

  _markSceneToDelete(sceneId) {
    /**
     * если сцена которую мы хотим закрыть является окном, то не трогай попапы
     * в противном случае оставляй как есть
     * если мы закрываем сцену типа window, то мы должны рекурсивно закрыть дочерние сцены типа window
     * если мы закрываем сцену типа popup, то мы должны рекурсивно закрыть дочерние сцены типа popup
     */

    this.setIn(["scenes", sceneId, "shouldClose"], true);

    const currentSceneContainer = this.getIn(["scenes", sceneId, "container"]);

    this.get("scenes")
      .filter(
        scene =>
          scene.get("parentSceneId") === sceneId &&
          scene.get("container") === currentSceneContainer
      )
      .forEach((_childScene, childSceneId) => {
        this._markSceneToDelete(childSceneId);
      });
  },

  _findSceneToDelete() {
    const scenesToDelete = this.get("scenes").filter(
      scene => scene.get("shouldClose") === true
    );

    const lastChild = scenesToDelete.find((scene, sceneId) => {
      return !scenesToDelete.find(
        scene => scene.get("parentSceneId") === sceneId
      );
    });

    return lastChild ? lastChild.get("sceneId") : false;
  },

  switchModal(sceneId) {
    if (sceneId) {
      const scene = this.getIn(["scenes", sceneId]);
      const isPopup = scene && scene.get("container") === SCENE_CONTAINER.POPUP;
      if (isPopup) {
        this.setIn(["modal", "activeScene"], sceneId);
      }
    } else {
      this.setVisible(false);
    }
    this.changed();
  },

  switchFirstModal() {
    const modalScene =
      this.get("scenes") &&
      this.get("scenes").find(
        scene =>
          scene.get("container") === SCENE_CONTAINER.POPUP &&
          !scene.get("shouldClose")
      );
    const activeSceneId = modalScene && modalScene.get("sceneId");
    activeSceneId && this.switchModal(activeSceneId);
  },

  setVisible(visible) {
    this.setIn(["modal", "visible"], visible);

    if (visible) {
      const activeScheneId = this.getIn(["modal", "activeScene"]);
      const activeScene = this.getIn(["scenes", activeScheneId]);
      if (!activeScene) {
        this.switchFirstModal();
      }
    }

    this.changed();
  },

  /* 
    HELPERS
  */
  getCurrentParentScene() {
    const visible = this.getIn(["modal", "visible"]);
    return visible ? this.getIn(["modal", "activeScene"]) : "";
  },

  deleteRecordsFromScene(sceneId) {
    this.deleteIn(["scenes", sceneId, "records"]);
    this.changed();
  },

  deleteCatalogCompleted(result, params, data, query, res, actionParams) {
    const { catalogId } = params;

    /* при удалении каталога удаляются все сцены каталога и сцены его записей */
    const scenesToDelete = this.get("scenes").filter(
      scene => scene.getIn(["params", "catalogId"]) === catalogId
    );

    /* поиск дочерних сцен */
    scenesToDelete.forEach((scene, sceneId) => {
      const children = this.get("scenes").filter(
        child => child.get("parentSceneId") === sceneId
      );

      /* неудаленным дочерним записям заменяем родительскую сцену */
      children
        .filter(ch => ch.getIn(["params", "catalogId"]) !== catalogId)
        .forEach((_ch, chSceneId) => {
          let parentSceneId;
          /* если сцена - каталог, то можно спокойно брать ее родительский элемент */
          if (scene.get("type") === SCENE_TYPE.CATALOG) {
            parentSceneId = scene.get("parentSceneId");
            /* если сцена - запись, то нужно взять ее родителя (удаляемый каталог) и от него взять родительскую сцену */
          } else if (scene.get("type") === SCENE_TYPE.RECORD) {
            const catalogSceneId = scene.get("parentSceneId");
            parentSceneId = this.getIn([
              "scenes",
              catalogSceneId,
              "parentSceneId"
            ]);
          }
          this.setIn(["scenes", chSceneId, "parentSceneId"], parentSceneId);
        });
      this.deleteScene(sceneId);
    });

    this.changed();
  },

  getRecordCompleted(body, params, data, query, res, actionParams) {
    this.syncRecordInCatalogScenes(params, body);

    /* снимаем флаг о необходимости получения записи */
    const recordsScenes = this.get("scenes").filter(scene => {
      return (
        scene.getIn(["params", "catalogId"]) === params.catalogId &&
        scene.getIn(["params", "recordId"]) === params.recordId &&
        scene.get("type") === SCENE_TYPE.RECORD
      );
    });
    recordsScenes &&
      recordsScenes.forEach((scene, sceneId) => {
        this.setShouldReload(sceneId, false);
      });

    this.changed();
  },

  syncRecordInCatalogScenes(params, data) {
    const scenes = this.get("scenes");

    // обновление записи каталога, пришедшей записи с сервера
    // обновление полученнной записи в списке записей каталогов
    /*    
      полученная запись может присутствовать в различных сценах каталога, 
      поэтому заменим "старые" значения полей записи на "новые" (пришедшие) значения полей  
    */

    const values = data && data.values;
    const newValues = values
      ? Immutable.Map.isMap(values)
        ? values
        : Immutable.fromJS(values)
      : values;

    scenes &&
      scenes
        .filter(
          scene =>
            scene.getIn(["params", "catalogId"]) === params.catalogId &&
            scene.get("type") === SCENE_TYPE.CATALOG
        )
        .forEach((scene, sceneId) => {
          let key =
            scene.get("records") &&
            scene
              .get("records")
              .findKey(item => item.get("id") === String(params.recordId));

          if (key || key === 0 || key === "0") {
            this.mergeIn(
              ["scenes", sceneId, "records", key, "values"],
              newValues
            );
          }

          /* синхронизация предлагаемых записей для календаря */
          const suggestedRecords = scene.get("suggestedRecords");

          if (suggestedRecords) {
            const suggestedRecordKey = suggestedRecords.findKey(
              r => r.get("id") === params.recordId
            );

            if (
              suggestedRecordKey ||
              suggestedRecordKey === 0 ||
              suggestedRecordKey === "0"
            ) {
              this.mergeIn(
                [
                  "scenes",
                  sceneId,
                  "suggestedRecords",
                  suggestedRecordKey,
                  "values"
                ],
                newValues
              );
              this.changed();
            }
          }
        });

    // обновление записей каталогов, пришедших связанных записей полученной записи
    // обновление полученнных связанных записей в списках записей каталогов
    /* 
      "связанная запись" может является записью каталога, так и связью в другой записи
  
      Полученная запись может содержать в себе другие связанные записи.
      Нужно найти все эти связанные записи в списке записей во всех сценах каталога и заменить "старые" 
      значения полей на "новые", пришедшие в расширенных полях полученной записи.
    */

    const linkedRecords = this.getLinkedRecords(params.catalogId, newValues);

    linkedRecords.forEach(linkedRecord => {
      const catalogId = linkedRecord.get("catalogId");
      const recordId = linkedRecord.get("recordId");
      const values = linkedRecord.get("recordValues");

      /* 
        проверка на то, что значения связанной записи действительно пришли с сервера, тк на 2 уровне вложенности связей recordValues отсутствуют 
        если в связях указана сама запись, то нет смысла проводить по ней синхронизацию
      */
      values &&
        this.syncRecordInCatalogScenes({ catalogId, recordId }, { values });
    });

    // обновление связанных записей в записи в списке записей каталогов, полученной записи
    // обновление полученной записи в связанной записи у записи в списке записей каталогов
    /* 
      полученная запись может быть связанной записью для многих других записей. 
      Эти "другие записи" могут содержаться во многих каталогах, открытых в разных сценах. 
      поэтому в "других записях" в сценах заменим тайтлы связанных записей равных полученной записью
    */

    //   let needReload;
    //   if (linkFields && linkFields.size > 0) {
    //     sceneRecords &&
    //       sceneRecords.forEach(r => {
    //         if (needReload) {
    //           return;
    //         }
    //         linkFields.forEach(f => {
    //           if (needReload) {
    //             return;
    //           }
    //           r.getIn(["values", f.get("id")]) &&
    //             r.getIn(["values", f.get("id")]).forEach(v => {
    //               if (needReload) {
    //                 return;
    //               }
    //               if (f.get("type") === FIELD_TYPES.OBJECT) {
    //                 if (
    //                   v.get("catalogId") === params.catalogId &&
    //                   v.get("recordId") === params.recordId
    //                 ) {
    //                   needReload = true;
    //                 }
    //               } else {
    //                 if (v.get("id") === params.recordId) {
    //                   needReload = true;
    //                 }
    //               }
    //             });
    //         });
    //       });
    //       /* прописать тайтл связанной записи */
    //   }
  },

  /* в сцене заменяет рекорды с guid на id  с сервера (newRecordId) */
  createRecordCompleted(
    record,
    { catalogId },
    data,
    { },
    response,
    { recordId, sceneId, silent = false } = {} // silent - означает тихое обновленеи те те случаи когда ререндер компонентов не нужен ()
  ) {
    const newRecordId = record.id;

    // update record id in modal opened records collection
    if (!newRecordId) {
      return;
    }

    // update recordId from Guid to real Id
    /* при создании записи через вложенное поле без создания всплывающего окна, у новосозданной записи не будет сцены, поэтому искать новые записи надежней по предыдущему айди записи */
    this.get("scenes").forEach((scene, sceneId) => {
      const newRecord = scene.getIn(["params", "recordId"]) == recordId;
      if (newRecord) {
        this.setIn(["scenes", sceneId, "params", "recordId"], newRecordId);
      }
    });

    // получить текущую сцену
    const scene = sceneId && this.getIn(["scenes", sceneId]);
    // проверяем есть ли текущая сцена
    if (scene) {
      // доастать парент сцен айди
      const parentSceneId = scene.get("parentSceneId");
      // получить парент сцен
      const parentScene = this.getIn(["scenes", parentSceneId]);
      // Проверяем есть ли у сцены родительская сцена и её тип каталог
      if (parentSceneId && parentScene.get("type") === SCENE_TYPE.CATALOG) {
        // Проверяем есть ли обработчик onCreateRecord у родительской сцены и вызываем его
        const onCreateRecord = this.getCallback(
          parentSceneId,
          "onCreateRecord"
        );
        onCreateRecord && onCreateRecord({ ...record, parentSceneId });
      }

      /* Вызов колбека  */
      const onCreateRecord = this.getCallback(sceneId, "onCreateRecord");
      onCreateRecord && onCreateRecord({ ...record, sceneId });
    }

    /* обновление списка записей всех сцен этого каталога */
    if (!silent) {
      this.get("scenes")
        .filter(
          scene =>
            scene.getIn(["params", "catalogId"]) === catalogId &&
            scene.get("type") === SCENE_TYPE.CATALOG
        )
        .forEach((_scene, sceneId) => {
          this.setShouldReload(sceneId, true);
        });

      this.changed();
    }
  },

  updateRecordCompleted(result, params, data, query, res, actionParams = {}) {
    const { silent = false } = actionParams;
    this.syncRecordInCatalogScenes(params, result);
    !silent && this.changed();
  },

  deleteRecordCompleted(result, params, data, query, res, actionParams = {}) {
    const { catalogId, recordId } = params;
    const { silent = false } = actionParams;

    const scenesToDelete = this.get("scenes").filter(
      scene =>
        scene.getIn(["params", "catalogId"]) === catalogId &&
        scene.getIn(["params", "recordId"]) === recordId &&
        scene.get("type") === SCENE_TYPE.RECORD
    );

    /* подмена родителя на деда */
    scenesToDelete.forEach((_scene, sceneId) => {
      const children = this.get("scenes").filter(
        child => child.get("parentSceneId") === sceneId
      );

      const newParentId = this.getParentForDeletedRecord(
        sceneId,
        scenesToDelete
      );

      /* может произойти ситуация, когда родительская запись и сама запись являются одним и тем же элементом */
      children.forEach((ch, chSceneId) => {
        this.setIn(["scenes", chSceneId, "parentSceneId"], newParentId);
      });
    });

    /* удаление сцен */
    scenesToDelete.forEach((scene, sceneId) => {
      this.deleteScene(sceneId);
    });

    /* обновление записей в каталоге */
    if (!silent) {
      this.get("scenes")
        .filter(
          scene =>
            scene.getIn(["params", "catalogId"]) === catalogId &&
            scene.get("type") === SCENE_TYPE.CATALOG
        )
        .forEach((_scene, sceneId) => {
          this.setShouldReload(sceneId, true);
        });

      this.changed();
    };
  },

  getParentForDeletedRecord(sceneId, scenesToDelete) {
    let parentSceneId = this.getIn(["scenes", sceneId, "parentSceneId"]);

    /* если родительская сцена не является удаляемой или если она отсутствует */
    if (!scenesToDelete.get(parentSceneId)) {
      return parentSceneId;
    } else {
      return this.getParentForDeletedRecord(parentSceneId, scenesToDelete);
    }
  },

  getRecordsCompleted(records, params, data, query, response, actionParams) {
    if (!(actionParams && actionParams.sceneId)) {
      return;
    }

    let scene = this.getIn(["scenes", actionParams.sceneId]);
    let currentQuery = scene && scene.get("query");
    // TODO: remove this workaround
    // если currentQuery не задан, скорее всего это состояние обновления страницы
    // т.е. когда страницу обновляют, то catalog еще не сушествует и
    // поэтому в него никто не запишет query
    if (
      scene &&
      currentQuery &&
      !Immutable.is(Immutable.fromJS(query), currentQuery)
    ) {
      return;
    }

    const offset = query.offset || 0;
    let appendRecords = offset > 0;
    let recordsMap = {};

    records.map((c, i) => (recordsMap[offset + i] = RecordFactory.create(c)));
    recordsMap = Immutable.Map(recordsMap);

    if (appendRecords) {
      recordsMap = scene.get("records").merge(recordsMap);
    }

    this.setIn(["scenes", actionParams.sceneId, "records"], recordsMap);
    !actionParams.withOutRecordsCountReloading &&
      this.setIn(
        ["scenes", actionParams.sceneId, "recordsCount"],
        response.headers["x-total-count"]
      );

    this.mergeIn(["scenes", actionParams.sceneId], {
      allRecordsLoaded: records.length < query.limit,
      loading: false,
      loadingError: null
    });

    this.setShouldReload(actionParams.sceneId, false);
    this.changed();
  },

  getRecordsFailed(err, params, data, query, actionParams) {
    if (!(actionParams && actionParams.sceneId)) {
      return;
    }

    if (!this.getIn(["scenes", actionParams.sceneId])) {
      return;
    }
    const errText = _.isObject(err) && err.text ? err.text : "";

    this.setIn(["scenes", actionParams.sceneId, "loading"], false);
    this.setIn(
      ["scenes", actionParams.sceneId, "loadingError"],
      Immutable.fromJS(errText)
    );
    this.changed();
  }
};

export default sceneMixin;
