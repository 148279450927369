import React, { Component } from "react";
import { withRouter } from "react-router";
import { Row } from "antd";
import { withTranslation } from "react-i18next";

import FieldsPanel from "./FieldsPanel";
import PropertyPanel from "./PropertyPanel";
import CatalogEditorBody from "./CatalogEditorBody";
import CatalogEditorHeader from "./CatalogEditorHeader";
import apiActions from "../../actions/apiActions";
import catalogActions from "../../actions/catalogActions";
import { connect } from "../StateProvider";
import uiActions from "../../actions/uiActions";
import userSettingsActions from "../../actions/userSettingsActions";

import styles from "./catalogEditor.less";
import _ from "lodash";

class CatalogEditor extends Component {
  state = {
    selectedFieldIndex: null
  };

  componentDidMount() {
    const catalogId = this.props.match.params.catalogId;
    const sectionId = this.props.match.params.sectionId;

    userSettingsActions.getKey([
      "catalogs", 
      catalogId, 
      "catalogEditor",
      "compactFields",
      ].join("."),
    );

    if (this.props.isStateEditCatalog) {
      apiActions.getCatalog(
        {
          catalogId: catalogId
        },
        null,
        { catalogEdit: "catalogEdit", sceneId: this.props.sceneId }
      );
    } else {
      catalogActions.addCatalog({
        sectionId: sectionId,
        t: this.props.t
      });
    }
  }

  componentDidUpdate(prevProps) {
    uiActions.showHelper("systemCreation");
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newCatalogId = nextProps.match.params.catalogId;
    const newSectionId = nextProps.match.params.sectionId;

    if (
      this.props.isStateEditCatalog &&
      newCatalogId &&
      newCatalogId !== this.props.match.params.catalogId
    ) {
      apiActions.getCatalog(
        {
          catalogId: newCatalogId
        },
        null,
        { catalogEdit: "catalogEdit", sceneId: this.props.sceneId }
      );
    }
    if (
      this.props.isStateAddCatalog &&
      newSectionId !== this.props.match.params.sectionId
    ) {
      catalogActions.addCatalog({
        sectionId: newSectionId
      });
    }
  }
  onSelectField = fieldIndex =>
    this.setState({ selectedFieldIndex: fieldIndex });

  render() {
    const sectionId = this.props.match.params.sectionId;
    const catalog = this.props.editingCatalogs.get(sectionId);
    const { catalogs, dropType, dropInfo } = this.props;
    const disabled =
      catalog && (catalog.get("updating") || catalog.get("creating"));
    return (
      <div className={styles.catalogEditorWrapper}>
        <div className={styles.catalogEditor}>
          {catalog ? (
            <CatalogEditorHeader
              {...this.props}
              catalog={catalog}
              disabled={disabled}
            />
          ) : null}
          {catalog ? (
            <div className={styles.catalogEditorBody}>
              <Row type="flex" className={styles.catalogEditorData}>
                <FieldsPanel />
                <CatalogEditorBody
                  {...this.props}
                  dropType={dropType}
                  dropInfo={dropInfo}
                  catalog={catalog}
                  catalogs={catalogs}
                  disabled={disabled}
                  selectedFieldIndex={this.state.selectedFieldIndex}
                  onSelectField={this.onSelectField}
                />
                <PropertyPanel
                  {...this.props}
                  selectedFieldIndex={this.state.selectedFieldIndex}
                />
              </Row>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect(
  withRouter(withTranslation()(CatalogEditor)),
  ["catalogs", "editingCatalogs", "dropType", "dropInfo"]
);
