import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import cn from "classnames";
import { Button } from "antd";
import { withTranslation } from "react-i18next";

import Icon from '../../../../../../../common/UI/Icon';
import modalsActions from "../../../../../../../../actions/modalsActions";
import StateLink from "../../../../../../../common/router/StateLink";
import routes from "../../../../../../../../routes";
import ViewActivities from "./ViewActivities";
import getLink from "../../../../../../../common/router/getLink";

import PRIVILEGE_CODES from "../../../../../../../../configs/privilegeCodes";
import RESOURCE_TYPES from "../../../../../../../../configs/resourceTypes";
import { checkAccessOnObject } from "../../../../../../../../utils/rights";
import { connect } from "../../../../../../../StateProvider";

import styles from "./viewsMenu.less";

class ViewsMenuItem extends Component {
  static propTypes = {
    view: PropTypes.object,
    catalogId: PropTypes.string,
    sceneId: PropTypes.string,
    onClick: PropTypes.func
  };

  onModalNewView = () => {
    const accessOnViewForRights = checkAccessOnObject(
      RESOURCE_TYPES.CATALOG,
      { privilegeCode: this.props.catalogPrivilegeCode },
      PRIVILEGE_CODES.ACCESS
    );

    modalsActions.openViewInputModal(
      this.props.sceneId,
      this.props.view.get("id"),
      accessOnViewForRights,
      res => {
        const { location, history } = this.props;
        // при создании нового вида, редиректить на станицу вида без search
        location.search = "";
        history.push(getLink(location, null, { viewId: res.id }));
      }
    );
  };

  ButtonSave({ isActive, filtersChanged, isNew }) {
    if (
      isActive &&
      !(Number(this.props.view && this.props.view.get("id")) === 0) &&
      !isNew
    ) {
      return (
        <ViewActivities
          className={styles.viewsActivities}
          view={this.props.view}
          sceneId={this.props.sceneId}
          {...{
            history: this.props.history,
            location: this.props.location,
            filtersChanged,
            onModalNewView: this.onModalNewView
          }}
        />
      );
    } else if (filtersChanged) {
      return (
        <Button className={styles.buttonSaveView} onClick={this.onModalNewView}>
          {this.props.t("buttons.save")}
        </Button>
      );
    }
  }

  render() {
    const { view, viewId, isNew, filtersChanged, onClick } = this.props;
    const isView = view && view.get("isAdmin") && view.get("forRights");
    let name = view && view.get("displayName");

    return (
      <StateLink
        route={routes.view}
        params={{ viewId }}
        render={props => {
          return (
            <li
              className={cn(styles.menuItem, {
                [styles.menuItemSelected]: props.isActive
              })}
              onClick={onClick}
            >
              <div
                className={cn(
                  "ant-menu-item",
                  { "ant-menu-item-selected": props.isActive },
                  styles.itemDiv
                )}
              >
                <Link to={props.link} className={styles.link}>
                  {isView && (
                    <Icon
                      type={"icon edition-55"}
                      className={styles.forRights}
                    />
                  )}
                  {/* {view ? (
                    view.get("isAdmin") && view.get("forRights") ? (
                      <Icon
                        type={"icon edition-55"}
                        className={styles.forRights}
                      />
                    ) : null
                  ) : null} */}
                  {name}
                </Link>
              </div>
              {this.ButtonSave({
                filtersChanged,
                isActive: props.isActive,
                isNew
              })}
            </li>
          );
        }}
      />
    );
  }
}

export default withTranslation()(
  connect(
    withRouter(ViewsMenuItem),
    {
      scenes: ["scenes"]
    },
    (props, { scenes }) => {
      const viewId = props.view.get("id");
      const filtersChanged =
        scenes.get(props.sceneId) &&
        scenes.getIn([props.sceneId, "views", viewId, "filtersChanged"]);
      const isNew = viewId === "$new";
      return {
        filtersChanged,
        isNew,
        viewId,
        ...props
      };
    }
  )
);
