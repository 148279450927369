import React, { Component } from "react";
import { Menu as AntMenu, Badge, Dropdown } from "antd";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import Icon from '../../../../../../../common/UI/Icon';
import ButtonTransparent from "../../../../../../../common/UI/ButtonTransparent";
import apiActions from "../../../../../../../../actions/apiActions";
import modalsActions from "../../../../../../../../actions/modalsActions";
import { confirm } from "../../../../../../../common/Modal";
import getLink from "../../../../../../../common/router/getLink";
import routes from "../../../../../../../../routes";
import { connect } from "../../../../../../../StateProvider";
import PRIVILEGE_CODES from "../../../../../../../../configs/privilegeCodes";
import RESOURCE_TYPES from "../../../../../../../../configs/resourceTypes";
import { checkAccessOnObject } from "../../../../../../../../utils/rights";
import viewActions from "../../../../../../../../actions/viewActions";

import styles from "./viewsMenu.less";

class ViewActivities extends Component {
  static propTypes = {
    view: PropTypes.object,
    onModalNewView: PropTypes.func,
    filtersChanged: PropTypes.bool,
    history: PropTypes.object,
    location: PropTypes.object
  };

  onClickAccess = () => {
    const { view, location, history } = this.props;

    if (view) {
      let isAccessView =
        view &&
        checkAccessOnObject(RESOURCE_TYPES.VIEW, view, PRIVILEGE_CODES.ACCESS);
      let readOnly =
        !checkAccessOnObject(
          RESOURCE_TYPES.CATALOG,
          this.props.catalog,
          PRIVILEGE_CODES.ACCESS
        ) && !isAccessView;
      modalsActions.openViewAccessModal(view, readOnly, result => {
        if (result && result.viewId) {
          const link = getLink(location, routes.view, {
            viewId: result.viewId
          });
          history.push(link);
        }
      });
    }
  };

  renameView = e => {
    const view = this.props.view;
    const catalogId = view.get("catalogId");
    const sceneId = this.props.sceneId;
    modalsActions.openViewInputModalEdit(view, catalogId, sceneId);
  };

  // remove view.
  openRemoveConfirm = e => {
    const { t } = this.props;
    const view = this.props.view;
    const sceneId = this.props.sceneId;
    const viewId = view.get("id");
    const catalogId = view.get("catalogId");

    confirm({
      headerText: t("modals.removeViewConfirm.headerText"),
      text: view.get("forRights")
        ? t("modals.removeViewConfirm.textForRights")
        : t("modals.removeViewConfirm.text"),
      okText: t("modals.removeViewConfirm.okText"),
      cancelText: t("modals.removeViewConfirm.cancelText"),
      onOk() {
        apiActions.deleteView({
          catalogId,
          viewId,
          sceneId
        });
      }
    });
  };

  render() {
    const { t } = this.props;
    const view = this.props.view;
    const sceneId = this.props.sceneId;
    const filtersChanged = this.props.filtersChanged;
    const viewId = view.get("id");
    let dropDownButtonItems = [];

    // check access admin on catalog.
    let isAccessAdmin = checkAccessOnObject(
      RESOURCE_TYPES.VIEW,
      view,
      PRIVILEGE_CODES.ACCESS
    );
    let isPrivateView = !view.get("forRights");

    if (this.props.filtersChanged) {
      // Check acces to catalog
      if (isAccessAdmin || isPrivateView) {
        dropDownButtonItems.push({
          icon: "text-2",
          text: t("buttons.save"),
          onClick: () => {
            // get scene window by sceneId, then get scene.views.ivewId,filters
            const sceneView =
              this.props.sceneViews && this.props.sceneViews.get(viewId);

            let params = {
              name:
                (sceneView && sceneView.get("name")) ||
                (sceneView && sceneView.get("originName")),
              forRights: sceneView && sceneView.get("forRights")
            };
            // Get new filters from scene view, current filters + new ones
            const filters = sceneView && sceneView.get("filters");
            viewActions.updateExistingView(sceneId, view, params, filters, () => {
              const { location, history } = this.props;
              history.push(location.pathname);
            });
          }
        });
      }
      dropDownButtonItems.push({
        // "save as" button, need to check acces right for prived views
        icon: "text-2",
        text: t("buttons.saveAs"),
        onClick: this.props.onModalNewView
      });
    } else {
      // edit and remove
      if (view) {
        // if ((!view.get('isNew') && Number(view.get('id')) !== 0) && (isAccessAdmin || isPrivateView)) {
        if (isAccessAdmin || isPrivateView) {
          dropDownButtonItems = [
            {
              icon: "edition-33",
              text: t("views.renameView"),
              onClick: this.renameView
            },
            {
              icon: "edition-41",
              text: t("buttons.removeView"),
              className: styles.settingRemove,
              onClick: this.openRemoveConfirm
            }
          ].concat(dropDownButtonItems);
        }
      }

      // access button if view for rights.
      if (view && view.get("forRights")) {
        dropDownButtonItems = [
          {
            icon: "edition-55",
            text: t("buttons.accessView"),
            onClick: this.onClickAccess
          }
        ].concat(dropDownButtonItems);
      }
    }
    const dropdownMenu =
      dropDownButtonItems.map((item, i) => {
        return {
          key: i,
          label: (
            <a
              onClick={item.onClick}
              className={item.className ? item.className : null}
            >
              <Icon
                type={"icon " + item.icon}
                className={styles.settingIcon}
              />
              {item.text}
            </a>)
        };
      });



    return (
      <Dropdown
        menu={{ items: dropdownMenu }}
        trigger={["click"]}
        placement="bottomRight"
      >
        <ButtonTransparent className={this.props.className}>
          <Badge style={{ boxShadow: "none" }} dot={filtersChanged}>
            <Icon type="icon setting-10" />
          </Badge>
        </ButtonTransparent>
      </Dropdown>
    );
  }
}

export default withTranslation()(
  connect(
    ViewActivities,
    {
      scenes: ["scenes"]
    },
    (props, { scenes }) => {
      const { sceneId } = props;

      /* сделано для добавления нового вида из сцены, так как в каталог новый вид не сохраяняется, а список видов отображается по видам каталога */
      const sceneViews = scenes.getIn([sceneId, "views"]);
      return {
        sceneViews,
        ...props
      };
    }
  )
);
