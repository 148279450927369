import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import editorActions from "../../../actions/editorActions";
import {
  TEXT,
  MASKTEXT,
  MULTILINE,
  SCRIPT
} from "../../../configs/textFieldSubTypes";
import { Select, Input, Row, Col } from "antd";
import styles from "./fields.less";

const Option = Select.Option;
const fieldTypes = [TEXT, MASKTEXT, MULTILINE]; //, SCRIPT

class TextField extends React.PureComponent {
  constructor(props) {
    super(props);
    const config = this.innerMapper(this.props.field.getIn(["config"]));

    this.state = {
      typeItems: fieldTypes.map(type => {
        return {
          key: type,
          text: `fieldTypes.${this.props.field.get("type")}.types.` + type
        };
      }),
      ...config
    };
  }

  innerMapper = config => {
    const type = config.get("type");
    const mask = config.get("mask");

    if (mask) {
      return { type: MASKTEXT, mask };
    } else {
      return { type, mask: "" };
    }
  };

  outerMapper = () => {
    const type = this.state.type;
    const mask = this.state.mask;

    if ((type == TEXT && mask) || type == MASKTEXT) {
      return { type: TEXT, mask };
    } else {
      return { type, mask: "" };
    }
  };

  onSelectType = value => {
    this.setState(
      {
        type: value,
        showMask: value === MASKTEXT
      },
      () => {
        this.onChange();
      }
    );
  };

  onChangeMask = e => {
    const value = e.target.value || "";
    this.setState(
      {
        mask: value
      },
      () => {
        this.onChange();
      }
    );
  };

  onChange = () => {
    const config = this.outerMapper();
    editorActions.changeFieldConfig(
      this.props.sectionId,
      this.props.fieldIndex,
      config
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const config = this.innerMapper(nextProps.field.getIn(["config"]));
    this.setState(config);
  }

  render() {
    const colsConfig = {
      lg: 12,
      xl: 8
    };
    const type = this.state.type;
    const showMask = this.state.showMask || type === MASKTEXT;
    return (
      <Row gutter={[24, 16]} style={{ marginBottom: 0 }}>
        <Col {...colsConfig} lg={24} style={{ paddingBottom: 0 }}>
          <Select
            style={{ width: "100%" }}
            children={this.state.typeItems.map(item => (
              <Option key={item.key}>{this.props.t(item.text)}</Option>
            ))}
            value={this.state.type}
            onChange={this.onSelectType}
          />
        </Col>
        {showMask ? (
          <Col {...colsConfig} style={{ paddingBottom: 0 }}>
            <Input
              type="text"
              disabled={this.props.disabled}
              value={this.state.mask}
              onChange={this.onChangeMask}
              placeholder={this.props.t("fieldTypes.text.mask.placeholder")}
            />
          </Col>
        ) : null}
        {showMask ? (
          <Col {...colsConfig} style={{ paddingBottom: 0 }}>
            <span className={styles.subText}>
              {this.props.t("fieldTypes.text.mask.example")}
            </span>
          </Col>
        ) : null}
      </Row>
    );
  }
}

TextField.propTypes = {
  field: PropTypes.object.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  sectionId: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default withTranslation()(TextField);
