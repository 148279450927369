import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import editorActions from "../../actions/editorActions";
import { confirm } from "../common/Modal";
import ButtonClose from "../common/UI/ButtonClose";

class FieldRemoveCross extends React.PureComponent {
  removeField = () => {
    editorActions.removeField(this.props.sectionId, this.props.fieldIndex);
    this.props.onRemove();
  };

  onClick = () => {
    if (this.props.fieldId) {
      const { t } = this.props;
      confirm({
        headerText: t("modals.removeFieldConfirm.header"),
        text: t("modals.removeFieldConfirm.text"),
        okText: t("modals.removeFieldConfirm.okText"),
        cancelText: t("modals.removeFieldConfirm.cancelText"),
        onOk: () => {
          this.removeField();
        }
      });
    } else {
      this.removeField();
    }
  };

  render() {
    return (
      <ButtonClose
        onClick={this.onClick}
        title={this.props.t("catalogEditor.field.remove")}
        medium
      />
    );
  }
}

FieldRemoveCross.propTypes = {
  fieldId: PropTypes.string,
  fieldIndex: PropTypes.number.isRequired,
  sectionId: PropTypes.string.isRequired
};

export default withTranslation()(FieldRemoveCross);
