import _ from "lodash";
import React, { useEffect, useRef } from "react";
import { Layout, Input, Button } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Icon from '../../../common/UI/Icon';
import SidebarMenu from "./SidebarMenu";

import styles from "./companies.less";

const PopoverLayout = props => {
  const {
    showSideSearch,
    searchText,
    searchCompanyText,
    onSearchChange,
    onCompanySearchChange,
    filteredCompanyItems,
    filteredItems,
    openCreateModal,
    visible,
    showSidebarHeader,
    sectionsAccess,
    company,
    handleClickCreacteCompany
  } = props;

  const searchRef = useRef(null);
  const companySearchRef = useRef(null);
  const showAddCompanyButton = company.get("canCreateCompany");
  const { t } = useTranslation();

  useEffect(
    () => {
      // Focus on search input everytime modal opening
      searchRef.current.focus();
      searchRef.current.select();
    },
    [visible]
  );

  return (
    <Layout className={styles.popoverContent}>
      <Layout.Content>
        <Layout>
          <Layout.Sider className={styles.popoverSider}>
            {showAddCompanyButton || showSidebarHeader ? (
              <Layout.Header className={styles.siderHeader}>
                <div className={styles.searchSiderHeaderWrapper}>
                  {showSidebarHeader && (
                    <Input
                      ref={companySearchRef}
                      placeholder={t("menuPopover.searchCompany")}
                      allowClear
                      className={styles.input}
                      value={searchCompanyText}
                      onChange={onCompanySearchChange}
                    />
                  )}
                </div>
                {showAddCompanyButton &&
                  sectionsAccess && (
                    <Button
                      onClick={handleClickCreacteCompany}
                      key="submit"
                      type="default"
                      className={styles.popoverHeaderAddButton}
                    >
                      <Icon type="icon interface-72" />
                      {t("section.menu.createCompany")}
                    </Button>
                  )}
              </Layout.Header>
            ) : null}
            <SidebarMenu
              companies={filteredCompanyItems}
              company={company}
              showSideSearch={showSideSearch}
              showAddCompanyButton={showAddCompanyButton}
            />
          </Layout.Sider>
          <Layout className={styles.popoverMainLayout}>
            <Layout.Header className={styles.searchHeader}>
              <div className={styles.searchHeaderWrapper}>
                <Input
                  ref={searchRef}
                  placeholder={t("menuPopover.searchCatalog")}
                  allowClear
                  className={styles.input}
                  value={searchText}
                  onChange={onSearchChange}
                />
              </div>
              <div>
                {sectionsAccess ? (
                  <Button
                    key="submit"
                    type="default"
                    onClick={openCreateModal}
                    className={styles.popoverHeaderAddButton}
                  >
                    <Icon type="icon interface-72" />
                    {t("section.menu.create")}
                  </Button>
                ) : null}
              </div>
            </Layout.Header>
            <Layout.Content>
              {_.isEmpty(filteredItems) ? (
                <div className={styles.notFound}>
                  {t("menuPopover.searchEmpty")}
                </div>
              ) : (
                <div className={styles.sectionContainer}>
                  {_.map(filteredItems, item => item)}
                </div>
              )}
            </Layout.Content>
          </Layout>
        </Layout>
      </Layout.Content>
    </Layout>
  );
};

PopoverLayout.propTypes = {
  sectionsAccess: PropTypes.bool,
  showSideSearch: PropTypes.bool,
  searchText: PropTypes.string,
  onSearchChange: PropTypes.func,
  filteredItems: PropTypes.array,
  openCreateModal: PropTypes.func,
  visible: PropTypes.bool,
  showSidebarHeader: PropTypes.bool,
  handleClickCreacteCompany: PropTypes.func
};

export default PopoverLayout;
