import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";
import cn from "classnames";

import Immutable from "immutable";
import { Tag } from "antd";
import tinycolor from "tinycolor2";

import styles from "./controls.less";

const items = Immutable.fromJS([
  {
    id: "$HAS",
    name: "Есть",
    color: "d3e3ff"
  },
  {
    id: "$EMPTY",
    name: "Нет",
    color: "ffffff"
  }
]);

class FileField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value
    };
  }

  onClickItem = value => {
    if (this.props.readOnly) {
      return;
    }

    const { onSave, fieldId } = this.props;

    this.setState({
      value
    });

    onSave(fieldId, value);
  };

  componentWillReceiveProps(nextProps) {
    if (!Immutable.is(nextProps.value, this.props.value)) {
      this.setState({
        value: nextProps.value
      });
    }
  }

  render() {
    return (
      <div className={styles.tagsContainer}>
        {items.map(item => {
          const id = item.get("id");
          const selected = this.state.value == id;
          const backgroundColor = "#" + item.get("color");
          const color = tinycolor(backgroundColor)
            .darken(65)
            .toString();
          return (
            <Tag
              key={id}
              onClick={_.bind(this.onClickItem, this, id)}
              style={
                selected
                  ? {
                      backgroundColor: backgroundColor,
                      border: "1px solid rgba(0,0,0,0.1)",
                      color: color
                    }
                  : { backgroundColor: "transparent" }
              }
              className={cn(styles.tags, { readonly: this.props.readOnly })}
            >
              {item.get("name")}
            </Tag>
          );
        })}
      </div>
    );
  }
}

FileField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  config: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
};

export default FileField;
