// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header__optionsContainer___Bogx0 {\n  position: absolute;\n  right: 0;\n  top: 0;\n  padding-right: 10px;\n  text-align: right;\n  height: 34px;\n  z-index: 1;\n  background: linear-gradient(to right, rgba(239, 241, 244, 0.8), #eff1f4 50%, rgba(239, 241, 244, 0.8));\n}\n.header__optionsContainer___Bogx0:hover,\n.header__optionsContainer___Bogx0:focus,\n.header__optionsContainer___Bogx0:active {\n  background: linear-gradient(to right, rgba(239, 241, 244, 0.8), #eff1f4 50%, rgba(239, 241, 244, 0.8));\n}\n", "",{"version":3,"sources":["webpack://./src/components/Cards/Header/header.less"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,QAAA;EACA,MAAA;EACA,mBAAA;EACA,iBAAA;EACA,YAAA;EACA,UAAA;EACA,sGAAA;AADF;AAQE;;;EAGE,sGAAA;AANJ","sourcesContent":["@import (reference) \"../../../styles/helpers/index.less\";\n\n.optionsContainer {\n  position: absolute;\n  right: 0;\n  top: 0;\n  padding-right: @PADDING_BASE;\n  text-align: right;\n  height: @RECORD_DATA_HEADER - 1px; // Otherwise, the border of the header is invisible\n  z-index: 1;\n  background: linear-gradient(\n    to right,\n    fade(@BG_HEADER, 80),\n    @BG_HEADER 50%,\n    fade(@BG_HEADER, 80)\n  );\n\n  &:hover,\n  &:focus,\n  &:active {\n    background: linear-gradient(\n      to right,\n      fade(@BG_HEADER, 80),\n      @BG_HEADER 50%,\n      fade(@BG_HEADER, 80)\n    );\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionsContainer": "header__optionsContainer___Bogx0"
};
export default ___CSS_LOADER_EXPORT___;
