import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import Icon from '../../UI/Icon';
import AbstractMenu from "../AbstractMenu";
import NavLink from "../../router/Link";

import defaultTheme from "./defaultTheme.less";
import lightTheme from "./lightTheme.less";

const TabsMenu = ({ ...props }) => {
  const {
    activeId,
    items,
    route,
    params,
    extParams = {},
    dragType,
    canDrag,
    onDragEnd,
    className,
    filterFn,
    theme,
    onClick
  } = props;
  let styles = {};
  let buttonMoreProps;

  switch (theme) {
    case "light":
      styles = lightTheme;
      buttonMoreProps = { light: true };
      break;
    default:
      styles = defaultTheme;
  }

  const horizontal = {
    menu: styles.horizontalMenu,
    item: styles.listItem
  };
  const vertical = {
    menu: styles.verticalMenu
  };

  const getIcon = item => (
    <Icon type={"icon " + item.get("icon")} className={styles.icon} />
  );
  const getTextName = item => (
    <span className={styles.text}>{item.get("name")}</span>
  );
  const getNameActive = item => (
    <span className={styles.text}>{item.get("nameActive")}</span>
  );
  const getNavLink = (item, linkParams, title, icon, textActive, text) => (
    <NavLink
      key={item.get("id")}
      route={item.get("route") || route}
      params={linkParams}
      render={props => {
        return (
          <Link
            onClick={() => onClick && onClick(item)}
            to={props.link}
            title={title}
            className={cn(styles.link, {
              [styles.selected]: props.isActive
            })}
          >
            {icon}
            {props.isActive ? textActive : text}
          </Link>
        );
      }}
    />
  );
  const getNativeLink = (item, title, icon, text) => (
    <a
      key={item.get("id")}
      rel="noreferrer"
      className={cn(styles.link, styles.addButton, {
        [styles.selected]: activeId === item.get("id")
      })}
      onClick={() => {
        const callFn = item.get("onClick");
        callFn && callFn();
        onClick && onClick(item.get("id"));
      }}
      title={title}
    >
      {icon}
      {text}
    </a>
  );

  const getItems = items => {
    return items.map((item, i) => {
      const icon = item.get("icon") ? getIcon(item) : null;
      const title = item.get("title") || item.get("text");
      const text = item.get("name") && getTextName(item);
      const textActive =
        (item.get("nameActive") && getNameActive(item)) || text;
      const linkWithRoute =
        item.get("route") || (route && !item.get("onClick"));

      if (linkWithRoute) {
        const linkParams = Object.assign(
          {},
          extParams,
          params && { [params]: item.get("id") }
        );
        return getNavLink(item, linkParams, title, icon, textActive, text);
      } else {
        return getNativeLink(item, title, icon, text);
      }
    });
  };

  return (
    <AbstractMenu
      horizontal={horizontal}
      vertical={vertical}
      dragType={dragType}
      canDrag={canDrag}
      onDragEnd={onDragEnd}
      className={className}
      filterFn={filterFn}
      buttonMoreProps={buttonMoreProps}
      items={getItems(items)}
    />
  );
};

export default TabsMenu;
