// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sectionBody__body___qEK4n {\n  height: 100%;\n  overflow: hidden;\n  width: calc(50% - (55px + 10px));\n  padding-top: 5px;\n  padding-left: 5px;\n  padding-bottom: 15px;\n}\n.sectionBody__bodyFixed___AC-aa {\n  width: calc(50% - (55px + 10px + 95px));\n}\n", "",{"version":3,"sources":["webpack://./src/components/App/AppBody/Section/SectionBody/sectionBody.less"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,gBAAA;EACA,gCAAA;EACA,gBAAA;EACA,iBAAA;EACA,oBAAA;AAAF;AAGA;EACE,uCAAA;AADF","sourcesContent":["@import (reference) \"../../../../../styles/helpers/index.less\";\n.body {\n  height: 100%;\n  overflow: hidden;\n  width: calc(~\"50%\" - (55px + @PADDING_BASE)); // width catalog menu + padding\n  padding-top: @PADDING_BASE / 2;\n  padding-left: @PADDING_BASE / 2;\n  padding-bottom: @PADDING_BASE*1.5;\n}\n\n.bodyFixed {\n  width: calc(~\"50%\" - (55px + @PADDING_BASE + 95px)); // width sidebarFixed\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "sectionBody__body___qEK4n",
	"bodyFixed": "sectionBody__bodyFixed___AC-aa"
};
export default ___CSS_LOADER_EXPORT___;
