import React from "react";
import cn from "classnames";

import NavRoute from "../../../../../../common/router/Route";
import routes from "../../../../../../../routes";
import ViewsMenu from "./ViewsMenu";
import FilterList from "./FilterList";

import styles from "./catalogBody.less";

const CatalogBody = function({ ...props }) {
  const { catalog, sceneId, leftPanel } = props;

  return (
    <div
      className={cn(styles.container, {
        [styles.containerHidden]: !leftPanel
      })}
    >
      <ViewsMenu
        catalog={catalog}
        sceneId={sceneId}
        viewId={props.match && props.match.params.viewId}
      />
      <NavRoute
        route={routes.view}
        children={props => {
          return (
            <FilterList
              catalog={catalog}
              sceneId={sceneId}
              viewId={props.match && props.match.params.viewId}
            />
          );
        }}
      />
    </div>
  );
};

export default CatalogBody;
