import React from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import RecordFields from "../../../Record/RecordBody/mainTab/RecordFields";
import FIELD_TYPES from "../../../../configs/fieldTypes";

import styles from "./ObjectField.less";

class SelectFieldsRights extends React.PureComponent {
  static propTypes = {
    item: PropTypes.object,
    fields: PropTypes.object,
    onChange: PropTypes.func
  };

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  render() {
    const { item, fields, onChange } = this.props;

    let extFields = fields || [];
    let extValues = {};

    // create fields as category: viow|edit
    extFields = extFields.map(f => {
      f = f.set("visible", true);
      f = f.set("visibleRules", Immutable.Map());
      f = f.set("hint", "");
      f = f.set("required", false);
      f = f.set("apiOnly", false);
      if (f.get("type") !== FIELD_TYPES.GROUP) {
        f = f.set("type", FIELD_TYPES.DROPDOWN);
        f = f.set(
          "config",
          Immutable.fromJS({
            items: [
              {
                id: "view",
                name: this.props.t("rights.view"),
                color: "e6e7ea"
              },
              {
                id: "edit",
                name: this.props.t("rights.edit"),
                color: "D0FBBF"
              }
            ],
            multiselect: false
          })
        );
      }
      extValues[f.get("id")] = [f.get("editable") ? "edit" : "view"];
      return f;
    });
    extValues = Immutable.fromJS(extValues);
    return (
      <div className={styles.linkedItemExtended}>
        <RecordFields
          catalogId={item.get("catalogId")}
          recordId={"0"}
          fields={extFields}
          values={extValues}
          compact={true}
          onSaveField={onChange}
        />
      </div>
    );
  }
}

export default withTranslation()(SelectFieldsRights);
