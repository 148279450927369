import React from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import cn from "classnames";
import { withTranslation } from "react-i18next";

import apiActions from "../../../../actions/apiActions";
import DefaultRedirect from "../../../common/router/DefaultRedirect";
import { alert } from "../../../common/Modal";
import routes from "../../../../routes";
import mapRouteParams from "../../../common/router/mapRouteParams";
import catalogActions from "../../../../actions/catalogActions";
import { connect } from "../../../StateProvider";

import ListMenu from "../../../common/menu/ListMenu";
import styles from "./section.less";

class CatalogsMenu extends React.PureComponent {
  static propTypes = {
    sectionId: PropTypes.string,
    catalogs: PropTypes.object.isRequired
  };

  componentDidMount() {
    // load all catalogs at start
    apiActions.getCatalogs(null);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.sectionId !== nextProps.sectionId) {
      apiActions.getCatalogs(null, { sectionId: nextProps.sectionId });
    }
  }

  saveToServer = orderKey => {
    const filteredKey = orderKey.filter(key => key !== "addButton");
    catalogActions.changeSortIndex(filteredKey);
    apiActions.updateSection(
      {
        sectionId: this.props.sectionId
      },
      {
        catalogsPriorities: filteredKey
      }
    );
  };

  isSchemaLocked = () => {
    const { t } = this.props;
    const isSchemaLocked =
      this.props.license && this.props.license.getIn(["modules", "schemalock"]);
    if (isSchemaLocked) {
      alert({
        headerText: t("modals.schemaLock.headerText"),
        text: t("modals.schemaLock.text"),
        okText: t("modals.schemaLock.okText")
      });
    }
    return isSchemaLocked;
  };

  goToNewCatalog = () => {
    if (this.isSchemaLocked()) {
      return;
    }

    this.props.history.push({
      pathname: `/section/${this.props.sectionId}/add`,
      search: this.props.location.search
    });
  };

  canDrag = key => {
    if (!this.props.isAccessAdmin) {
      return false;
    }
    if (key !== "addButton") {
      return true;
    }
  };

  render() {
    const history = this.props.history;
    const sectionId = this.props.sectionId;
    const catalogId = this.props.catalogId;
    const viewId = this.props.viewId;

    let catalogs = this.props.catalogs
      .valueSeq()
      .filter(
        c =>
          c.get("sectionId") === sectionId &&
          // show catalogs with privilegeCode > available (search, view, ...)
          c.get("privilegeCode") !== "available"
      )
      .sortBy(c => c.get("index"));

    const firstCatalog = catalogs.get(0);
    let catalogListClassName;
    if (this.props.isAccessAdmin) {
      catalogs = catalogs.concat([
        Immutable.Map({
          id: "addButton",
          icon: "interface-72",
          name: this.props.t("buttons.add"),
          onClick: this.goToNewCatalog
        })
      ]);

      if ((catalogs && catalogs.size) == 1) {
        catalogListClassName = styles.listWithNoCatalogs;
      }
    }
    return (
      <div className={cn(styles.catalogsMenuContainer, catalogListClassName)}>
        <DefaultRedirect
          route={routes.catalog}
          params="catalogId"
          object={firstCatalog}
        />
        <ListMenu
          route={routes.catalog}
          params="catalogId"
          items={catalogs}
          canDrag={this.canDrag}
          onDragEnd={this.saveToServer}
          dragType="catalog"
          direction="column"
          history={history}
          catalogId={catalogId}
          viewId={viewId}
        />
      </div>
    );
  }
}

export default withTranslation()(
  connect(
    mapRouteParams(CatalogsMenu, routes.catalog),
    ["license"]
  )
);
