import queryString from "query-string";
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

import { RESET_PASSWORD } from '../helpers/const'
import apiActions from "../../../actions/apiActions";
import SetPassForm from './SetPass'

import styles from "../styles.less";

const SetPass = (props) => {
  const { t } = useTranslation();
  const [token, setToken] = useState(null)
  const [isReset, setIsReset] = useState(null)

  useEffect(() => {
    const parsed = queryString.parse(props.location.search);
    if (parsed.action && parsed.action === RESET_PASSWORD) {
      setIsReset(true)
    } else {
      setIsReset(false)
    }

    setToken(parsed.token);
  }, []);

  const setPassword = (password) => {
    return new Promise((resolve, reject) => {
      apiActions.setPass(token, password).then(response => {
        window.location.href = "/";
      }).catch(err => reject(err));
    })
  }

  const resetPassword = (password) => {
    return new Promise((resolve, reject) => {
      apiActions.setNewPass(token, password).then(response => {
        window.location.href = "/";
      }).catch(err => reject(err));
    })
  }

  return (
    <div className={styles.formContainer}>
      <div className={styles.formInner}>
        <SetPassForm
          token={token}
          onPasswordSet={isReset ? resetPassword : setPassword}
          title={
            isReset ? t("auth.passwordResetTitle") : t("auth.setPass")
          }
        />
      </div>
    </div>
  )
}

export default SetPass
