import React from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import cn from "classnames";
import { withTranslation } from "react-i18next";

import { connect } from "../../../StateProvider";
import { Form, Row, Col } from "antd";

import SelectSource from "./SelectSource";
import SelectFields from "./SelectFields";
import SelectFilter from "./SelectFilter";
import SelectFieldsRights from "./SelectFieldsRights";
import PreviewLinkedRecord from "./PreviewLinkedRecord";

import Icon from "../../../common/UI/Icon";
import dynamicFiltersList from "./helpers/dynamicFiltersList";
import ButtonClose from "../../../common/UI/ButtonClose";
import { OBJECT_MODS } from "../../../../configs/fieldTypes";

import styles from "./ObjectField.less";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 8 },
    lg: { span: 6 },
    xl: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
    md: { span: 16 },
    lg: { span: 18 },
    xl: { span: 20 }
  }
};

const MODES_TO_RENDER_SELECT = [OBJECT_MODS.TABLE, OBJECT_MODS.CARDS];

class ObjectItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSelectSource = value => {
    let key = value.key.split(":");

    let item = value;

    if (key[0] == "view") {
      item.catalogId = value.catalogId;
      item.viewId = key[1];
    } else if (key[0]) {
      item.catalogId = key[0];
      item.viewId = 0;
    } else {
      return;
    }
    item.title = value.text;

    // fields
    const previousCatalogId =
      this.props.item && this.props.item.get("catalogId");
    if (previousCatalogId !== item.catalogId) {
      item.fields = [];
    } else {
      const fields = this.props.item.get("fields");
      item.fields = fields ? fields.toJS() : [];
    }

    // set
    item = Immutable.fromJS(item);
    this.props.onChange(item);
  };

  onSelectFields = value => {
    let item = this.props.item;

    // save field rights from alreade selected
    let curentFields = item.get("fields");

    if (curentFields) {
      value = value.map(f => {
        const fieldId = f.get("id");
        const cf = curentFields.find(cf => cf.get("id") === fieldId);
        if (cf) {
          f = f.set("editable", cf.get("editable"));
        }
        return f;
      });
    }

    item = item.setIn(["fields"], value);
    this.props.onChange(item);
  };

  onSelectFieldsRights = value => {
    let item = this.props.item;

    let fields = item.get("fields");
    const fieldsIndex = fields.findIndex(f => f.get("id") == value.fieldId);
    fields = fields.setIn([fieldsIndex, "editable"], value.data[0] === "edit");
    item = item.setIn(["fields"], fields);

    this.props.onChange(item);
  };

  onSelectFilter = value => {
    let item = this.props.item;

    item = item.setIn(["dynamicFilters"], value);

    this.props.onChange(item);
  };

  render() {
    const {
      sourceCatalog,
      editingCatalog,
      item,
      disabled,
      autoFocus,
      key,
      view,
      t
    } = this.props;
    const sourceCatalogId = item && item.get("catalogId");
    const hasFields = item && item.get("fields") && item.get("fields").size;
    const showSelect = MODES_TO_RENDER_SELECT.includes(view);
    const source = item
      ? {
          key: item.get("key"),
          text: item.get("title"),
          icon: item.get("icon")
        }
      : {};

    const filterItems = dynamicFiltersList(sourceCatalog, editingCatalog);
    return (
      <div className={styles.itemContainer} key={key}>
        <ButtonClose
          className={styles.btnItemRemove}
          onClick={this.props.onRemove}
          small
        />

        <Form.Item
          {...formItemLayout}
          className={styles.formRow}
          colon={false}
          label={t("fieldTypes.object.item.sourceLabel")}
        >
          <SelectSource
            type={["catalogs", "views"]}
            value={source}
            onChange={this.onSelectSource}
            disabled={disabled}
            autoFocus={autoFocus}
            placeholder={t("fieldTypes.object.item.sourcePlaceholder")}
          />
        </Form.Item>

        {sourceCatalogId && filterItems && filterItems.size ? (
          <Form.Item
            {...formItemLayout}
            className={styles.formRow}
            colon={false}
            label={t("fieldTypes.object.item.filterLabel")}
          >
            <SelectFilter
              editingCatalog={editingCatalog}
              items={filterItems}
              value={item.get("dynamicFilters")}
              onChange={this.onSelectFilter}
              disabled={disabled}
              placeholder={t("fieldTypes.object.item.filterPlaceholder")}
            />
          </Form.Item>
        ) : null}

        {sourceCatalogId && !(item.get("disableFields") && !hasFields) ? (
          <Form.Item
            {...formItemLayout}
            className={styles.formRow}
            colon={false}
            label={t("fieldTypes.object.item.fieldsLabel")}
          >
            <SelectFields
              catalogId={sourceCatalogId}
              value={item.get("fields")}
              onChange={this.onSelectFields}
              disabled={disabled || item.get("disableFields")}
              placeholder={t("fieldTypes.object.item.fieldsPlaceholder")}
            />
          </Form.Item>
        ) : null}

        {showSelect && sourceCatalogId && hasFields ? (
          <Form.Item
            {...formItemLayout}
            className={cn(styles.formRow)}
            colon={false}
            label={t("fieldTypes.object.item.rightsLabel")}
          >
            <SelectFieldsRights
              item={item}
              fields={item.get("fields")}
              onChange={this.onSelectFieldsRights}
            />
          </Form.Item>
        ) : null}

        {false && sourceCatalogId ? (
          <Form.Item
            {...formItemLayout}
            className={cn(styles.formRow, styles.previewRow)}
            colon={false}
            label={
              <span className={styles.previewLabel}>
                {t("fieldTypes.object.item.previewLabel")}
              </span>
            }
          >
            <PreviewLinkedRecord item={item} fields={item.get("fields")} />
          </Form.Item>
        ) : null}
      </div>
    );
  }
}

ObjectItem.propTypes = {
  editingCatalog: PropTypes.object,
  item: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool
};

export default withTranslation()(
  connect(
    ObjectItem,
    ["catalogs"],
    ({ item, ...props }, { catalogs }) => {
      const sourceCatalogId = item && item.get("catalogId");
      const sourceCatalog = catalogs.find(c => c.get("id") === sourceCatalogId);
      return {
        sourceCatalog,
        item,
        ...props
      };
    }
  )
);
