import React, { PureComponent } from "react";
import { CSSTransitionGroup } from "react-transition-group";
import { Row } from "antd";

import { withRouter } from "react-router-dom";
import LayoutLeftPanel from "./LayoutLeftPanel";
import LayoutMiddlePanel from "./LayoutMiddlePanel";
import LayoutRightPanel from "./LayoutRightPanel";
import Splitter from "../../../../../common/Splitter";
import NavRoute from "../../../../../common/router/Route";
import routes from "../../../../../../routes";
import uiActions from "../../../../../../actions/uiActions";
import userSettingsActions from "../../../../../../actions/userSettingsActions";
// import catalogActions from "../../../../../../actions/catalogActions";
import { connect } from "../../../../../StateProvider";

import styles from "./catalog.less";

const ANIMATION_DELAY = 200;

class ROW extends PureComponent {
  render() {
    const { props } = this;
    return <Row type="flex" {...props} />;
  }
}

class Catalog extends PureComponent {
  state = {
    leftPanel: this.props.leftPanel
  };

  changeViewMenuVisibility = () => {
    this.setState(
      state => ({
        leftPanel: !state.leftPanel
      }),
      () => uiActions.leftPanelVisible(this.state.leftPanel)
    );
  };

  render() {
    const { catalogIsLoaded, sceneIsLoaded, sceneId, catalogId } = this.props;
    const { leftPanel } = this.state;
    // const userSettingsLoaded = this.state[catalogId];
    if (!catalogIsLoaded || !sceneIsLoaded) {
      return null;
    }

    return (
      <NavRoute route={routes.record}>
        {props => (
          <CSSTransitionGroup
            component={ROW}
            className={styles.container}
            transitionName={{
              enter: styles.leftEnter,
              enterActive: styles.leftEnterActive,
              leave: styles.leftLeave,
              leaveActive: styles.leftLeaveActive
            }}
            transitionEnterTimeout={ANIMATION_DELAY}
            transitionLeaveTimeout={ANIMATION_DELAY}
            transitionAppear={false}
            transitionEnter={false}
            transitionLeave={false}
          >
            {!props.match && (
              <NavRoute
                route={routes.catalog}
                render={() =>
                  !props.match && (
                    <LayoutLeftPanel
                      sceneId={sceneId}
                      catalogId={catalogId}
                      history={props.history}
                      // userSettingsLoaded={userSettingsLoaded}
                      leftPanel={leftPanel}
                      changeViewMenuVisibility={this.changeViewMenuVisibility}
                    />
                  )
                }
              />
            )}
            <CSSTransitionGroup
              component={ROW}
              className={styles.childTransitionGroup}
              transitionName={{
                enter: styles.rightEnter,
                enterActive: styles.rightEnterActive,
                leave: styles.rightLeave,
                leaveActive: styles.rightLeaveActive
              }}
              transitionEnterTimeout={ANIMATION_DELAY}
              transitionLeaveTimeout={ANIMATION_DELAY}
              transitionLeave={true}
            >
              <LayoutMiddlePanel
                sceneId={sceneId}
                catalogId={catalogId}
                // viewId={props.match.params.viewId}
                // userSettingsLoaded={userSettingsLoaded}
              />
              {props.match && (
                <Splitter
                  componentName="record"
                  defaultWidth={40}
                  transparent={true}
                >
                  <LayoutRightPanel
                    catalogId={props.match.params.catalogId}
                    sceneId={sceneId}
                    recordId={props.match.params.recordId}
                    viewId={props.match.params.viewId}
                  />
                </Splitter>
              )}
            </CSSTransitionGroup>
          </CSSTransitionGroup>
        )}
      </NavRoute>
    );
  }
}

export default connect(
  withRouter(Catalog),
  {
    catalogs: ["catalogs"],
    scenes: ["scenes"],
    leftPanel: ["ui", "leftPanel", "visible"]
  },
  (props, { catalogs, scenes, leftPanel }) => {
    const { catalogId } = props.match.params;
    const catalogIsLoaded = !!catalogs.get(catalogId);
    const sceneIsLoaded = !!scenes.get(props.sceneId);
    return {
      ...props,
      catalogId,
      catalogIsLoaded,
      sceneIsLoaded,
      leftPanel
    };
  }
);
