import FIELD_TYPES from "../../configs/fieldTypes";

import Group from "./Group";
import Address from "./Address";
import CheckBoxes from "./CheckBoxes";
import Contact from "./Contact";
import Date from "./Date";
import Dropdown from "./Dropdown";
import Switch from "./Switch";
import File from "./File";
import Object from "./Object";
import Number from "./Number";
import Progress from "./Progress";
import RadioButton from "./RadioButton";
import Stars from "./Stars";
import Text from "./Text";
import User from "./User";
import Button from "./Button";

const interfaces = {
  [FIELD_TYPES.GROUP]: Group, /* require("./Group").default */
  [FIELD_TYPES.ADDRESS]: Address /* require("./Address").default */,
  [FIELD_TYPES.CHECKBOXES]: CheckBoxes /* require("./CheckBoxes").default */,
  [FIELD_TYPES.CONTACT]: Contact /* require("./Contact").default */,
  [FIELD_TYPES.DATE]: Date /* require("./Date").default */,
  [FIELD_TYPES.DROPDOWN]: Dropdown /* require("./Dropdown").default */,
  [FIELD_TYPES.SWITCH]: Switch /* require("./Switch").default */,
  [FIELD_TYPES.BUTTON]: Button /* require("./Button").default */,
  [FIELD_TYPES.FILE]: File /* require("./File").default */,
  [FIELD_TYPES.OBJECT]: Object /* require("./Object").default */,
  [FIELD_TYPES.NUMBER]: Number /* require("./Number").default */,
  [FIELD_TYPES.PROGRESS]: Progress /* require("./Progress").default */,
  [FIELD_TYPES.RADIOBUTTON]: RadioButton /* require("./RadioButton").default */,
  [FIELD_TYPES.STARS]: Stars /* require("./Stars").default */,
  [FIELD_TYPES.TEXT]: Text /* require("./Text").default */,
  [FIELD_TYPES.USER]: User /* require("./User").default */
};

const F = field => interfaces[field.get("type")] || {};

export default {
  isEmpty: (field, value) => value === undefined || F(field).isEmpty(value),
  createComponent: (field, value, componentType) => F(field).createComponent(field, value, componentType),
  receivePossibleItems: (field, records, filters) => F(field).receivePossibleItems && F(field).receivePossibleItems(field, records, filters),
  calcId: (field, value) => F(field).calcId(value),
  valueIs: (field, value, type) => F(field).valueIs(value, type),
  convertIdToValue: (field, id, records, filters) => F(field).convertIdToValue(field, id, records, filters),
  setValue: (field, prevValues, newValues) => F(field).setValue(prevValues, newValues),
  removeItem: (field, values, id) => F(field).removeItem(values, id),
  getEmptyValue: field => F(field).getEmptyValue(),
  receivePossibleItemById: (field, id, filters) => F(field).receivePossibleItemById(field, id, filters),
  compare: (field, value1, value2) => F(field).compare(value1, value2),
  sortValues: (field, values, sortType) => F(field).sortValues && F(field).sortValues(field, values, sortType),
  hasItem: (field, values, item) => F(field).hasItem(values, item),
  getComponent: (field, type) => F(field).getComponent(type),
  parseValue: (value, field, catalogId, locale) => F(field).parseValue(value, field, catalogId, locale),
  validateValue: (value, field, outError) => F(field).validateValue(value, field, outError),
  convertFilterToRecordValue: (field, value, catalogs, user) => F(field).convertFilterToRecordValue(value, catalogs, user),
  getDefaultValue: field => F(field).getDefaultValue(field),
  validateRequired: (field, value) => F(field).validateRequired(value),
  boardWitchColor: (field) => F(field).boardWitchColor(),
  visibleRules: (field, value) => F(field, value).visibleRules(value),
  sortRecordsInCards: (field, records) => F(field, records).sortRecordsInCards(field, records)
};
