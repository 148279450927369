import React from "react";
import { withRouter } from "react-router-dom";

import RecordController from "../../../../../Record/RecordController";
import recordActions from "../../../../../../actions/recordActions";
import sceneActions from "../../../../../../actions/sceneActions";
import SCENE_CONTAINER from "../../../../../../configs/sceneContainer";

class LayoutRightPanel extends React.Component {
  state = {};

  requestForRecordsCf = () => {
    const { catalogId, viewId, sceneId } = this.props;

    recordActions.requestForRecords(catalogId, sceneId, {
      viewId: viewId
    });
  };

  componentDidMount() {
    this.createScene();
  }

  componentWillUnmount() {
    sceneActions.deleteScene(this.state.sceneId);
  }

  componentDidUpdate(prevProps, prevState) {
    const prevRecordId = prevProps.recordId;
    const currentRecordId = this.props.recordId;

    const prevCatalogId = prevProps.catalogId;
    const currentCatalogId = this.props.catalogId;

    const sceneId = this.state.sceneId;

    /* При открытии создании новой записи из новой записи условие не отрабатывает, поэтому нужно обработать этот кейс по-другому */
    /* смена каталога или записи должна сопровождаться сменой сцены */
    if (prevCatalogId !== currentCatalogId) {
      /* Комопонента анмаутится из-за условия в компоненте выше. При смене каталога и записи, будет удаляться сцена записи */
      /* удаляется предыдущая сцена */
      sceneActions.deleteScene(sceneId);
    } else if (prevRecordId !== currentRecordId) {
      /* обнуление айди новой записи с предыдущей процедуры создания новой записи */
      this.setState({
        recordId: undefined
      });

      /* создается новая сцена */
      this.createScene();
      /* удаляется предыдущая сцена */
      sceneActions.deleteScene(sceneId);
    }
  }

  onClose = sceneId => {
    sceneActions.closeScene(sceneId);
  };

  onDelete = recordSceneId => {
    const { catalogId, viewId, sceneId: catalogSceneId } = this.props;

    sceneActions.closeScene(recordSceneId);
    recordActions.requestForRecords(catalogId, catalogSceneId, {
      viewId: viewId
    });
  };

  createScene = () => {
    const params = {
      catalogId: this.props.catalogId,
      parentSceneId: this.props.sceneId,
      recordId: this.props.recordId,
      viewId: this.props.viewId
    };

    if (this.props.recordId === "$new") {
      sceneActions.openNewRecord(
        params,
        {},
        {
          onCreate: this.openNewRecord
        },
        SCENE_CONTAINER.WINDOW
      );
    } else {
      sceneActions.openRecord(
        params,
        {
          onCreate: this.openRecord
        },
        SCENE_CONTAINER.WINDOW
      );
    }
  };

  openNewRecord = ({ sceneId, params }) => {
    this.setState({
      sceneId,
      recordId: params.recordId
    });
  };

  openRecord = ({ sceneId }) => {
    this.setState({
      sceneId
    });
  };

  render() {
    /* 
      тк новый айди генерируется в миксинах, 
      его стоит вернуть для использования компонентов ниже по иерархии, 
      но при переключении между записями, айди записи нужно брать из пропсов 
    */

    const recordId =
      this.props.recordId === "$new"
        ? this.state.recordId
        : this.props.recordId;

    return this.state.sceneId && recordId ? (
      <RecordController
        recordId={recordId}
        catalogId={this.props.catalogId}
        sceneId={this.state.sceneId}
        onClose={this.onClose}
        onCreate={this.requestForRecordsCf}
        onDelete={this.onDelete}
      />
    ) : null;
  }
}

export default withRouter(LayoutRightPanel);
