export const RESET_PASS_ACTION = "reset";
export const SUB_DOMAIN_PARAM = "domain";
export const ACTION_PARAM = "action";
export const TOKEN_PARAM = "token";
export const PASSPORT_USERNAME_FIELD = "email";
export const PASSPORT_PASSWORD_FIELD = "password";
export const LOGIN_LIMIT_ERROR_CODE = 429;
export const NOT_FOUND_STATUS_404 = 404;
export const RESET_PASSWORD = "reset";
export const EMAIL_FIELD = PASSPORT_USERNAME_FIELD;

export const REGISTER_FIELDS = {
  COMPANY_NAME: "companyName",
  ADDRESS: "address",
  PHONE: "phone",
  NAME: "name",
  PASSWORD: "password",
  PASSWORD_REPEAT: "passwordRepeat"
};
export const SERVER_ERROR = {
  EMAIL_NOT_REGISTERED: "email not registered",
  UNAUTHORIZED: "Unauthorized"
};
