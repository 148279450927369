/**
 *
 * @returns {string}
 */

const redirectWithBackurl = path => {
  if (window.location.href.indexOf("back_url") === -1) {
    window.location.href =
      path + "?back_url=" + encodeURIComponent(window.location.href);
  } else {
    window.location.href = path;
  }
};

export default {
  logout() {
    window.location.href = "/auth/logout";
  },

  authorize() {
    if (window.location.href.indexOf("auth/login") > -1) {
      return;
    }

    redirectWithBackurl("/#/auth/companies");
  },

  login() {
    window.location.href = "/#/auth/companies";
  },

  paymentRequired() {
    redirectWithBackurl("/#/auth/payment/required");
  }
};
