import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import { withTranslation } from "react-i18next";

import editorActions from "../../../actions/editorActions";
import { EMAIL, PHONE, SITE } from "../../../configs/contactFieldSubTypes";

const Option = Select.Option;
const fieldTypes = [PHONE, EMAIL, SITE];

class TextField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      items: fieldTypes.map(type => {
        return {
          key: type,
          text: this.props.t(
            `fieldTypes.${this.props.field.get("type")}.types.` + type
          )
        };
      })
    };
  }

  onSelect = itemKey => {
    editorActions.changeFieldConfig(
      this.props.sectionId,
      this.props.fieldIndex,
      {
        type: itemKey
      }
    );
  };

  render() {
    return (
      <div>
        <Select
          style={{ width: "100%" }}
          children={this.state.items.map(item => (
            <Option key={item.key}>{item.text}</Option>
          ))}
          defaultValue={this.props.field.getIn(["config", "type"])}
          onChange={this.onSelect}
        />
      </div>
    );
  }
}

TextField.propTypes = {
  field: PropTypes.object.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  sectionId: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default withTranslation()(TextField);
