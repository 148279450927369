// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".record__container___Syqkm {\n  height: 100%;\n  width: 100%;\n  background-color: #ffffff;\n  flex-basis: 100%;\n  min-width: 0px;\n}\n.record__newRecordCreated___qCyXX {\n  color: #b6b8ba;\n  text-align: center;\n  white-space: nowrap;\n  padding-top: 10px;\n}\n@media (max-width: 1150px) {\n  .record__container___Syqkm {\n    z-index: 99;\n    position: fixed;\n    left: 0;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Record/record.less"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,WAAA;EACA,yBAAA;EACA,gBAAA;EACA,cAAA;AAAF;AAGA;EACE,cAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;AADF;AAIA;EACE;IACE,WAAA;IACA,eAAA;IACA,OAAA;EAFF;AACF","sourcesContent":["@import (reference) \"../../styles/helpers/index.less\";\n.container {\n  height: 100%;\n  width: 100%;\n  background-color: @BG_CONTENT;\n  flex-basis: 100%;\n  min-width: 0px;\n}\n\n.newRecordCreated {\n  color: @TEXT_MUTED;\n  text-align: center;\n  white-space: nowrap;\n  padding-top: 10px;\n}\n\n@media (max-width: 1150px) {\n  .container {\n    z-index: 99;\n    position: fixed;\n    left: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "record__container___Syqkm",
	"newRecordCreated": "record__newRecordCreated___qCyXX"
};
export default ___CSS_LOADER_EXPORT___;
