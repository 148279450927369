import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { DropTarget } from "react-dnd";
import { withTranslation } from "react-i18next";

import dndTargets from "../../configs/dndTargets";
import editorActions from "../../actions/editorActions";
// import router from '../../router'

import styles from "./catalogEditor.less";

const dropTarget = DropTarget(
  [dndTargets.FIELD_TYPE, dndTargets.FIELD],
  {
    drop: function(props, monitor, component) {
      const item = monitor.getItem();
      
      // can only be in section
      if (item.collapsed) {
        editorActions.dropCollapsedSection(
          props.sectionId,
          item.fieldIndex,
          item.fieldType,
          component.props.prevFieldIndex
        );
      } else {
        editorActions.dropField(
          props.sectionId,
          item.fieldIndex,
          item.fieldType,
          component.props.prevFieldIndex
        );
      }

      props.onSelectField && props.onSelectField(props.prevFieldIndex + 1);
    },
    canDrop: function canDrop(props, monitor) {
      return !props.disabled;
    }
  },
  function collect(connect, monitor) {
    return {
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver()
    };
  }
);

class FieldDropArea extends React.PureComponent {
  render() {
    const { connectDropTarget, isOver } = this.props;
    const hovering = !this.props.disabled && isOver;

    let classes = classNames("dropdownField", styles.fieldDropArea, {
      [styles.fieldDropAreaHovering]: hovering
    });

    return connectDropTarget(
      <div className={classes}>
        <style
          dangerouslySetInnerHTML={{
            __html: [
              ".dropdownField:last-child:before {",
              `  content: "${this.props.t("catalogData.dropFieldHere")}";`,
              "}"
            ].join("\n")
          }}
        />
        <div
          className={classNames({
            [styles.fieldDropAreaHoveringChild]: hovering
          })}
        />
      </div>
    );
  }
}

FieldDropArea.propTypes = {
  prevFieldIndex: PropTypes.number.isRequired,
  disabled: PropTypes.bool
};

export default withTranslation()(dropTarget(FieldDropArea));
