import React from "react";
import NavRoute from "../../../../../common/router/Route";
import routes from "../../../../../../routes";
import View from "./View";

import styles from "./layoutMiddlePanel.less";

const LayoutMiddlePanel = ({ catalogId, sceneId }) => {
  return (
    <div className={styles.container}>
      <NavRoute
        route={routes.view}
        render={props => {
          return (
            <View
              sceneId={sceneId}
              catalogId={catalogId}
              viewId={props.match.params && props.match.params.viewId}
            />
          );
        }}
      />
    </div>
  );
};

export default LayoutMiddlePanel;
