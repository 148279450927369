import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import Button from "../../common/UI/ControlList/controls/Button/ButtonItem";
import ItemListEditor from "./ItemListEditor";

import styles from "./fields.less";

class ButtonField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { mode, t } = this.props;

    if (mode === "view") {
      const items = this.props.field.getIn(["config", "items"]);

      return (
        <div className={styles.wrapperViewMode}> 
        <div>
          {
            items.map(item => 
              <Button 
                className={styles.buttonInline}
                icon={ item.get("icon") } 
                backgroundColor={ item.get("color") } 
              > 
                {item.get("name")} 
              </Button>
            )
          }
          <span className={styles.textChange}>
            { t("catalogEditor.field.lastItemWhenCollapsed") + "..." }
          </span>
        </div>
      </div>
      );
    } else return <ItemListEditor
        disabled={this.props.disabled}
        fieldIndex={this.props.fieldIndex}
        sectionId={this.props.sectionId}
        field={this.props.field}
        withColor={true}
        isChangeIcon={true}
      />;
  }
}

ButtonField.propTypes = {
  field: PropTypes.object.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  sectionId: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default withTranslation()(ButtonField);
