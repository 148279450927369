import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import { connect } from "../StateProvider";

class PageHeaders extends React.PureComponent {
  static propTypes = {
    catalogName: PropTypes.string,
    sectionName: PropTypes.string,
    companyTitle: PropTypes.string
  };

  render() {
    const catalogName = this.props.catalogName;

    if (!catalogName) {
      return null;
    }

    let title;
    const { sectionName, companyTitle } = this.props;

    if (!companyTitle) {
      title = [sectionName, catalogName].join(" / ");
    } else {
      title = [companyTitle, catalogName].join(" / ");
    }

    return (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    );
  }
}

export default connect(
  PageHeaders,
  ["sections", "catalogs", "company"],
  ({ ...props }, { sections, catalogs, company }) => {
    const { catalogId } = props.match.params;
    const sectionId = catalogs.getIn([catalogId, "sectionId"]);
    return {
      catalogName: catalogs.getIn([catalogId, "name"]),
      sectionName: sections.getIn([sectionId, "name"]),
      companyTitle: company && company.get("title")
    };
  }
);
