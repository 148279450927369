import React from "react";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import FieldListEditor from "./FieldListEditor";
import Loading from "../common/Loading";

import styles from "./catalogEditor.less";

class CatalogEditorBody extends React.PureComponent {
  render() {
    const sectionId = this.props.match.params.sectionId;
    const catalog = this.props.catalog;
    const catalogs = this.props.catalogs;
    const dropType = this.props.dropType;
    const dropInfo = this.props.dropInfo;
    const disabled = this.props.disabled;

    return (
      <div className={styles.catalogEditorFieldsListWrapper}>
        {catalog ? (
          <FieldListEditor
            disabled={disabled}
            dropType={dropType}
            dropInfo={dropInfo}
            catalog={catalog}
            sectionId={sectionId}
            catalogs={catalogs}
            selectedFieldIndex={this.props.selectedFieldIndex}
            onSelectField={this.props.onSelectField}
          />
        ) : (
          <Loading fullHeight={true} />
        )}
      </div>
    );
  }
}

export default CatalogEditorBody;
