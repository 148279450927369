import React from "react";
import styles from "./fields.less";

const ProgressField = () => {
  // TODO React.memo
  return (
    <div className={styles.typeProgress}>
      <span>0 — 100%</span>
    </div>
  );
};

export default ProgressField;
