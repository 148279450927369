import _ from "lodash";
import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import PropTypes from "prop-types";

import historyActions from "../../../../actions/historyActions";
import HistoryItem from "../../../History/HistoryItem";
import NewComment from "./NewComment";

import styles from "./history.less";

class History extends React.PureComponent {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      onBottom: true
    };
  }

  loading(props = this.props) {
    return props.history.get("loading");
  }

  scrolltoBottom() {
    this.node.current.scrollTop = this.node.current.scrollHeight;
    this.setState({
      onBottom: true
    });
  }

  onScroll(e) {
    const onBottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight < 30;
    if (onBottom !== this.state.onBottom) {
      this.setState({ onBottom });
    }
    if (!this.loading() && e.target.scrollTop < window.innerHeight * 2) {
      historyActions.loadHistory(
        this.props.sceneId,
        this.props.catalogId,
        this.props.recordId
      );
    }
  }

  initHistory = () => {
    const { catalogId, recordId } = this.props;
    const preparedFields = this.props.getFieldsToRender();

    const fields = preparedFields.map(field => field.get("id")).toJS();

    this.setState(
      () => ({
        fields: preparedFields
      }),
      () => {
        historyActions.setFilter(
          { ...this.props.filter, fields },
          { catalogId, recordId }
        );
        historyActions.loadHistory(
          this.props.sceneId,
          catalogId,
          recordId,
          {},
          true
        );
      }
    );
  };

  componentDidMount() {
    this.onScroll = _.debounce(this.onScroll.bind(this), 200);
    this.scrolltoBottom();
    $(this.node.current).on("scroll", this.onScroll);
  }

  componentWillUnmount() {
    $(this.node.current).off("scroll", this.onScroll);
  }

  componentWillMount() {
    this.initHistory();
  }

  componentDidUpdate(prevProps, prevState) {
    const { catalogId, recordId } = this.props;
    const { fields } = this.state;
    const { fields: prevFields } = prevState;

    if (this.state.onBottom) {
      this.scrolltoBottom();
    } else if (this.loading(prevProps) && !this.loading()) {
      let el = this.node.current;
      let sh = el.scrollHeight;
      if (this.lastScrollHeight < sh) {
        el.scrollTop = el.scrollTop + sh - this.lastScrollHeight;
      }
    }

    if (prevFields && !prevFields.equals(fields)) {
      historyActions.loadHistory(
        this.props.sceneId,
        catalogId,
        recordId,
        {},
        true
      );
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const el = this.node.current;
    this.lastScrollHeight = el.scrollHeight;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      fields: this.props.getFieldsToRender()
    });
  }

  render() {
    const items = this.props.history.get("items");
    return (
      <div ref={this.node} className={styles.sectionFields}>
        {items &&
          items
            .reverse()
            .map(h => (
              <HistoryItem
                key={h.get("id")}
                item={h}
                fields={this.state.fields}
                catalogId={this.props.catalogId}
                recordId={h.get("recordId")}
              />
            ))}
        <NewComment
          catalogId={this.props.catalogId}
          recordId={this.props.recordId}
          sceneId={this.props.sceneId}
        />
      </div>
    );
  }
}

History.propTypes = {
  history: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  recordId: PropTypes.string,
  catalogId: PropTypes.string
};

export default History;
