// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".userAvatar__containerAvatar___OdEzZ {\n  background: rgba(255, 255, 255, 0.1);\n  border-radius: 20px;\n  cursor: pointer;\n}\n.userAvatar__userIcon___YJNzh {\n  color: rgba(255, 255, 255, 0.85);\n  width: 30px;\n  height: 30px;\n  padding: 9px 0;\n  border-radius: 50px;\n  text-align: center;\n  text-transform: uppercase;\n  font-size: 0.8em;\n  letter-spacing: 1px;\n}\n.userAvatar__arrow___P2BJB {\n  font-size: 11px;\n  color: #e6e8ec;\n  padding-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/App/AppHeader/UserMenu/UserAvatar/userAvatar.less"],"names":[],"mappings":"AAEA;EACI,oCAAA;EACA,mBAAA;EACA,eAAA;AADJ;AAIA;EACI,gCAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;EACA,mBAAA;AAFJ;AAKA;EACI,eAAA;EACA,cAAA;EACA,mBAAA;AAHJ","sourcesContent":["@import (reference) \"../../../../../styles/helpers/index.less\";\n\n.containerAvatar {\n    background: rgba(255, 255, 255, 0.1);\n    border-radius: @PADDING_BASE * 2;\n    cursor: pointer;\n}\n\n.userIcon {\n    color: rgba(255, 255, 255, 0.85);\n    width: 30px;\n    height: 30px;\n    padding: 9px 0;\n    border-radius: 50px;\n    text-align: center;\n    text-transform: uppercase;\n    font-size: 0.8em;\n    letter-spacing: 1px;\n}\n\n.arrow {\n    font-size: 11px;\n    color: @BG_PANELS;\n    padding-right: @PADDING_BASE;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerAvatar": "userAvatar__containerAvatar___OdEzZ",
	"userIcon": "userAvatar__userIcon___YJNzh",
	"arrow": "userAvatar__arrow___P2BJB"
};
export default ___CSS_LOADER_EXPORT___;
