import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import _ from "lodash";
import PropTypes from "prop-types";
import { Select } from "antd";
import { withTranslation } from "react-i18next";
import {DownOutlined} from "@ant-design/icons";

import availableLinkedRecordActions from "../../actions/availableLinkedRecordActions";
import { connect } from "../StateProvider";
// import SelectWithFilter from '../common/UI/SelectWithFilter'

const Option = Select.Option;

class SelectSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      loading: this.getLoadig(),
      items: this.getItems()
    };
  }

  getType = (props = this.props) => {
    return [].concat("sections");
  };

  getItems = (props = this.props) => {
    return _(this.getType(props))
      .map(type =>
        props.dropdownCollections.getIn([type, this.props.storeKey, "items"])
      )
      .map(items => (items ? items.toJS() : []))
      .flatten()
      .value();
  };

  getLoadig = (props = this.props) => {
    return this.getType(props).some(type =>
      props.dropdownCollections.getIn([type, this.props.storeKey, "loading"])
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateStateItems(this.getItems(nextProps));
  }

  updateStateItems = items => {
    let noEmptyRemoteItems = !(
      items.length === 0 && this.state.items.length > 0
    );
    if (!_.isEqual(this.state.items, items) && noEmptyRemoteItems) {
      this.setState({
        items,
        loading: this.getLoadig()
      });
    }
  };

  onOpenChange = isOpen => {
    if (isOpen) {
      let params = _.extend(
        { title: this.state.text },
        this.props.requestParams
      );
      this.getType().forEach(type =>
        availableLinkedRecordActions.loadAvailableItems(
          type,
          this.props.storeKey,
          params
        )
      );
      this.setState({ loading: true });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.onLoadItems &&
      this.state.items.length &&
      this.state.items !== prevState.items
    ) {
      this.props.onLoadItems(this.state.items);
    }
  }

  onChange = itemKey => {
    let items = this.state.items.concat(this.props.value);
    items = _.uniqBy(items, "key");

    const item = _.find(items, i => i.key === itemKey);

    this.props.onChange && this.props.onChange(item);
  };

  render() {
    const value = this.props.value;
    let items = this.state.items;

    if (value.key && !items.length) {
      items = items.concat(value);
    }

    if (this.props.filterFn) {
      items = items.filter(this.props.filterFn);
    }

    items = _.sortBy(items, "text");

    return (
      <Select
        showSearch
        value={value.key}
        onChange={this.onChange}
        filterOption={(inputValue, option) =>
          _.toLower(option.props.children).indexOf(_.toLower(inputValue)) >= 0
        }
        placeholder={this.props.placeholder}
        autocomplete={true}
        withButton={false}
        notFoundContent={this.props.t("dropdown.noitems")}
        onFocus={() => this.onOpenChange(true)}
        onBlur={() => this.onOpenChange(false)}
        autoFocus={this.props.autoFocus}
        disabled={this.props.disabled}
        style={{ width: "280px" }}
        suffixIcon={<DownOutlined />}
      >
        {items.map((item, i) => (
          <Option key={item.key}>{item.text}</Option>
        ))}
      </Select>
    );
  }
}

SelectSection.defaultProps = {
  storeKey: String(Math.random())
};

SelectSection.propTypes = {
  onLoadItems: PropTypes.func,
  filterFn: PropTypes.func,
  requestParams: PropTypes.object,
  blockForceUpdateForEmpty: PropTypes.bool,
  storeKey: PropTypes.string.isRequired
};

export default withTranslation()(
  connect(
    SelectSection,
    ["sections", "dropdownCollections"],
    ({ value, ...props }, { sections, dropdownCollections }) => ({
      ...props,
      dropdownCollections,
      value: {
        key: value,
        text: (sections && sections.getIn([value, "name"])) || ""
      }
    })
  )
);
