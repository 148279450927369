import { InputMappers, OutputMappers } from "../helpers/mappers";
import i18n from "../../../../../configs/i18n";

const Properties = (field, t = i18n.t) => {

  return {
    properties: [
      {
        id: "configPropertiesSection",
        name: t("catalogEditor.fieldProperties.configProperties.section"),
        type: "group"
      },
      /* тут написать конфиги для кнопки */
      // {
      //   id: "config.multiselect",
      //   name: t(trsPrefix + "multiselect.label"),
      //   type: "checkboxes",
      //   readOnly: false,
      //   config: {
      //     items: [
      //       {
      //         id: "true",
      //         name: t(trsPrefix + "multiselect.value")
      //       }
      //     ]
      //   }
      // },
    ],

    getValues: (propery, value) => {
      return { [propery]: value };
    }
  };
};

export default Properties;