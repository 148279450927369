// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".viewSelector__viewsListContainer___H89t5 {\n  width: 200px;\n  margin-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/App/AppBody/Section/SectionBody/Catalog/View/ViewHeader/ViewSelector/viewSelector.less"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,kBAAA;AADF","sourcesContent":["@import (reference) \"../../../../../../../../../styles/helpers/index.less\";\n\n.viewsListContainer {\n  width: @INPUT_WIDTH_MIDDLE;\n  margin-right: @PADDING_BASE; // for compensation negative margin by tabsMenu\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewsListContainer": "viewSelector__viewsListContainer___H89t5"
};
export default ___CSS_LOADER_EXPORT___;
