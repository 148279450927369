import Immutable from "immutable";
import _ from "lodash";
import { privilegePriorities } from "../configs/privilegeCodes";

function queryToObject(query) {
  let object;

  if (query.sectionId) {
    object = {
      sectionId: query.sectionId
    };
  } else if (query.viewId) {
    object = {
      viewId: query.viewId
    };
  } else if (query.recordId) {
    object = {
      recordId: query.recordId,
      catalogId: query.catalogId
    };
  } else if (query.catalogId) {
    object = {
      catalogId: query.catalogId
    };
  }

  return object;
}

export default {
  updateRightObject(object, loadingComplete, rules = null) {
    let rightsCollection = this.getIn(["rights"]);
    let rightsObjectIndex = rightsCollection.findIndex(ro => {
      let roObject = ro.getIn(["object"]);
      roObject = roObject && roObject.toJS ? roObject.toJS() : roObject;
      return _.isEqual(roObject, object);
    });

    // sort rules
    if (rules) {
      rules = _.sortBy(rules, r => {
        const privilegeIndex = privilegePriorities.indexOf(r.privilegeCode);

        // allUsers, roles, users
        let typeOfSubject = 0;
        if (r.rightSubject.userAttr == "id") {
          typeOfSubject = 0;
        } else if (r.rightSubject.userAttr == "allUsers") {
          typeOfSubject = 1;
        } else {
          typeOfSubject = 1 / (parseFloat(r.rightSubject.userAttr) || 1);
        }
        return -1 * (privilegeIndex + typeOfSubject);
      });
    }

    if (rightsObjectIndex > -1) {
      this.setIn(
        ["rights", rightsObjectIndex, "loadingComplete"],
        loadingComplete
      );

      if (rules) {
        this.setIn(
          ["rights", rightsObjectIndex, "rules"],
          Immutable.fromJS(rules)
        );
      }
    } else {
      rightsCollection = rightsCollection.push(
        Immutable.fromJS({
          object,
          rules,
          loadingComplete
        })
      );
      this.setIn(["rights"], rightsCollection);
    }
  },

  getRights(params, query) {
    let object = queryToObject(query);

    this.updateRightObject(object, false);

    this.changed();
  },

  getRightsCompleted(rights, params, data, query) {
    // if from server returned empty list
    if (!rights.length) {
      let object = queryToObject(query);

      // if request contains object filter, then in object no rules
      // else no objects with rules
      if (_.keys(object).length) {
        rights = [
          {
            object,
            rules: []
          }
        ];
      } else {
        this.setSize(["rights"], 0);
        return;
      }
    }
    rights.forEach(rightObj => {
      this.updateRightObject(rightObj.object, true, rightObj.rules || []);
    });

    this.changed();
  },

  createRightCompleted(result, params, data) {
    let rightObj = Immutable.fromJS(data).toJS();
    this.updateRightObject(rightObj.object, true, rightObj.rules || []);
  }
};
