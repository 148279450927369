import _ from "lodash";
import React, { useState, useEffect, useRef, Fragment } from "react";
import { Input, Skeleton } from "antd";
import { useTranslation } from 'react-i18next';

import apiActions from "../../../actions/apiActions";
import Sessions from "./Sessions";

import styles from "./companies.less";

const Companies = () => {
  const { t } = useTranslation();
  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState(sessions);
  const [searchCompanyText, setSearchCompanyText] = useState("");
  const companySearchRef = useRef(null);
  const [loading, setLoading] = useState(false)

  const onCompanySearchChange = e => {
    setSearchCompanyText(e.target.value);
  };

  useEffect(() => {
    companySearchRef.current.focus();
    setLoading(true)
    apiActions.getSessions().then(response => {
      setSessions(response.body.sessions);
      setLoading(false)
      // If not sessions exits, redirect to login page
      if (_.isEmpty(response.body.sessions)) {
        window.location.href = "/#/auth/login"
      }
    });
  }, []);

  useEffect(
    () => {
      const _filtered = sessions.filter(s => {
        return (
          s.company.title
            .toLowerCase()
            .includes(searchCompanyText.toLowerCase()) ||
          s.company.domain
            .toLowerCase()
            .includes(searchCompanyText.toLowerCase()) ||
          s.account.email
            .toLowerCase()
            .includes(searchCompanyText.toLowerCase())
        );
      });
      searchCompanyText
        ? setFilteredSessions(_filtered)
        : setFilteredSessions(sessions);
    },
    [sessions, searchCompanyText]
  );

  return (
    <div className={styles.sessionWrapper}>
      <div className={styles.sessionBasicContainer}>
        <Skeleton active loading={loading} >
          <Fragment>
            <Input
              ref={companySearchRef}
              placeholder={t("menuPopover.searchCompany")}
              className={styles.searchInput}
              value={searchCompanyText}
              onChange={onCompanySearchChange}
            />
            <Sessions sessions={filteredSessions} />
          </Fragment>
        </Skeleton>
      </div>
    </div>
  );
};

export default Companies;
