import React from "react";
import PropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";
import editorActions from "../../../actions/editorActions";
import { Form, Input } from "antd";
import { withTranslation } from "react-i18next";

import styles from "./fields.less";

class AddressField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.field.getIn(["config", "token"])
    };
  }

  onChange = e => {
    var newVal = e.target.value || "";
    this.setState({
      token: newVal
    });
    editorActions.changeFieldConfig(
      this.props.sectionId,
      this.props.fieldIndex,
      {
        token: newVal
      }
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      token: nextProps.field.getIn(["config", "token"])
    });
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Input
          type="text"
          disabled={this.props.disabled}
          value={this.state.token}
          onChange={this.onChange}
          placeholder={t("fieldTypes.address.token.placeholder")}
        />
        <span className={styles.subText}>
          {t("fieldTypes.address.token.hint")}
        </span>
      </div>
    );
  }
}

AddressField.propTypes = {
  field: PropTypes.object.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  sectionId: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default withTranslation()(AddressField);
