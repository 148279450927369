import React from 'react';

import UserAvatar from '.';
import { connect } from '../../../../StateProvider';
import calculateRemainsLicense from '../../../../../utils/calculateRemainsLicense';
import calculateRemainsRecords from '../../../../../utils/calculateRemainsRecords';

const UserAvatarContainer = (props) => {
    const recordsLimit = props.licenseInfo.get("records");
    const licenseDate = props.licenseInfo.get("expires")

    const recordsCount = props.companyInfo.get("recordsCount");

    const remainsRecords = calculateRemainsRecords(recordsLimit, recordsCount);
    const remainsLicense = calculateRemainsLicense(licenseDate);
    
    const shortName = (name) => {
        name = name.split(" ");
        name = name.map(i => i.substr(0, 1));
        name = name.join("");
        name = name.substr(0, 2);
        return name;
    };

    const { user } = props;

    const userName = user && user.get("title");
    const styleUserMenuBadge = {
        boxShadow: "none",
        background: "rgb(255 0 0 / 71%)",
        width: "10px",
        height: "10px",
        top: "2px",
        right: "5px"
    };

    if (!userName) {
        return null;
    };

    const userShortName = shortName(userName);
    return <UserAvatar
        userShortName={userShortName}
        styleUserMenuBadge={styleUserMenuBadge}
        userName={userName}
        recordsEnds={remainsRecords.redTitlePercentage}
        licenseEnds={remainsLicense.redTitleDate}
    />
};

export default connect(
    UserAvatarContainer,
    {
        user: ["user"],
        companyInfo: ["company"],
        licenseInfo: ["license"]
    }
);