
import debug from "debug";
import _ from "lodash";
import Immutable from "immutable";

const log = debug("CRM:scriptsMixin");

export default {
  setSandboxValue(fieldId, value) {
    let values = this.getIn( ["scripts", "sandbox", "values"] );
    values = values || Immutable.Map();
    values = values.set(fieldId, Immutable.fromJS(value) );

    this.setIn( ["scripts", "sandbox", "values"], values );
    this.changed();
  },

  storeSandboxData(param, value) {
    this.setIn( ["scripts", "sandbox", param], Immutable.fromJS(value) );
    this.changed();
  },
  clearSandbox() {
    this.setIn( ["scripts", "sandbox"], Immutable.Map() );
    this.changed();
  },
};
