import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Button } from "antd";
import { ShrinkOutlined, ArrowsAltOutlined } from "@ant-design/icons";

import { connect } from "../../components/StateProvider";
import userSettingsActions from "../../actions/userSettingsActions";
import i18n from "../../configs/i18n";
import dndContext from "../../services/dndContext";
import dndTargets from "../../configs/dndTargets";
import FieldDropArea from "./FieldDropArea";
import FieldWrapper from "./FieldWrapper";
import SectionWrapper from "./SectionWrapper";
import FIELD_TYPES from "../../configs/fieldTypes";

import styles from "./catalogEditor.less";

const Fields = {
  [FIELD_TYPES.GROUP]: require("./fields/SectionField").default,
  [FIELD_TYPES.TEXT]: require("./fields/TextField").default,
  [FIELD_TYPES.CONTACT]: require("./fields/ContactField").default,
  [FIELD_TYPES.ADDRESS]: require("./fields/AddressField").default,
  [FIELD_TYPES.NUMBER]: require("./fields/NumberField").default,
  [FIELD_TYPES.DATE]: require("./fields/DateField").default,
  [FIELD_TYPES.DROPDOWN]: require("./fields/DropdownField").default,
  [FIELD_TYPES.SWITCH]: require("./fields/SwitchField").default,
  [FIELD_TYPES.BUTTON]: require("./fields/ButtonField").default,
  [FIELD_TYPES.CHECKBOXES]: require("./fields/CheckboxesField").default,
  [FIELD_TYPES.RADIOBUTTON]: require("./fields/RadiobuttonField").default,
  [FIELD_TYPES.PROGRESS]: require("./fields/ProgressField").default,
  [FIELD_TYPES.STARS]: require("./fields/StarsField").default,
  [FIELD_TYPES.USER]: require("./fields/UserField").default,
  [FIELD_TYPES.OBJECT]: require("./fields/ObjectField").default,
  [FIELD_TYPES.FILE]: require("./fields/FileField").default
};

// если секция есть в массиве свернутых секций, то сворачиваем ее и все ее филды
function collapsedFieldsBySections(allFields, collapsedSections) {
  const result = [];
  
  if (!allFields || !collapsedSections) {
    return result;
  }
  
  let sectionCollapsed = false;
  allFields.forEach((field, index) => {
    const fieldType = field.get("type");
    const fieldUuid = field.get("uuid");
    const isSection = fieldType === FIELD_TYPES.GROUP;

    // проверяем секцию в массиве свернутых секций
    if (isSection) {
      sectionCollapsed = collapsedSections.includes(fieldUuid);
    }

    // как только наткнулись на секцию и обработали ее, следом за ней необходимо раскрыть ее филды
    if (sectionCollapsed) {
      const collapsedField = field.set("collapsed", true);
      result.push(collapsedField);
    } else {
      result.push(field);
    }
  });

  return result;
}

class FieldListEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // используем uuid, тк id появляется только после сохранения каталога (прилетают с сервера)
      uuidsCollapsedSections: [],
      isCompact: true
    };
  }

  componentDidMount() {
    this.setCompact();
  }

  componentDidUpdate(prevProps) {
    const editingCatalog = this.props.catalog;
    let fields = editingCatalog.get("fields").toArray();

    const prevEditingCatalog = prevProps.catalog;
    let prevFields = prevEditingCatalog.get("fields").toArray();

    if (fields.length > prevFields.length) {
      // раскрываем секцию, если она свернута и в нее добавили новый филд
      this.expandSectionAfterAddingField(fields, prevFields);
    }

    if (this.props.compact !== prevProps.compact) { 
      // из за юзерсеттингов во время componentDidMount "compact" может прийти нормально, 
      // а может и не прийти и происходит это в рандомный момент
      this.setCompact();
    }
  }


  expandSectionAfterAddingField = (fields, prevFields) => { 
    const { uuidsCollapsedSections } = this.state;

    // находим добавленный филд 
    const addedField = _.difference(fields, prevFields)[0];

    if (addedField) {
      // находим его индекс в массиве филдов
      const indexAdded = fields.findIndex(f => f.get("uuid") == addedField.get("uuid"));

      // идем в обратном порядке вверх по массиву, пока не встретим секцию, в которой этот филд находится
      // начинаем иттерацию с индекса добавленного филда
      for (let i = indexAdded; (i < fields.length && i >= 0); i--) { 
        const field = fields[i];

        if (field.get("type") === FIELD_TYPES.GROUP) { 

          // убираем секцию из массива скрытых секций
          this.setState({
            uuidsCollapsedSections: uuidsCollapsedSections.filter(u => u != field.get("uuid"))
          });
          break;
        }
      }
    }
  } 

  setCompact() {
    let { compact } = this.props;

    if (compact !== undefined) {
      this.setState({ isCompact: compact });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // compare prev and next props,
    // find new field from list. (index)
    let diffField = _.difference(
      nextProps.catalog
        .get("fields")
        .toJS()
        .map(f => f.uuid),
      this.props.catalog
        .get("fields")
        .toJS()
        .map(f => f.uuid)
    )[0];
    if (diffField) {
      this.setState({
        diffFieldUuid: diffField
      });
    }
  }

  resetDiffAfterFocusInputNameInWrapper = () => {
    this.setState({ diffFieldUuid: undefined });
  };

  onCompactFields = () => {
    const { catalog } = this.props;
    const catalogId = catalog.get("id");

    const key = ["catalogs", catalogId, "catalogEditor", "compactFields"];
    const value = this.state.isCompact;

    this.setState({ isCompact: !value });
    userSettingsActions.setKey(key, !value); 
  }

  onCollapseSection = (section) => { 
    const { uuidsCollapsedSections } = this.state;
    const uuid = section.get("uuid");
    
    let newCollapsedSections = [];
    if (uuidsCollapsedSections.indexOf(uuid) === -1) {
      newCollapsedSections = [...uuidsCollapsedSections, uuid];
    } else {
      newCollapsedSections = uuidsCollapsedSections.filter(u => u != uuid);
    }

    this.setState({
      uuidsCollapsedSections: newCollapsedSections
    });
  }

  onSelectField = (fieldIndex) => {
    this.props.onSelectField(fieldIndex);
  }


  render() {
    const editingCatalog = this.props.catalog;
    let fields = editingCatalog.get("fields").toArray();
    let originalFields = this.props.catalog.get("originalFields");
    let fieldsLen = fields.length;
    let fieldComponents = [];
    let disableDropAreasAroundId =
      this.props.dropType === dndTargets.FIELD &&
      this.props.dropInfo.get("fieldId");

    const isCompact = this.state.isCompact; 
    const uuidsCollapsedSections = this.state.uuidsCollapsedSections;

    fields = collapsedFieldsBySections(fields, uuidsCollapsedSections);

    const allSections = fields.filter(f => f.get("type") === FIELD_TYPES.GROUP);
    const lastSection = allSections.length && allSections[allSections.length - 1];
    const isCollapsedLastSection = lastSection && lastSection.get("collapsed");

    fields.forEach((field, i) => {
      let fieldId = field.get("id");
      let fieldType = field.get("type");
      let fieldUuid = field.get("uuid");
      let originalField = fieldId
        ? originalFields.find(f => f.get("id") === fieldId)
        : null;
      let isFieldCollapsed = field.get("collapsed");

      const isSection = fieldType === FIELD_TYPES.GROUP; 
      const isSelectedField = this.props.selectedFieldIndex === i;
      const isSectionCollapsed = uuidsCollapsedSections.includes(fieldUuid);

      let FieldComponent = Fields[fieldType];
      // Disable drop areas around current dragging field
      let disableDropArea =
        disableDropAreasAroundId &&
        (field.get("id") === disableDropAreasAroundId ||
          (i < fieldsLen - 1 &&
            fields[i + 1].get("id") === disableDropAreasAroundId));

      if (i === 0) {
        fieldComponents.push(
          <FieldDropArea
            key={"drop"}
            disabled={disableDropArea}
            prevFieldIndex={-1}
            sectionId={this.props.sectionId}
          />
        );
      }

      const modeField = isCompact 
      ? isSelectedField 
        ? "edit"
        : "view"
      : "edit";

      const hideCross = (i === 0 && isSection) || (isSection && isSectionCollapsed);

      if (isSection) {
        fieldComponents.push(
          <SectionWrapper
            key={field.get("uuid")}
            hideCross={hideCross}
            selected={isSelectedField}
            sectionIndex={i}
            section={field}
            fields={fields}
            sectionId={this.props.sectionId}
            catalogId={this.props.catalog.get("id")}
            needFocusOnInputNameCallback={
              this.state.diffFieldUuid === field.get("uuid")
                ? this.resetDiffAfterFocusInputNameInWrapper
                : null
            }
            onSelect={this.onSelectField}
            onCollapse={this.onCollapseSection}
          >
            <FieldComponent/>
          </SectionWrapper>
        );
      } else {
        fieldComponents.push(
          <FieldWrapper
            key={field.get("uuid")}
            disabled={this.props.disabled}
            hideCross={hideCross}
            selected={isSelectedField}
            compact={isCompact}
            fieldIndex={i}
            field={field}
            sectionId={this.props.sectionId}
            catalogId={this.props.catalog.get("id")}
            needFocusOnInputNameCallback={
              this.state.diffFieldUuid === field.get("uuid")
                ? this.resetDiffAfterFocusInputNameInWrapper
                : null
            }
            onSelectField={this.onSelectField}
          >
            <FieldComponent
              mode={modeField}
              disabled={this.props.disabled}
              sectionId={this.props.sectionId}
              fieldIndex={i}
              field={field}
              originalField={originalField}
              fields={fields}
              catalogs={this.props.catalogs}
              catalogId={this.props.catalog.get("id")}
              editingCatalog={editingCatalog}
            />
          </FieldWrapper>
        );
      }

      // after collapsed section need to add a drag area with prevIndex === last field the section
      const nextField = (fields.length >= i + 1) ? fields[i + 1] : null;
      const nextFieldIsSection = nextField && nextField.get("type") === FIELD_TYPES.GROUP;
      if (isFieldCollapsed && nextFieldIsSection) {
        const lastItem = i === (fields.length - 1) ? "Last": "";
        fieldComponents.push(
          <FieldDropArea
            key={i + "drop" + lastItem}
            disabled={disableDropArea}
            prevFieldIndex={i}
            sectionId={this.props.sectionId}
            onSelectField={this.props.onSelectField}
          />
        );
      }

      if (isFieldCollapsed) return;

      const lastItem = i === (fields.length - 1) ? "Last": "";
      fieldComponents.push(
        <FieldDropArea
          key={i + "drop" + lastItem}
          disabled={disableDropArea}
          prevFieldIndex={i}
          sectionId={this.props.sectionId}
          onSelectField={this.props.onSelectField}
        />
      );
    });

    // if last section collapsed
    if (isCollapsedLastSection) {
      fieldComponents.push(
        <FieldDropArea
          key={(fields.length - 1) + "drop" + "Last"}
          disabled={false}
          prevFieldIndex={(fields.length - 1)}
          sectionId={this.props.sectionId}
        />
      );
    }

    // if deleted all fields
    if (!fields.length) {
      fieldComponents.push(
        <FieldDropArea
          key={"drop"}
          disabled={false}
          prevFieldIndex={-1}
          sectionId={this.props.sectionId}
        />
      );
    }

    return (
      <div id="fieldListEditor" className={styles.fieldListEditor}>
        <div className={styles.containerBtnCollapsingFields}>
          <Button
            className={styles.btnCollapsingFields} 
            title={
              isCompact 
              ? i18n.t("catalogEditor.fields.expand") 
              : i18n.t("catalogEditor.fields.collapse")}
            icon={
              isCompact 
              ? <ArrowsAltOutlined/> 
              : <ShrinkOutlined/>
            }
            type="link"
            onClick={this.onCompactFields}
          >
          </Button> 
        </div>
        {fieldComponents}
      </div>
    );
  }
}

FieldListEditor.propTypes = {
  dropType: PropTypes.string,
  dropInfo: PropTypes.object,
  catalog: PropTypes.object.isRequired,
  catalogs: PropTypes.object.isRequired,
  sectionId: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default connect(
  dndContext(FieldListEditor),
  { userSettings: ["userSettings", "catalogs"] },
  (props, { userSettings }) => { 
    const catalogId = props.catalog.get("id");

    return {
      compact: userSettings.getIn([
        catalogId, 
        "catalogEditor",  
        "compactFields",
      ]),
      ...props,
    };
  }
);
