import SCENE_CONTAINER from "../../../configs/sceneContainer";
import SCENE_TYPE from "../../../configs/sceneTypes";

export default {
  clearRecordStore(catalogId, recordId) {
    let childs = this.collectAllRecordChilds(catalogId, recordId);
    const recordsToDelete = Object.values(childs).filter(child => {
      let allowDelete = true;

      child.parents &&
        child.parents.forEach(parent => {
          const parentCatalogId = parent.get("catalogId");
          const parentRecordId = parent.get("recordId");

          if (!this.getElement(childs, parentCatalogId, parentRecordId)) {
            allowDelete = false;
          }
        });

      if (allowDelete) {
        child.linkedRecords.forEach(linkedRecord => {
          const params = {
            catalogId: linkedRecord.get("catalogId"),
            recordId: linkedRecord.get("recordId")
          };
          this.removeParentFromRecord(params, child.catalogId, child.recordId);
        });
      }

      return allowDelete;
    });

    recordsToDelete.forEach(({ catalogId, recordId }) => {
      this.deleteIn(["records", catalogId, recordId]);
    });

    this.changed();
  },

  /*
    catalogId, recordId, - params of record that we want to delete,
    childs - records that we'll be able to delete
    passedRecords - records that this function passed
  */

  collectAllRecordChilds(catalogId, recordId, childs = {}, passedRecords = {}) {
    const recordKey = ["records", catalogId, recordId];
    const recordValues = this.getIn([...recordKey, "values"]);

    if (this.getElement(passedRecords, catalogId, recordId)) {
      return childs;
    }

    const record = this.getIn(recordKey);

    if (!record) {
      return childs;
    }

    const recordInRigthPanel = this.get("scenes").some(
      scene =>
        scene &&
        (scene.getIn(["params", "catalogId"]) === catalogId &&
          scene.getIn(["params", "recordId"]) === recordId &&
          scene.get("type") === SCENE_TYPE.RECORD &&
          scene.get("container") === SCENE_CONTAINER.WINDOW)
    );

    const recordInModal = this.get("scenes").some(
      scene =>
        scene &&
        (scene.getIn(["params", "catalogId"]) === catalogId &&
          scene.getIn(["params", "recordId"]) === recordId &&
          scene.get("type") === SCENE_TYPE.RECORD &&
          scene.get("container") === SCENE_CONTAINER.POPUP)
    );

    const recordIsOpen = recordInRigthPanel || recordInModal;
    const linkedRecords = this.getLinkedRecords(catalogId, recordValues);
    const parents = record.get("parents");
    // we'll need linkedRecords to delete their parents when we'll delete this record
    !recordIsOpen &&
      this.setElement(childs, { catalogId, recordId, linkedRecords, parents });
    this.setElement(passedRecords, { catalogId, recordId });

    linkedRecords.forEach(linkedRecord => {
      const linkedCatalogId = linkedRecord.get("catalogId");
      const linkedRecordId = linkedRecord.get("recordId");

      Object.values(
        this.collectAllRecordChilds(
          linkedCatalogId,
          linkedRecordId,
          childs,
          passedRecords
        )
      ).forEach(data => this.setElement(childs, data));
    });

    return childs;
  },

  getElement(collection, catalogId, recordId) {
    const key = `${catalogId}:${recordId}`;
    return collection[key];
  },

  setElement(collection, element) {
    if (!this.getElement(collection, element.catalogId, element.recordId)) {
      const key = `${element.catalogId}:${element.recordId}`;
      collection[key] = element;
    }
  }
};
