// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reports__container___67-t6 {\n  width: 100%;\n  height: 100%;\n  padding-left: 5px;\n  padding-right: 5px;\n  background-color: #f7f8fa;\n}\n.reports__header___ZyKs- {\n  min-height: 35px;\n  padding-left: 15px;\n  padding-right: 15px;\n  padding-top: 5px;\n  padding-bottom: 5px;\n  background-color: #f7f8fa;\n}\n.reports__body___DBXon {\n  position: relative;\n  height: calc(100% - 35px);\n  overflow-y: auto;\n  overflow-x: hidden;\n  padding-bottom: 200px;\n  background-color: #f7f8fa;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Reports/reports.less"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;AAAF;AAGA;EACE,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;AADF;AAIA;EACE,kBAAA;EACA,yBAAA;EACA,gBAAA;EACA,kBAAA;EACA,qBAAA;EACA,yBAAA;AAFF","sourcesContent":["@import (reference) \"../../styles/helpers/index.less\";\n.container {\n  width: 100%;\n  height: 100%;\n  padding-left: @PADDING_BASE / 2;\n  padding-right: @PADDING_BASE / 2;\n  background-color: @BG_WORKPLACE;\n}\n\n.header {\n  min-height: @RECORD_DATA_HEADER;\n  padding-left: 3 * @PADDING_BASE / 2;\n  padding-right: 3 * @PADDING_BASE / 2;\n  padding-top: @PADDING_BASE / 2;\n  padding-bottom: @PADDING_BASE / 2;\n  background-color: @BG_WORKPLACE;\n}\n\n.body {\n  position: relative;\n  height: calc(~\"100%\" - @RECORD_DATA_HEADER);\n  overflow-y: auto;\n  overflow-x: hidden;\n  padding-bottom: 200px;\n  background-color: @BG_WORKPLACE;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "reports__container___67-t6",
	"header": "reports__header___ZyKs-",
	"body": "reports__body___DBXon"
};
export default ___CSS_LOADER_EXPORT___;
