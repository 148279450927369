import _ from "lodash";
import React, { Fragment } from "react";
import { Form, Input, Button, message } from "antd";
import cn from "classnames";
import queryString from "query-string";
import { withTranslation } from "react-i18next";

import { REGISTER_FIELDS } from "./helpers/const";
import LoadingSpin from "../common/LoadingSpin";
import apiActions from "../../actions/apiActions";

import styles from "./styles.less";

class RegisterForm extends React.Component {
  constructor() {
    super();

    this.state = {
      formLoaded: false,
      withAccount: false,
      loading: false,
      domain: window.location.hostname
          .split(".")
          .slice(1)
          .join(".")
    };
  }

  componentDidMount() {
    /**
     * Check if account already exists
     * using route whick make decision by token
     */
    const parsed = queryString.parse(this.props.location.search);
    apiActions.getAccountByInvite(parsed.token).then(res => {
      const accountExist = res.body.accountExist;
      if (accountExist) {
        this.setState({ withAccount: true });
      } else {
        this.setState({ withAccount: false });
      }
      this.setState({ formLoaded: true });
    });
  }

  handleSubmit = values => {
    if (!this.validatePassword(values.password, values.passwordRepeat)){
      return;
    }

    const parsed = queryString.parse(this.props.location.search);

    const { withAccount } = this.state;
    if (withAccount) {
      this.registerCompanyWithAccount(parsed.token, {
        companyName: values.companyName,
        domain: values.address,
        phone: values.phone
      });
    } else {
      this.registerCompany(values, parsed.token);
    }
  };

  validatePassword(password, password2) {
    const { t } = this.props;
    if (this.state.withAccount) {
      return true;
    }

    if (!(password === password2)) {
      message.error(t("auth.errors.notEqual"));
      return false;
    }
    if (!(password.length > 5)) {
      message.error(t("auth.errors.minLength"));
      return false;
    }
    return true;
  }

  registerCompanyWithAccount = (token, { companyName, domain, phone }) => {
    const { t } = this.props;
    const body = {
      token,
      companyName,
      domain,
      phone
    };

    this.setState({ loading: true });
    apiActions
      .createCompanyWithAccount(body)
      .then(response => {
        this.setState({ loading: false });
        if (response.status == 200) {
          window.location.href = "//" + response.body.hostname;
        } else {
          message.error(t("auth.errors.somethinWrong"));
        }
      })
      ["catch"](err => {
        this.setState({ loading: false });
        message.error(err.response.text);
      });
  };

  registerCompany = (data, token) => {
    const { t } = this.props;

    const body = {
      token,
      password: data.password,
      userName: data.name,
      companyName: data.companyName,
      domain: data.address,
      newCompany: true,
      phone: data.phone
    };

    this.setState({ loading: true });
    apiActions
      .registerCompany(body)
      .then(response => {
        this.setState({ loading: false });
        if (response.status == 200) {
          window.location.href = "//" + response.body.hostname;
        } else {
          message.error(t("auth.errors.somethinWrong"));
        }
      })
      ["catch"](err => {
        this.setState({ loading: false });
        if (err.response && err.response.text === "Hostname is used") {
          message.error(t("auth.errors.domainInUse"));
        } else if (err.response && err.response.text === "'token' incorrect") {
          message.error(t("auth.errors.invalidToken"));
        } else {
          message.error(err.message);
        }
      });
  };

  render() {
    const { t } = this.props;
    const { loading, withAccount } = this.state;

    if (!this.state.formLoaded) {
      return null;
    }

    return (
      <div className={styles.formContainer}>
        <div className={styles.formInner}>
          <Form 
            name="register"
            className={styles.form} 
            onFinish={this.handleSubmit}
          >
            <h1>{t("auth.company.companyCreate")}</h1>
            <Form.Item
              name={REGISTER_FIELDS.COMPANY_NAME}
              className={cn(styles.formItem)}
              rules={[{ required: true, message: t("auth.fieldHintMessage") }]}
            >
              <div>
                <div className={styles.formLabel}>
                  {t("auth.company.companyName")}
                </div>
                <Input
                  disabled={loading}
                  className={styles.formField}
                  placeholder=""
                />
              </div>
            </Form.Item>

            <Form.Item
              name={REGISTER_FIELDS.ADDRESS}
              className={cn(styles.formItem)}
              help={t("auth.company.adressHint")}
              validateTrigger="onBlur"
              rules={[
                { required: true, message: t("auth.fieldHintMessage") },
                {
                  pattern: new RegExp(
                    /^[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?$/
                  ),
                }
              ]}
            >
              <div>
                <div className={styles.formLabel}>
                  {t("auth.company.address")}
                </div>
                <Input
                  disabled={loading}
                  className={styles.formField}
                  placeholder=""
                  addonAfter={this.state.domain && `.${this.state.domain}`}
                  />  
              </div>
            </Form.Item>

            <Form.Item
              name={REGISTER_FIELDS.PHONE}
              className={cn(styles.formItem)}
              validateTrigger="onBlur"
              rules={[
                { required: true, message: t("auth.fieldHintMessage") }
              ]}
            >
              <div>
              <div className={styles.formLabel}>{t("auth.company.phone")}</div>
                <Input
                  disabled={loading}
                  className={styles.formField}
                  placeholder=""
                />
              </div>
            </Form.Item>

            {!withAccount && (
              <Fragment>
                <Form.Item
                  name={REGISTER_FIELDS.NAME}
                  className={cn(styles.formItem)}
                  rules={[
                    {
                      required: !withAccount,
                      message: t("auth.fieldHintMessage")
                    }
                  ]}
                >
                  <div>
                    <div className={styles.formLabel}>
                      {t("auth.company.name")}
                    </div>
                      <Input
                        disabled={loading}
                        className={styles.formField}
                        placeholder=""
                      />
                    </div>
                </Form.Item>

                <div className={styles.itemGroup}>
                  <Form.Item
                    name={REGISTER_FIELDS.PASSWORD}
                    className={cn(styles.formItem)}
                    rules={[
                      {
                        required: !withAccount,
                        message: t("auth.fieldHintMessage")
                      }
                    ]}
                  >
                    <div>
                      <div className={styles.formLabel}>{t("auth.password")}</div>
                      <Input.Password
                          type="password"
                          disabled={loading}
                          className={styles.formField}
                          placeholder=""
                          />
                    </div>
                  </Form.Item>
                  <Form.Item
                    name={REGISTER_FIELDS.PASSWORD_REPEAT}
                    className={cn(styles.formItem)}
                    dependencies={[REGISTER_FIELDS.PASSWORD]}
                    rules={[
                      {
                        required: !withAccount,
                        message: t("auth.fieldHintMessage")
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue(REGISTER_FIELDS.PASSWORD) === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(t("auth.errors.notEqual")));
                        },
                      }),
                    ]}
                  >
                    <div>
                      <div className={styles.formLabel}>
                        {t("auth.repeatPassword")}
                      </div>
                      <Input.Password
                        disabled={loading}
                        type="password"
                        className={styles.formField}
                        placeholder=""
                      />
                    </div>
                  </Form.Item>
                </div>
              </Fragment>
            )}
            <br />
            <Form.Item className={styles.createCompanyButton}>
              <Button
                tabIndex={0}
                disabled={loading}
                key="submit"
                type="primary"
                className={styles.loginButton}
                htmlType="submit"
              >
                {t("auth.create")}
              </Button>
              {loading && (
                <div>
                  <LoadingSpin spin={true} />{" "}
                  {t("auth.messages.companyCreating")}
                </div>
              )}
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(RegisterForm);
