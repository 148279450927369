import React, { Component } from "react";
import { withRouter } from "react-router";
import { Menu as AntMenu, Row, Dropdown } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import cn from "classnames";
import { withTranslation } from "react-i18next";

import Icon from '../../../../../../common/UI/Icon';
import ButtonTransparent from "../../../../../../common/UI/ButtonTransparent";
import { alert } from "../../../../../../common/Modal";
import { checkAccessOnObject } from "../../../../../../../utils/rights";
import PRIVILEGE_CODES from "../../../../../../../configs/privilegeCodes";
import RESOURCE_TYPES from "../../../../../../../configs/resourceTypes";
import { promptConfirm } from "../../../../../../common/Modal";
import apiActions from "../../../../../../../actions/apiActions";
import modalsActions from "../../../../../../../actions/modalsActions";
import sceneActions from "../../../../../../../actions/sceneActions";
import { connect } from "../../../../../../StateProvider";

import styles from "./catalogHeader.less";

class CatalogHeader extends Component {
  static propTypes = {
    catalogId: PropTypes.string,
    history: PropTypes.object
  };

  isSchemaLocked = () => {
    const { t } = this.props;
    const isSchemaLocked =
      this.props.license && this.props.license.getIn(["modules", "schemalock"]);
    if (isSchemaLocked) {
      alert({
        headerText: t("modals.schemaLock.headerText"),
        text: t("modals.schemaLock.text"),
        okText: t("modals.schemaLock.okText")
      });
    }
    return isSchemaLocked;
  };

  onClickAccess = e => {
    let { catalog, sectionId, catalogId } = this.props;
    if (catalogId) {
      let isAdmin = checkAccessOnObject(
        RESOURCE_TYPES.CATALOG,
        catalog,
        PRIVILEGE_CODES.ADMIN
      );
      let readOnly = !checkAccessOnObject(
        RESOURCE_TYPES.CATALOG,
        catalog,
        PRIVILEGE_CODES.ACCESS
      );
      let object = { catalogId };
      let parents = [{ sectionId }];
      modalsActions.openAccessModal(
        { object, parents },
        RESOURCE_TYPES.CATALOG,
        { readOnly, isAdmin }
      );
    }
  };

  /* Удаление всех записей */
  truncate = () => {
    const { catalog, catalogId, sceneId, match, t } = this.props;
    const viewId = match && match.params.viewId;

    promptConfirm({
      headerText: t("modals.truncateConfirm.headerText"),
      text: (
        <span>
          {t("modals.truncateConfirm.firstText")}
          <br />
          {t("modals.truncateConfirm.secondText")}
        </span>
      ),
      okText: t("modals.truncateConfirm.okText"),
      cancelText: t("modals.truncateConfirm.cancelText"),
      value: catalog.get("name"),
      placeHolder: catalog.get("name"),
      onOk: () => {
        apiActions
          .deleteRecord(
            {
              catalogId,
              recordId: "$all"
            },
            { sceneId, viewId }
          )
          .then(() => {
            /* в случае удачного удаления, происходит перезапись текущих записей новыми (т.е. пустым объектом) можно просто удалить все записи из сцены */
            /*
            recordActions.requestForRecords(catalogId, sceneId, {
              viewId: this.props.match && this.props.match.params.viewId
            });
          */
            sceneActions.deleteRecordsFromScene(sceneId);
          });
      }
    });
  };

  editCatalog = () => {
    if (this.isSchemaLocked()) {
      return;
    }

    const catalog = this.props.catalog;
    this.props.history.push({
      pathname: `/section/${catalog.get("sectionId")}/catalog/${catalog.get(
        "id"
      )}/edit`,
      search: this.props.location.search
    });
  };

  remove = () => {
    if (this.isSchemaLocked()) {
      return;
    }

    const { catalog, history, location, sceneId, t } = this.props;
    const sectionId = catalog.get("sectionId");
    const catalogId = catalog.get("id");
    promptConfirm({
      headerText: t("modals.removeConfirm.headerText"),
      text: (
        <span>
          {t("modals.removeConfirm.firstText")}
          <br />
          {t("modals.removeConfirm.secondText")}
        </span>
      ),
      okText: t("modals.removeConfirm.okText"),
      cancelText: t("modals.removeConfirm.cancelText"),
      value: catalog.get("name"),
      placeHolder: catalog.get("name"),
      onOk: () => {
        apiActions
          .deleteCatalog(
            {
              catalogId
            },
            { sceneId }
          )
          .then(() => {
            history.push({
              pathname: `/section/${sectionId}`,
              search: location.search
            });
          });
        /* Удаление каталога вызывает удаление сцены*/
        sceneActions.deleteScene(sceneId);
      }
    });
  };

  toggleLeftPanel = () => {
    this.props.changeViewMenuVisibility &&
      this.props.changeViewMenuVisibility();
  };

  render() {
    const { catalog, leftPanel, t } = this.props;

    const isAccessAdmin = checkAccessOnObject(
      RESOURCE_TYPES.CATALOG,
      catalog,
      PRIVILEGE_CODES.ADMIN
    );

    let menu = [
      {
        key: 1,
        label: (
          <a onClick={this.onClickAccess}>
            <Icon type={"icon edition-55"} className={styles.settingIcon} />
            {t("buttons.accessCatalog")}
          </a>
        )
      }
    ];

    if (isAccessAdmin) {
      const adminItemsMenu = [
        {
          key: 2,
          label: (
            <a onClick={this.editCatalog}>
              <Icon
                type={"icon edition-33"}
                className={styles.settingIcon}
              />
              {t("buttons.configureCatalog")}
            </a>
          )
        },
        {
          key: 3,
          label: (
            <a onClick={this.truncate} className={styles.settingRemove}>
              <Icon type={"icon text-24"} className={styles.settingIcon} />
              {t("buttons.truncateCatalog")}
            </a>
          )
        },
        {
          key: 4,
          label: (
            <a onClick={this.remove} className={styles.settingRemove}>
              <Icon
                type={"icon edition-41"}
                className={styles.settingIcon}
              />
              {t("buttons.removeCatalog")}
            </a>
          )
        }
      ];

      menu = [...menu, ...adminItemsMenu];
    }
    

    return (
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        className={cn(styles.container, {
          [styles.containerHidden]: !leftPanel
        })}
      >
        {leftPanel && (
          <h2 className={styles.catalogName}>
            {catalog && catalog.get("name")}
          </h2>
        )}
        {leftPanel ? (
          <Dropdown menu={{items: menu}} trigger={["click"]} placement="bottomRight">
            <ButtonTransparent className={styles.catalogSetting}>
              <Icon type="icon setting-10" />
            </ButtonTransparent>
          </Dropdown>
        ) : null}

        <ButtonTransparent
          className={styles.buttonCollapse}
          onClick={this.toggleLeftPanel}
          title={
            !leftPanel
              ? t("catalogData.tooltip.filterExpand")
              : t("catalogData.tooltip.filterCollapse")
          }
        >
          <Icon
            type={`icon ${
              !leftPanel
                ? "arrows-chevron-medium-4-01"
                : "arrows-chevron-medium-3-01"
            }`}
          />
        </ButtonTransparent>
      </Row>
    );
  }
}

export default withTranslation()(
  connect(
    withRouter(CatalogHeader),
    {
      leftPanel: ["ui", "leftPanel", "visible"]
    },
    (props, { leftPanel }) => {
      const sectionId = props.catalog.get("sectionId");
      const catalogId = props.catalog.get("id");
      return {
        ...props,
        catalogId,
        sectionId,
        leftPanel
      };
    }
  )
);
