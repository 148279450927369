import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "antd";
import {  CheckSquareOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";

import ItemListEditor from "./ItemListEditor";
import editorActions from "../../../actions/editorActions";

import styles from "./fields.less";

class CheckboxesField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      defaultValue: !!this.props.field.getIn(["config", "defaultValue"])
    };
  }

  onChangeDefaultValue = e => {
    let defaultValue = e.target.checked;
    this.setState({
      defaultValue: defaultValue
    });
    editorActions.changeFieldConfig(
      this.props.sectionId,
      this.props.fieldIndex,
      {
        defaultValue: defaultValue
      }
    );
  };

  render() {
    const { 
      mode, 
      t 
    } = this.props;

    if (mode === "view") {
      const config = this.props.field.get("config");
      const items = config.get("items") || [];

      return (
        <div className={styles.wrapperViewMode}>
          <div>
            {
              items.map((value, i) =>   
                <div className={styles.checkBoxInline}>
                  <CheckSquareOutlined />
                  <span>{value.get("name")}</span>
                </div>
              )
            }
            <span className={styles.textChange}>
              { t("catalogEditor.field.lastItemWhenCollapsed") + "..." }
            </span>
          </div>
        </div>
      );
    } else return (
        <div>
          <ItemListEditor
            disabled={this.props.disabled}
            fieldIndex={this.props.fieldIndex}
            sectionId={this.props.sectionId}
            catalogId={this.props.catalogId}
            field={this.props.field}
          />
  
          <Checkbox
            className={styles.checkboxInline}
            disabled={this.props.disabled}
            checked={this.state.defaultValue}
            onChange={this.onChangeDefaultValue}
          >
            {this.props.t("fieldTypes.checkboxes.default")}
          </Checkbox>
        </div>
      );
  }
}

CheckboxesField.propTypes = {
  field: PropTypes.object.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  catalogId: PropTypes.string,
  sectionId: PropTypes.string,
  disabled: PropTypes.bool
};

export default withTranslation()(CheckboxesField);
