import debug from "debug";
import Guid from "guid";
import Immutable from "immutable";

import FieldFactory from "../models/FieldFactory";
import generateUniqName from "../utils/generateUniqName";
import FIELD_TYPES from "../configs/fieldTypes";

const log = debug("CRM:Store:editorMixin");

const editorMixin = {
  _setEditingCatalogUnsaved(sectionId, val) {
    this.setIn(["editingCatalogs", sectionId, "unsaved"], !!val);
  },

  removeField(sectionId, fieldIndex) {
    let fields = this.getIn(["editingCatalogs", sectionId, "fields"]);

    // delete visibility rules
    const fieldId = fields.get(fieldIndex).get("id");
    if (fieldId) {
      fields = fields.map(f => {
        if (f.getIn(["visible", fieldId])) {
          f = f.deleteIn(["visible", fieldId]);
        }
        return f;
      });
    }

    // delete field
    this.setIn(
      ["editingCatalogs", sectionId, "fields"],
      fields.delete(fieldIndex)
    );
    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  },

  dropField(sectionId, fieldIndex, fieldType, prevFieldIndex) {
    let field;

    if (fieldIndex != null) {
      let fields = this.getIn(["editingCatalogs", sectionId, "fields"]);
      field = fields.get(fieldIndex);
      this.setIn(
        ["editingCatalogs", sectionId, "fields"],
        fields.delete(fieldIndex)
      );
    }

    if (!field) {
      field = FieldFactory.create({
        // id: Guid.raw(),
        type: fieldType
      });
      let uniqName = generateUniqName(
        field.get("name"),
        this.getIn(["editingCatalogs", sectionId, "fields"])
          .toArray()
          .map(c => c.get("name"))
      );
      field = field.set("name", uniqName);
    }

    let fields = this.getIn(["editingCatalogs", sectionId, "fields"]);
    let offset = 0;
    if (fieldIndex === undefined || prevFieldIndex < fieldIndex) {
      offset = 1;
    }
    this.setIn(
      ["editingCatalogs", sectionId, "fields"],
      fields.splice(prevFieldIndex + offset, 0, field)
    );
    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  },

  dropCollapsedSection(sectionId, sectionIndex, sectionType, prevFieldIndex) {
    let section;
    let fields = this.getIn(["editingCatalogs", sectionId, "fields"]);
    section = fields.get(sectionIndex);

    if (!section) {
      section = FieldFactory.create({ type: sectionType });
      const name = section.get("name");
      const allNamesFields = this
          .getIn(["editingCatalogs", sectionId, "fields"])
          .toArray()
          .map(c => c.get("name"));

      let uniqName = generateUniqName(name, allNamesFields);
      section = section.set("name", uniqName);
    }
    
    const moveSectionWithFields = [section];

    // ищем все филды этой секции, спускаясь вниз по массиву до следующей секции           
    for (let i = sectionIndex + 1; i < fields.size; i++) { 
      if (fields.get(i).get("type") === FIELD_TYPES.GROUP) { 
        break;
      }
      moveSectionWithFields.push(fields.get(i));
    }

    // запоминаем филд, перед которым будем вставлять секцию
    let prevField = (prevFieldIndex > -1) ? fields.get(prevFieldIndex) : null;

    // вырезаем секцию с филдами из общего массива филдов
    fields = fields.splice(sectionIndex, moveSectionWithFields.length);

    // в новом массиве ищем филд, перед которым будем вставлять секцию
    // если такого нет, то вставляем в начало массива
    let newPrevFieldIndex = prevField 
      ? fields.findIndex(f => f.get("uuid") === prevField.get("uuid")) 
      : -1;

    // если секция находится в конце массива и ее перемещаем снова в конец
    if (prevFieldIndex > fields.size) {
      newPrevFieldIndex = fields.size - 1;
    }

    // вставляем секцию в новое место 
    fields = fields.splice(newPrevFieldIndex + 1, 0, ...moveSectionWithFields);

    this.setIn(
      ["editingCatalogs", sectionId, "fields"],
      fields
    );

    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  },

  updateField(sectionId, fieldIndex, field) {
    let state = this.getState();
    state = state.mergeIn(
      ["editingCatalogs", sectionId, "fields", fieldIndex],
      field || {}
    );
    this.setState(state);
    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  },

  changeFieldConfig(sectionId, fieldIndex, config) {
    let state = this.getState();
    state = state.mergeIn(
      ["editingCatalogs", sectionId, "fields", fieldIndex, "config"],
      config || {}
    );
    this.setState(state);
    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  },

  setFieldName(sectionId, fieldIndex, name) {
    this.setIn(
      ["editingCatalogs", sectionId, "fields", fieldIndex, "name"],
      name
    );
    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  },

  setFieldRequired(sectionId, fieldIndex, required) {
    this.setIn(
      ["editingCatalogs", sectionId, "fields", fieldIndex, "required"],
      required
    );
    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  },

  setFieldApiOnly(sectionId, fieldIndex, enabled) {
    this.setIn(
      ["editingCatalogs", sectionId, "fields", fieldIndex, "apiOnly"],
      enabled
    );
    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  },

  setFieldHint(sectionId, fieldIndex, name) {
    this.setIn(
      ["editingCatalogs", sectionId, "fields", fieldIndex, "hint"],
      name
    );
    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  },

  setCatalogIcon(sectionId, icon) {
    this.setIn(["editingCatalogs", sectionId, "icon"], icon);
    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  },

  setCatalogName(sectionId, name) {
    this.setIn(["editingCatalogs", sectionId, "name"], name);
    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  },

  setCatalogSection(sectionId, catalogSectionId) {
    this.setIn(["editingCatalogs", sectionId, "sectionId"], catalogSectionId);
    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  }

  // saveEditingCatalog() {
  //   let catalogId = this.getIn(['editingCatalog', 'id']);
  //   if ( this.getIn(['editingCatalog', 'isNew']) ) {
  //     let st = this.getState();
  //     st = st
  //       .setIn(['editingCatalog', 'isNew'], false)
  //       .setIn(['editingCatalog', 'unsaved'], false)
  //       .set('catalogs', st.get('catalogs').set('new', st.get('editingCatalog')) )
  //       .set('editingCatalog', null);
  //     this.setState(st);
  //   } else {
  //     let cat = this.getIn(['catalogs', catalogId]);
  //     if ( cat ) {
  //       let st = this.getState();
  //       st = st
  //         .setIn(['editingCatalog', 'unsaved'], false)
  //         .setIn(['catalogs', catalogId], st.get('editingCatalog'))
  //         .set('editingCatalog', null);
  //       this.setState(st);
  //     } else {
  //       // TODO
  //     }
  //   }

  //   this.changed();
  // }
};

export default editorMixin;
