import React from "react";
import cn from "classnames";

import NavRoute from "../../../../common/router/Route";
import routes from "../../../../../routes";
import { connect } from "../../../../StateProvider";
import CatalogSceneController from "./CatalogSceneController";

import styles from "./sectionBody.less";

const SectionBody = props => {
  return (
    <div
      className={cn(styles.body, {
        [styles.bodyFixed]: props.sidebarMenu.get("fixed")
      })}
    >
      <NavRoute route={routes.catalog} component={CatalogSceneController} />
    </div>
  );
};

export default connect(
  SectionBody,
  {
    sidebarMenu: ["ui", "sidebarMenu"]
  }
);
