import { Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';

import ButtonTransparent from '../../../../../../common/UI/ButtonTransparent';
import FeedbackError from './FeedbackError';
import FeedbackSuccess from './FeedbackSuccess';

import styles from "./feedBack.less"



const Feedback = ({ setFeedback, t, feedbackSuccess, feedbackError, onFinish, setFeedbackSuccess, setFeedbackError, email: userEmail, requestProcessed }) => {
    const [phone, setPhone] = useState("");
    const [emailValue, setEmailValue] = useState(userEmail);
    const [form] = Form.useForm();
    form.setFieldsValue({
        email: userEmail
    });

    const disabledButton = requestProcessed || (!phone || !emailValue);

    if (feedbackSuccess) {
        return <FeedbackSuccess t={t} setFeedbackSuccess={setFeedbackSuccess} setFeedback={setFeedback} />
    };
    if (feedbackError) {
        return <FeedbackError t={t} setFeedbackError={setFeedbackError} />
    };

    return (
        <div className={styles.containerLicenseAbout}>
            <h4 className={styles.feedbackTitle}>{t("userMenu.feedback.feedbackTitle")}</h4>
            <Form
                form={form}
                name="normal_login"
                onFinish={onFinish}
            >
                <span className={styles.feedbackText}>{t("userMenu.feedback.phone")}</span>
                <Form.Item
                    style={{ marginBottom: 0 }}
                    name="phone"
                >
                    <Input type="text" onChange={(e) => setPhone(e.target.value)} />
                </Form.Item>
                <span className={styles.feedbackText}>{t("userMenu.feedback.email")}</span>
                <Form.Item
                    style={{ marginBottom: "10px" }}
                    name="email"
                >
                    <Input type="text" onChange={(e) => setEmailValue(e.target.value)} />
                </Form.Item>
                <div className={styles.feedbackBtnGroup}>
                    <Button disabled={disabledButton} htmlType="submit">{t("userMenu.feedback.buttons.sendRequest")}</Button>
                    <ButtonTransparent onClick={() => setFeedback({ flag: false, message: "" })} className={styles.feedbackBtnCancel}>{t("userMenu.feedback.buttons.cancel")}</ButtonTransparent>
                </div>
            </Form>
        </div>
    );
};

export default Feedback