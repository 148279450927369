import React from "react";
import _ from 'lodash';
import { Link } from "react-router-dom";
import cn from "classnames";
import { useTranslation } from 'react-i18next';

import Icon from '../../common/UI/Icon';

import styles from "./companies.less";

export const Sessions = ({ sessions }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className={styles.sessionContainer}>
        {!_.isEmpty(sessions) ? (
          sessions.map(session => (
            <div
              key={`${session.account.email}:${session.company.title}`}
              className={styles.sessionItemWrapper}
            >
              <a
                className={styles.sessionItem}
                href={"//" + session.company.domain}
                key={session.company.title + ":" + session.account.email}
              >
                <div className={styles.sessionLeftGroup}>
                  <h2 className={styles.companyTitle}>
                    {session.company.title}
                  </h2>
                  <p className={styles.companyDomain}>
                    {session.company.domain} {session.account.email}
                  </p>
                </div>
                <Icon
                  className={styles.sessionIcon}
                  type="icon arrows-chevron-big-4-01"
                />
              </a>
            </div>
          ))
        ) : (
          <div className={styles.sessionItemWrapper}>
            <div className={styles.sessionsNotFound}>
              {t("menuPopover.searchSideEmpty")}
            </div>
          </div>
        )}
      </div>

      {/* Enter Button */}
      <div className={styles.sessionButtomContainer}>
        <div className={styles.sessionItemWrapper}>
          <Link
            className={cn(styles.sessionItem, styles.sessionEnter)}
            to="/auth/login"
          >
            {t("auth.enterOtherUser")}
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sessions;
