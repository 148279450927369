import React, { Component } from "react";
import PropTypes from "prop-types";

import routes from "../../../../../../../../routes";

import NavRoute from "../../../../../../../common/router/Route";
import { connect } from "../../../../../../../StateProvider";

import Records from "../../../../../../../Records";
import Cards from "../../../../../../../Cards";
import Calendar from "../../../../../../../Calendar";
import Reports from "../../../../../../../Reports";
import History from "../../../../../../../History";

import styles from "./viewBody.less";
import _ from "lodash";
import filterActions from "../../../../../../../../actions/filterActions";
import StatisticsData from "../../../../../../../../components/Statistics";

class ViewBody extends Component {
  static propTypes = {
    catalog: PropTypes.object,
    catalogId: PropTypes.string,
    scene: PropTypes.object,
    sceneId: PropTypes.string,
    viewId: PropTypes.string
  };

  render() {
    const {
      catalog,
      catalogId,
      scene,
      sceneId,
      viewId,
      openRecordsInModal
    } = this.props;

    return (
      <div className={styles.container}>
        <NavRoute
          route={routes.records}
          render={props => {
            return scene && catalog ? (
              <Records
                catalog={catalog}
                catalogId={catalogId}
                scene={scene}
                sceneId={sceneId}
                viewId={viewId}
                openRecordsInModal={openRecordsInModal}
              />
            ) : null;
          }}
        />
        <NavRoute
          route={routes.cards}
          render={props => {
            return scene && catalog ? (
              <Cards
                catalog={catalog}
                catalogId={catalogId}
                scene={scene}
                sceneId={sceneId}
                viewId={viewId}
              />
            ) : null;
          }}
        />
        {this.props.calendarExists && (
          <NavRoute
            route={routes.calendar}
            render={props => {
              return scene && catalog ? (
                <Calendar
                  catalog={catalog}
                  catalogId={catalogId}
                  scene={scene}
                  viewId={viewId}
                />
              ) : null;
            }}
          />
        )}
        <NavRoute
          route={routes.reports}
          render={props => {
            return catalog ? (
              <Reports catalog={catalog} viewId={viewId} sceneId={sceneId} />
            ) : null;
          }}
        />
        <NavRoute
          route={routes.history}
          render={props => {
            return scene && catalog ? (
              <History
                catalog={catalog}
                sceneId={sceneId}
                scene={scene}
                viewId={viewId}
                getFieldsToRender={this.props.getFieldsToRender}
              />
            ) : null;
          }}
        />
        <NavRoute
          route={routes.statistics}
          render={props => {
            return scene && catalog ? (
              <StatisticsData
                scene={scene}
                catalog={catalog}
                viewId={viewId}
              />
            ) : null;
          }}
        />
      </div>
    );
  }
}

export default connect(
  ViewBody,
  {
    scenes: ["scenes"],
    catalogs: ["catalogs"],
    userSettings: ["userSettings", "catalogs"]
  },
  (props, { scenes, catalogs, userSettings }) => {
    const scene = props.sceneId && scenes.get(props.sceneId);
    const catalog = props.catalogId && catalogs.get(props.catalogId);
    const sortField = userSettings.getIn([
      props.catalogId,
      "viewMode",
      "table",
      "sortingRecords",
      "sortField"
    ]);
    const sortType = userSettings.getIn([
      props.catalogId,
      "viewMode",
      "table",
      "sortingRecords",
      "sortType"
    ]);

    return {
      catalog,
      scene,
      sortType,
      sortField,
      ...props
    };
  }
);
