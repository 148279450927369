import $ from "jquery";
import "./numeral";
import "./moment";
import "./pace";

window.jQuery = $; // else fotorama not work

// workaround to support React@15 api
// todo refactor code and remove this code
// import createReactClass from 'create-react-class'
// import propTypes from 'prop-types'
// import React from 'react'
// React.createClass = createReactClass;
// React.PropTypes = propTypes;
