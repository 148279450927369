import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Col ,Row, Switch, Checkbox } from "antd";

import editorActions from "../../../actions/editorActions";

import styles from "./fields.less";

class SwitchField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      defaultValue: !!this.props.field.getIn(["config", "defaultValue"])
    };
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      defaultValue: nextProps.field.getIn(["config", "defaultValue"])
    });
  }

  onChangeDefaultValue = (e) => {
    let defaultValue = e.target.checked;
    this.setState({
      defaultValue: defaultValue
    });
    editorActions.changeFieldConfig(
      this.props.sectionId,
      this.props.fieldIndex,
      {
        defaultValue: defaultValue
      }
    );
  };

  

  render() { 
    const { t } = this.props;

    return (
      <Row gutter={[24, 16]}>
        <Col>
          <Checkbox
            disabled={this.props.disabled}
            checked={this.state.defaultValue}
            onChange={this.onChangeDefaultValue}
        >
          {t("fieldTypes.switch.default")}
        </Checkbox>
        </Col>
      </Row>
    );

  }
}


SwitchField.propTypes = {
  field: PropTypes.object.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  sectionId: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default withTranslation()(SwitchField);