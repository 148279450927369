import _ from "lodash";
import Immutable from "immutable";

import FIELD_TYPES from "../../../../../configs/fieldTypes";

export default function dynamicFiltersList(sourceCatalog, editingCatalog) {
  /*
  Дилер
    Название
    
  Производитель
    Название

  Клиент
    Привел: Дилер/Производитель
    Обслуживание: Дилер/Производитель
    
  Партнер
    Работает: Дилер/Производитель
    ХочетРаботать: Дилер/Производитель
    
  Заказ
    Исполнитель: Дилер/Производитель
    Контроль: Дилер/Производитель
    
    Покупатель: Клиент/Партнер
      Клиент
        
        Клиент.Привел.Дилер = Исполнитель.Дилер
        Клиент.Привел.Производитель = Исполнитель.Производитель
        Клиент.Привел.Дилер = Контроль.Дилер
        Клиент.Привел.Производитель = Контроль.Производитель
        
        Клиент.Обслуживание.Дилер = Исполнитель.Дилер
        Клиент.Обслуживание.Производитель = Исполнитель.Производитель
        Клиент.Обслуживание.Дилер = Контроль.Дилер
        Клиент.Обслуживание.Производитель = Контроль.Производитель
        
      Партнер
      
        Партнер.Работает.Дилер = Исполнитель.Дилер
        Партнер.Работает.Производитель = Исполнитель.Производитель
        Партнер.Работает.Дилер = Контроль.Дилер
        Партнер.Работает.Производитель = Контроль.Производитель
        
        Партнер.ХочетРаботать.Дилер = Исполнитель.Дилер
        Партнер.ХочетРаботать.Производитель = Исполнитель.Производитель
        Партнер.ХочетРаботать.Дилер = Контроль.Дилер
        Партнер.ХочетРаботать.Производитель = Контроль.Производитель


      (в понятиях)
        sourceCatalog.sourceField.sourceFieldCatalog = filterField.filterFieldCatalog
      (упрощенно)
        sourceCatalog.sourceField = filterField

      (условие попадания в список: пересечение в sourceFieldCatalogs и filterFieldCatalogs)

  */

  const items = [];
  //items.push({});

  // collect sourceFieldCatalogs for each sourceField
  const sourceFields =
    sourceCatalog &&
    sourceCatalog
      .get("fields")
      .filter(
        field =>
          field.get("type") === FIELD_TYPES.OBJECT ||
          field.get("type") === FIELD_TYPES.USER
      )
      .map(field => {
        if (field.get("type") === FIELD_TYPES.OBJECT) {
          // each filterField can contain many linked catalogs & veiws

          // collect all uniq catalogs in source field
          let fieldCatalogs = field.getIn(["config", "catalogs"]);
          fieldCatalogs =
            (fieldCatalogs && fieldCatalogs.map(c => c.get("id")).toJS()) || [];
          let fieldViews = field.getIn(["config", "views"]);
          fieldViews =
            (fieldViews && fieldViews.map(c => c.get("catalogId")).toJS()) ||
            [];
          fieldCatalogs = _.uniq(fieldCatalogs.concat(fieldViews));

          field = field.set("fieldCatalogs", fieldCatalogs);
        } else if (field.get("type") === FIELD_TYPES.USER) {
          // all is already enought
        }

        return field;
      });

  // collect filterFieldCatalogs for each filterField (fields — fields of this edited catalogs)
  const filterFields =
    editingCatalog &&
    editingCatalog
      .get("fields")
      .filter(
        field =>
          field.get("type") === FIELD_TYPES.OBJECT ||
          field.get("type") === FIELD_TYPES.USER
      )
      .map(field => {
        if (field.get("type") === FIELD_TYPES.OBJECT) {
          // each filterField can contain many linked catalogs & veiws

          // collect all uniq catalogs in filter field
          let fieldCatalogs = field.getIn(["config", "catalogs"]);
          fieldCatalogs =
            (fieldCatalogs && fieldCatalogs.map(c => c.get("id")).toJS()) || [];
          let fieldViews = field.getIn(["config", "views"]);
          fieldViews =
            (fieldViews && fieldViews.map(c => c.get("catalogId")).toJS()) ||
            [];
          fieldCatalogs = _.uniq(fieldCatalogs.concat(fieldViews));

          field = field.set("fieldCatalogs", fieldCatalogs);
        } else if (field.get("type") === FIELD_TYPES.USER) {
          // all is already enought
        }

        return field;
      });

  // get intersection of sourceFields.fieldCatalogs × filterFields.fieldCatalogs
  let addToList;

  sourceFields &&
    sourceFields.map(sourceField => {
      filterFields.map(filterField => {
        addToList = false;
        if (
          sourceField.get("type") === FIELD_TYPES.OBJECT &&
          filterField.get("type") === FIELD_TYPES.OBJECT
        ) {
          const commonCatalogs = _.intersectionBy(
            sourceField.get("fieldCatalogs"),
            filterField.get("fieldCatalogs")
          );
          if (commonCatalogs && commonCatalogs.length) {
            addToList = true;
          }
        } else if (
          sourceField.get("type") === FIELD_TYPES.USER &&
          filterField.get("type") === FIELD_TYPES.USER
        ) {
          addToList = true;
        }

        if (addToList) {
          items.push({
            sourceCatalog,
            sourceField,
            filterField
          });
        }
      });
    });

  return Immutable.fromJS(items);
}
