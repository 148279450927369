import _ from "lodash";
import Immutable from "immutable";

const progressThrottleFn = {};

export default {
  /* 
    queue: Immutable.Map({
      map: Immutable.Map() || Immutable.List(),
      action: (item, itemKey) => function( return Promise ),
      onComplited: (item, itemKey, result) => function(),
      onFailed: (item, itemKey, result) => function(),
      onPause: (item, itemKey) => function(),
      onFinish: () => function(),
      onProgress: () => function(),
      updateTime: updateTime for throttle
    })
  */
  initQueue(key, queue) {
    this.setIn(key, queue);

    // progress callback
    const updateTime = queue.get("updateTime") || 0;
    const onProgress = queue.get("onProgress");
    if (onProgress) {
      progressThrottleFn[key] = _.throttle(onProgress, updateTime, {
        trailing: true
      });
    }

    this.changed();
  },

  onProgress(key) {
    const map = this.getIn([...key, "map"]);
    const iterCount = this.getIn([...key, "index"]);

    const startTime = this.getIn([...key, "startTime"]);
    const workTime = +Date.now() - startTime;

    let avarageTime = workTime / iterCount;

    const allIters = (map && map.size) || 0;
    const towardIters = +allIters - +iterCount;

    const waitingTime = avarageTime * towardIters || undefined;

    progressThrottleFn[key] && progressThrottleFn[key](waitingTime);
  },

  startQueue(key) {
    this.setIn([...key, "pause"], false);
    this.changed();

    let startTime = this.getIn([...key, "startTime"]);
    let endTime = this.getIn([...key, "endTime"]);

    if (startTime) {
      const now = +Date.now();
      startTime = now - (endTime - startTime);
      this.setIn([...key, "startTime"], startTime);
    } else {
      this.setIn([...key, "startTime"], +Date.now());
    }

    this._iter(key);
  },

  _iter(key) {
    let index = this.getIn([...key, "index"]) || 0;
    const map = this.getIn([...key, "map"]);
    const item = map && map.get(index);

    const paused = this.getIn([...key, "pause"]);

    const action = this.getIn([...key, "action"]);
    const onComplited = this.getIn([...key, "onComplited"]);
    const onFailed = this.getIn([...key, "onFailed"]);

    if (!item) {
      this.finishQueue(key);
      return;
    }

    if (paused) {
      this.pauseQueue(key);
      return;
    }

    // this.deleteIn([...key, "map", 0]);

    return action(item)
      .then(result => {
        onComplited && onComplited(item, index, result);
      })
      .catch(result => {
        onFailed && onFailed(item, index, result);
      })
      .finally(() => {
        if (paused) return;

        this.setIn([...key, "index"], ++index);

        // progress
        this.onProgress(key);

        this._iter(key);
      });
  },

  pauseQueue(key) {
    const onPause = this.getIn([...key, "onPause"]);
    onPause && onPause();

    this.setIn([...key, "endTime"], +Date.now());
    this.setIn([...key, "pause"], true);
    this.changed();
  },

  stopQueue(key) {
    const onStop = this.getIn([...key, "onStop"]);
    onStop && onStop();

    this.reset(key);

    this.changed();
  },

  reset(key) {
    if (progressThrottleFn[key]) {
      delete progressThrottleFn[key];
    }

    this.deleteIn(key);
  },

  finishQueue(key) {
    const queue = this.getIn(key);
    const onFinish = queue.get("onFinish");

    if (progressThrottleFn[key]) {
      delete progressThrottleFn[key];
    }

    onFinish && onFinish();
    this.deleteIn(key);

    this.changed();
  }
};
