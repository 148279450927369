import _ from "lodash";
import { message } from "antd";

import i18n from "../../configs/i18n";

const recordMixinUpdating = {
  /* 
    Record
  */

  updateRecord(params, data, actionParams = {}) {
    const { silent = false } = actionParams;

    const record = this.getIn(["records", params.catalogId, params.recordId]);
    if (!record) {
      return;
    }

    this.setIn(["records", params.catalogId, params.recordId, "saving"], true);
    this.setIn(
      ["records", params.catalogId, params.recordId, "saveError"],
      null
    );

    !silent && this.changed();
  },

  updateRecordCompleted(result, params, data, query, res, actionParams = {}) {
    const { onCompleted } = actionParams;

    const recordKey = ["records", params.catalogId, params.recordId];

    const record = this.getIn(["records", params.catalogId, params.recordId]);
    if (record && record.get("id") === params.recordId) {
      this.setRecord(params, data);
      // update record state
      this.setIn([...recordKey, "isNew"], null);
      this.setIn([...recordKey, "saving"], false);
      this.setIn([...recordKey, "history", "forceReload"], true);
      this.setIn([...recordKey, "saveError"], null);
      this.setIn([...recordKey, "creating"], false);
      this.setIn([...recordKey, "hasBeenEdit"], false);
    };

    // show message from successed events
    let messageFromResult = result && result.message;
    if (messageFromResult) {
      const { alert } = require("../../components/common/Modal");
      const title = _.isObject(messageFromResult)
        ? messageFromResult.title
        : "";
      messageFromResult = _.isObject(messageFromResult)
        ? messageFromResult.text
        : messageFromResult;

      const text = {
        headerText:
          title !== ""
            ? title || i18n.t("modals.record.success.title")
            : i18n.t("modals.record.success.title"),
        text: messageFromResult,
        okText: i18n.t("modals.record.success.okText")
      };

      onCompleted
        ? onCompleted(params.catalogId, params.recordId, text)
        : alert(text);
    }
  },

  updateRecordFailed(err, params, data, query, actionParams) {
    const { silent = false, onFailed } = actionParams;

    const { alert } = require("../../components/common/Modal");
    const errText = _.isObject(err) && err.text ? err.text : "";
    this.setIn(["records", params.catalogId, params.recordId, "saving"], false);
    this.setIn(
      ["records", params.catalogId, params.recordId, "creating"],
      false
    );
    this.setIn(
      ["records", params.catalogId, params.recordId, "saveError"],
      errText || true
    );

    !silent && this.changed();

    const isAccessDenied = err && err.status == 403;
    const notFound = err && err.status == 403;
    const serverError = err ? err.status && err.status[0] == 5 : false;

    let text;

    if (isAccessDenied) {
      /* можно разные текста использовать */
      text = errText || i18n.t("modals.denied.record.update.text");
      onFailed
        ? onFailed(params.catalogId, params.recordId, text)
        : message.error(text);
    } else if (notFound) {
      text = errText || i18n.t("modals.recordSaveError.text");
      onFailed
        ? onFailed(params.catalogId, params.recordId, text)
        : message.error(text);
    } else if (serverError) {
      text = errText || i18n.t("modals.recordSaveError.text");
      onFailed
        ? onFailed(params.catalogId, params.recordId, text)
        : message.error(text);
    } else {
      text = {
        headerText:
          (err && err.title) || i18n.t("modals.recordSaveError.headerText"),
        text: errText || i18n.t("modals.recordSaveError.text"),
        okText: i18n.t("modals.recordSaveError.okText")
      };
      onFailed
        ? onFailed(params.catalogId, params.recordId, text)
        : alert(text);
    }
  }
};

export default recordMixinUpdating;
