import React, { useEffect, useState } from "react";
import { Form, Input, Descriptions, Button, message } from "antd";
import cn from "classnames";
import { withTranslation } from 'react-i18next';

import apiActions from "../../actions/apiActions";
import { connect } from "../StateProvider";
import { prepareDescription } from "./helpers/prepareDescription";

import styles from "./styles.less";

const activateLink = "bpium.ru/activate";
const activateUrl = "https://bpium.ru/activate";

const ActivateForm = (props) => {

  const [description, setDescription] = useState({});
  const [errors, setErrors] = useState({
    email: { errors: [] },
    password: { errors: [] }
  });
  const [form] = Form.useForm();

  useEffect(() => {
    apiActions.getCompanyInfo();
    apiActions.getLicense();
  }, []);

  const handleSubmit = async (values) => {
    const { t } = props;
    form.validateFields().then((data) => {
      apiActions.activate(data.license).then(response => {
        if (response.status == 200) {
          window.location.href = '/';
        } else {
          message.error(t("auth.errors.somethinWrong"));
        }
      })["catch"](err => {
        message.error(err.message);
      });
    }).catch((errors) => {
      setErrors(errors);
      form.setFields({
        license: {
          errors: [new Error(t("auth.activate.wrongCode"))]
        }
      });
    });
  };

  const getDescriptionComponent = () => {
    const { license: params, i18n, t } = props;
    if (!params) return;

    const description = prepareDescription(params, i18n.language, t);

    return (
      <Descriptions.Item label="Config Info">
        <p>{description.title}:</p>
        <p>{description.users}</p>
        <p>{description.extUsers}</p>
        <p>{description.records}</p>
        <p>{description.catalogs}</p>
        <p>{description.active}</p>
      </Descriptions.Item>
    );
  };

  const { activationCode, t } = props;
  const DescriptionComponent = getDescriptionComponent();
  return (
    <div className={styles.formContainer}>
      <div className={styles.formInner}>
        <Form form={form} className={styles.form} name="ActivateForm" onFinish={handleSubmit}>
          <h1>{t("auth.activate.title")}</h1>
          {DescriptionComponent}
          <Form.Item className={cn(styles.formItem)}>
            <div>
              <div className={styles.formLabel}>
                {t("auth.activate.code")}
              </div>
              <Input
                className={styles.formField}
                readOnly
                value={activationCode}
              />
            </div>
          </Form.Item>
          <div>
            {t("auth.activate.getLicense")}{" "}
            <a href={activateUrl}>{activateLink}</a>
          </div>

          <Form.Item
            name="license"
            rules={[{ required: true, message: t("auth.fieldHintMessage") }]}
          >
            <div>
              <div className={styles.formLabel}>
                {t("auth.activate.enterCode")}
              </div>
              <Input.TextArea
                className={styles.textArea}
                rows={7}
                autoFocus
              />
            </div>
          </Form.Item>

          <Form.Item>
            <Button
              tabIndex={0}
              key="submit"
              type="primary"
              className={styles.loginButton}
              htmlType="submit"
            >
              {t("auth.activate.activate")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default withTranslation()(connect(
  ActivateForm,
  { license: ["license"], activationCode: ["activationCode"] }
));