// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* CARD */\n.card__card___ws3IF {\n  padding: 5px 10px;\n  margin: 5px;\n  background-color: #ffffff;\n  border: 1px solid rgba(0, 0, 0, 0.12);\n  border-radius: 4px;\n  transition: all 0.25s;\n  cursor: grab;\n}\n.card__card___ws3IF:first-child {\n  margin-top: 0;\n}\n.card__card___ws3IF:hover {\n  border-color: rgba(0, 0, 0, 0.1);\n  box-shadow: 0 4px 8px rgba(90, 97, 105, 0.02), 0 10px 10px rgba(90, 97, 105, 0.02), 0 12px 90px rgba(90, 97, 105, 0.05);\n  transform: translate(0px, -2px);\n}\n.card__field___y6YT7 {\n  display: block;\n  margin-bottom: 2px !important;\n  max-width: 100%;\n  overflow: hidden;\n  word-wrap: break-word;\n  height: 100%;\n  white-space: break-spaces;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Cards/Card/card.less"],"names":[],"mappings":"AAAA,SAAS;AAGT;EACE,iBAAA;EACA,WAAA;EACA,yBAAA;EACA,qCAAA;EACA,kBAAA;EACA,qBAAA;EACA,YAAA;AADF;AAEE;EACE,aAAA;AAAJ;AAGA;EACE,gCAAA;EACA,uHAAA;EAEA,+BAAA;AAFF;AAKA;EACE,cAAA;EACA,6BAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;EACA,YAAA;EACA,yBAAA;AAHF","sourcesContent":["@import (reference) \"../../../styles/helpers/index.less\";\n\n/* CARD */\n.card {\n  padding: @PADDING_BASE / 2 @PADDING_BASE;\n  margin: @PADDING_BASE /2;\n  background-color: @BG_CONTENT;\n  border: 1px solid rgba(0, 0, 0, 12%);\n  border-radius: 4px;\n  transition: all 0.25s;\n  cursor: grab;\n  &:first-child {\n    margin-top: 0;\n  }\n}\n.card:hover {\n  border-color: rgba(0, 0, 0, 0.1);\n  box-shadow: 0 4px 8px rgba(90, 97, 105, 0.02),\n    0 10px 10px rgba(90, 97, 105, 0.02), 0 12px 90px rgba(90, 97, 105, 0.05);\n  transform: translate(0px, -2px);\n}\n\n.field {\n  display: block;\n  margin-bottom: 2px !important;\n  max-width: 100%;\n  overflow: hidden;\n  word-wrap: break-word;\n  height: 100%;\n  white-space: break-spaces;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "card__card___ws3IF",
	"field": "card__field___y6YT7"
};
export default ___CSS_LOADER_EXPORT___;
