// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".authHeader__container___Bm-ti {\n  padding: 0 10px;\n  display: flex;\n  justify-content: space-between;\n}\n.authHeader__username___skQ-e {\n  padding: 6px 12px;\n  color: rgba(255, 255, 255, 0.5);\n  white-space: nowrap;\n  vertical-align: middle;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/UI/AuthHeader/authHeader.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,8BAAA;AACF;AAEA;EACE,iBAAA;EACA,+BAAA;EACA,mBAAA;EACA,sBAAA;AAAF","sourcesContent":[".container {\n  padding: 0 10px;\n  display: flex;\n  justify-content: space-between;\n}\n\n.username {\n  padding: 6px 12px;\n  color: rgba(255, 255, 255, 0.5);\n  white-space: nowrap;\n  vertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "authHeader__container___Bm-ti",
	"username": "authHeader__username___skQ-e"
};
export default ___CSS_LOADER_EXPORT___;
