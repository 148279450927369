import React from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import { withTranslation } from "react-i18next";

import editorActions from "../../../../actions/editorActions";

import ObjectField from "./ObjectField";
import Icon from "../../../common/UI/Icon";

import styles from "../fields.less";

class ObjectFieldMapper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  mapFromConfig = field => {
    const catalogs = field.getIn(["config", "catalogs"]);
    const views = field.getIn(["config", "views"]);
    let fields = field.getIn(["config", "fields"]);

    let items = [];

    // allow set fields only for 1 item for each catalog
    const fieldsEditorForCatalog = {};

    // parse catalogs
    catalogs &&
      catalogs.forEach(c => {
        const catalogId = c.get("id");
        const item = {
          key: catalogId,
          catalogId: catalogId,
          viewId: 0,
          title: c.get("title"),
          icon: c.get("icon"),
          disableFields: fieldsEditorForCatalog[catalogId],
          dynamicFilters: c.getIn(["dynamicFilters"])
        };
        item.fields = (fields && fields.get(catalogId)) || [];
        fieldsEditorForCatalog[catalogId] = true;

        const position = c.get("position");
        if (position >= 0) {
          items.splice(position, 0, item);
        } else {
          items.push(item);
        }
      });

    // parse views
    views &&
      views.forEach(v => {
        const viewId = v.get("id");
        const catalogId = v.get("catalogId");
        const item = {
          key: "view:" + viewId,
          catalogId: catalogId,
          viewId: viewId,
          title: v.get("title"),
          icon: v.get("icon"),
          disableFields: fieldsEditorForCatalog[catalogId],
          dynamicFilters: v.getIn(["dynamicFilters"])
        };
        item.fields = (fields && fields.get(catalogId)) || [];
        fieldsEditorForCatalog[catalogId] = true;

        const position = v.get("position");
        if (position >= 0) {
          items.splice(position, 0, item);
        } else {
          items.push(item);
        }
      });

    items = Immutable.fromJS(items);
    return items;
  };

  mapToConfig = items => {
    items = items.reduce(
      (acc, item, position) => {
        const catalogId = item.get("catalogId");
        const viewId = item.get("viewId");
        const title = item.get("title");
        const icon = item.get("icon");
        const fields = item.get("fields");
        const dynamicFilters = item.get("dynamicFilters");

        if (viewId) {
          acc.views.push({
            id: viewId,
            catalogId,
            title,
            icon,
            dynamicFilters,
            position
          });
        } else {
          acc.catalogs.push({
            id: catalogId,
            title,
            icon,
            dynamicFilters,
            position
          });
        }

        // fields
        if (!acc.fields[String(catalogId)]) {
          acc.fields[String(catalogId)] = fields;
        }

        return acc;
      },
      { catalogs: [], views: [], fields: {} }
    );

    return items;
  };

  onChange = value => {
    editorActions.changeFieldConfig(
      this.props.sectionId,
      this.props.fieldIndex,
      value
    );
  };

  onChangeItems = value => {
    this.onChange(this.mapToConfig(value));
  };

  render() {
    const { editingCatalog, field, disabled, mode, t } = this.props;

    const multiselect = field.getIn(["config", "multiselect"]);
    const view = field.getIn(["config", "mode"]);

    if (mode === "view") {
      const catalogs = field.getIn(["config", "catalogs"]) || [];
      const views = field.getIn(["config", "views"]) || [];

      const items = (catalogs).concat(views);

      return (
        <div className={styles.wrapperViewMode}>
          <div>
            {
              items.map(item => {
                const icon = item.get("icon") || item.get("catalogIcon");
                return (
                  <span className={styles.objectInline}>
                    {
                      icon && 
                      <Icon 
                        className={styles.objectInlineIcon} 
                        type={`icon ${icon}` } 
                      />
                    }
                    <span className={styles.objectInlineTitle}>
                      {item.get("title")}
                    </span> 
                  </span> 
              );}
              )
            }
            <span className={styles.textChange}>
              { t("catalogEditor.field.lastItemWhenCollapsed") + "..." }
            </span>
          </div>
        </div>
      );
    } else return (
      <ObjectField
        editingCatalog={editingCatalog}
        items={this.mapFromConfig(field)}
        view={view}
        multiselect={multiselect}
        onChangeItems={this.onChangeItems}
        onChange={this.onChange}
        disabled={disabled}
      />
    );
  }
}

ObjectFieldMapper.propTypes = {
  field: PropTypes.object.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  catalogs: PropTypes.object.isRequired,
  sectionId: PropTypes.string.isRequired,
  editingCatalog: PropTypes.object,
  disabled: PropTypes.bool
};

export default  withTranslation()(ObjectFieldMapper);
