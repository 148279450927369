import React from "react";

import Config from "./Config";
import FieldConfig from "../../common/FieldConfig";

import styles from "./header.less";

class Header extends React.PureComponent {
  state = {};

  render() {
    const { catalog, settings } = this.props;
    if (!catalog) {
      return;
    }
    return (
      <div className={styles.optionsContainer}>
        <Config catalog={catalog} settings={settings} viewMode="cards" />
        <FieldConfig
          catalogId={catalog.get("id")}
          fields={catalog.get("fields")}
          viewMode="cards"
          defaultVisible={false}
        />
      </div>
    );
  }
}

export default Header;
