import Immutable from "immutable";
import FieldFactory from "./FieldFactory";
import RecordFactory from "./RecordFactory";
import ViewFactory from "./ViewFactory";
import FIELD_TYPES from "../configs/fieldTypes";
import antiCapitalize from "../utils/antiCapitalize";
import i18n from "../configs/i18n";
import _ from "lodash";
import filtersUtils from "../utils/filters";

export default {
  create(data, t = i18n.t) {
    let catalog = {
      __name: "Catalog"
    };
    catalog.fields = [];
    // catalog.records = [];

    // catalog.history = {};

    // catalog.widgetsChartData = {};
    catalog.boards = {
      loaded: false,
      loading: false,
      list: []
    };

    catalog.allRecordsLoaded = false; // are all records loaded for current params
    catalog.lastLoadParams = null; // last load request params

    catalog = Immutable.fromJS(catalog);

    catalog = catalog.merge(this.format(data, t));
    return catalog;
  },

  format(data, t = i18n.t) {
    data = data || {};
    let catalog = {};

    // server data
    data.index !== undefined && (catalog.index = data.index);
    catalog.id = (data.id != null && data.id.toString()) || null;
    catalog.name = data.name || "";
    catalog.icon = data.icon || "";
    // catalog.count = data.count || 0;
    // catalog.notifications = data.notifications || 0;
    catalog.sectionId = data.sectionId;
    catalog.privilegeCode = data.privilegeCode;
    catalog.fieldPrivilegeCodes = data.fieldPrivilegeCodes;

    catalog.rights = data.rights;
    catalog.isNew = data.isNew;

    catalog.loading = false; // is there pending request for catalog records
    catalog.loadingError = null; // err obj for last request for records

    // create default view (all items)
    let catalogName = antiCapitalize(String(catalog.name).trim());
    catalog.views = Immutable.fromJS({
      "0": ViewFactory.create({
        id: 0,
        name: t("views.list.all") + " " + catalogName,
        isNew: false,
        index: -Infinity,
        catalogId: data.id
      })
    });

    catalog = Immutable.fromJS(catalog);

    if (data.fields) {
      //catalog = this.setCatalogFields(catalog, data.fields);
      const fields = this.prepareFields(data);
      catalog = catalog.set("fields", fields);
    } else if (catalog.get("isNew")) {
      catalog = catalog.setIn(
        ["fields"],
        Immutable.List([
          FieldFactory.create({
            type: FIELD_TYPES.GROUP
          })
        ])
      );
    }

    return catalog;
  },

  prepareFields(data) {
    let fields = data.fields;
    if (fields) {
      fields = fields.toJS ? fields.toJS() : fields;
      // set hidden param to fields
      fields = fields.map(f => {
        if (
          data.fieldPrivilegeCodes &&
          data.fieldPrivilegeCodes[f.id] &&
          data.fieldPrivilegeCodes[f.id] == "hide"
        ) {
          f.hidden = true;
        }
        return f;
      });

      // convert to Field
      fields = fields.map(f => {
        return FieldFactory.create(f, fields);
      });
    }

    return new Immutable.List(fields || []);
  },

  /* Depricated? */
  setCatalogFields(catalog, fields) {
    let orderMatch = true;
    let currColIds = [];
    let currCols = catalog.get("fields") || Immutable.List();
    currCols.forEach(c => currColIds.push(c.get("id")));
    fields = fields.map((field, i) => {
      field.id = field.id.toString();
      if (field.id !== currColIds[i]) {
        orderMatch = false;
      }
      return FieldFactory.create(field, fields);
    });

    if ((orderMatch && fields.length) === (currCols && currCols.size)) {
      return catalog.set("fields", currCols.mergeDeep(fields));
    } else {
      return catalog.set("fields", Immutable.List(fields));
    }
  }
  /* 
  setRecords(catalog, records, offset) {
    offset = offset || 0;
    let appendRecords = offset > 0;
    let recordsMap = {};
    records.map((c, i) => (recordsMap[offset + i] = RecordFactory.create(c)));
    recordsMap = Immutable.Map(recordsMap);
    if (appendRecords) {
      recordsMap = catalog.get("records").merge(recordsMap);
    }
    return catalog.set("records", recordsMap);
  } */
};
