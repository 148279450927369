import React, { Component, useEffect, useState } from "react";
import {
  Menu,
  Dropdown,
  Row,
  Upload,
  message,
  Button
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import mime from "mime-types";
import _ from "lodash";
import Immutable from "immutable";
import { withTranslation } from "react-i18next";

import routes from "../../routes";
import getLink from "../common/router/getLink";
import LoadingSpin from "../common/LoadingSpin";
import ButtonClose from "../common/UI/ButtonClose";
import ImportData from "./Import";

import Icon from "../common/UI/Icon";
import filtersUtils from "../../utils/filters";
import { renderModaltoBody } from "../common/Modal";
import { confirm } from "../common/Modal";
import recordActions from "../../actions/recordActions";
import userSettingsActions from "../../actions/userSettingsActions";
import sceneActions from "../../actions/sceneActions";
import PRIVILEGE_CODES from "../../configs/privilegeCodes";
import RESOURCE_TYPES from "../../configs/resourceTypes";
import FIELD_TYPES from "../../configs/fieldTypes";
import { checkAccessOnObject } from "../../utils/rights";
import { connect } from "../StateProvider";
import TimerLabel from "./Timer/TimerLabel";
import AutoRefreshButton from "./Timer/AutoRefreshButton";

import styles from "./records.less";
import cn from "classnames";


class RecordsActivities extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      dropDownButton: false
    };
  }
  static propTypes = {
    catalog: PropTypes.object,
    scene: PropTypes.object,
    viewId: PropTypes.string,
    sceneId: PropTypes.string
  };

  /* действие для выполнения обновления данных по флагу shouldReload */
  btnRefresh = () => {
    const { sceneId, catalogId } = this.props;
    recordActions.setShouldReload(sceneId, true);
    recordActions.setTimeUntilTimerEnd(sceneId, Date.now() + 10 * 60 * 1001);
    /*     const viewId = this.props.viewId;
    recordActions.requestForRecordsImmediate(
      catalogId,
      sceneId,
      { viewId: viewId },
      this.props.viewMode
    ); */
  };

  btnExport = e => {
    const { viewId, sceneId } = this.props;
    const fields = this.calcFieldsToRender();

    recordActions.requestForExportRecords({ sceneId, viewId }, { fields });
  };

  btnImport = e => {
    const file = e.file;
    const { catalogId, sectionId, sceneId } = this.props;

    // console.log(e);
    // e.file.onPropgress
    // e.file.onSuccess
    if (file) {
      renderModaltoBody(ImportData, { file, catalogId, sectionId, sceneId });
    } else {
      message.error("failed to load data");
    }
  };

  btnRecordsBatchUpdate = (e) => {
    const { catalogId, sectionId, scene, viewId } = this.props;
    const parentSceneId = scene.get("sceneId");

    let params = {
      catalogId,
      sectionId,
      viewId
    };

    this.setState({ dropDownButton: false });
    sceneActions.openRecordsBatchUpdate(parentSceneId, params);
  };

  btnRecordsBatchDelete = (e) => {
    const { catalogId, sectionId, scene, t, recordsCount, viewId } = this.props;
    const parentSceneId = scene.get("sceneId");

    let params = {
      catalogId,
      sectionId,
      viewId
    };

    confirm({
      headerText: t("batchDeleteRecords.confirmation.headerText"),

      text: t("batchDeleteRecords.confirmation.text") + " " +
        t("record.groupRecords.count", { count: +recordsCount }) + "?",
      okText: t("batchDeleteRecords.confirmation.okText"),
      cancelText: t("batchDeleteRecords.confirmation.cancelText"),
      onOk: () => {
        this.setState({ dropDownButton: false })
        sceneActions.openRecordsBatchDelete(parentSceneId, params);
      }
    });
  };

  /* формирование списка полей, в случае отсутствия в списке, добавляется в начало */
  calcFieldsToRender = () => {
    // set ordering by default.
    const { userSettingsfields, catalog, fieldsOrder } = this.props;
    const _fieldsOrder = fieldsOrder ? fieldsOrder.toJS() : [];

    let fields = catalog.get("fields");
    let fieldsToRender = [];

    if (fields) {
      fields.forEach(field => {
        const type = field.get("type");
        const colId = field.get("id");
        let visible =
          userSettingsfields &&
          userSettingsfields.getIn([colId, "visible", "visible"]);
        visible = visible === undefined ? true : visible;

        if (!visible || type === FIELD_TYPES.GROUP) {
          return;
        }

        fieldsToRender.push(colId);
      });

      fieldsToRender = fieldsToRender
        .map(field => {
          // get index from collection
          const index = _fieldsOrder.indexOf(field);
          return {
            field: field,
            order: index !== -1 ? index : 9999
          };
        })
        // apply orderFields from user settings.
        .sort((f1, f2) => f1.order - f2.order)
        .map(o => o.field);
    }

    return fieldsToRender;
  };

  btnAddRecord = () => {
    const {
      createInPopup,
      catalogId,
      viewId,
      sceneId,
      history,
      location,
      openRecordsInModal
    } = this.props;
    if (createInPopup || openRecordsInModal) {
      sceneActions.openNewRecord({ catalogId, viewId, parentSceneId: sceneId });
    } else {
      history.push({
        pathname: `${getLink(location, routes.records).pathname}/$new`,
        search: location.search
      });
    }
  };

  showTimerAutoUpdate = (e) => {
    e.stopPropagation();
    userSettingsActions.setOption({
      catalogId: this.props.catalogId,
      viewMode: "table",
      option: "timer",
      value: !this.props.userSettingsTimer
    });
  };

  getDropDownButtons = () => {
    const { catalog, viewId, recordsCount, t, sceneViews, catalogView } = this.props;
    const views = catalogView
      ? catalogView.merge(sceneViews) // .merge(sceneViews)
      : sceneViews;
    const view = views && views.get(viewId);

    const isAccessEditRecordAtViews =
      views &&
      views.find(v =>
        checkAccessOnObject(RESOURCE_TYPES.VIEW, v, PRIVILEGE_CODES.EDIT)
      );

    let dropDownButtonItems = [];

    // export button for view
    /* ----------------------------------------------------------------------- */

    const isAccessExportRecordsAtView =
      view &&
      checkAccessOnObject(RESOURCE_TYPES.VIEW, view, PRIVILEGE_CODES.EXPORT);

    const isAccessExportRecordsAtCatalog = checkAccessOnObject(
      RESOURCE_TYPES.CATALOG,
      catalog,
      PRIVILEGE_CODES.EXPORT
    );

    if (isAccessExportRecordsAtCatalog || isAccessExportRecordsAtView) {
      dropDownButtonItems = [
        {
          text: t("buttons.export"),
          icon: "files-48",
          onClick: this.btnExport
        }
      ].concat(dropDownButtonItems);
    }

    /* ----------------------------------------------------------------------- */

    // import button
    /* ----------------------------------------------------------------------- */

    const isAccessImportRecordsAtView =
      view &&
      checkAccessOnObject(RESOURCE_TYPES.VIEW, view, PRIVILEGE_CODES.EXPORT);

    const isAccessImportRecordsAtCatalog = checkAccessOnObject(
      RESOURCE_TYPES.CATALOG,
      catalog,
      PRIVILEGE_CODES.EXPORT
    );

    if (isAccessImportRecordsAtView || isAccessImportRecordsAtCatalog) {
      const extensions = [".xlsx"];
      const mimeTypes =
        _.map(extensions, extension => mime.lookup(extension)) || [];
      const acceptedTypes = [...mimeTypes, ...extensions].join(", ");
      dropDownButtonItems = [
        {
          text: t("buttons.import"),
          icon: "files-44",
          import: true,
          acceptedTypes: acceptedTypes /* форматы допустимых к загрузке файлов */,
          onClick: this.btnImport
        }
      ].concat(dropDownButtonItems);
    }

    // batchDelete button
    /* ----------------------------------------------------------------------- */

    if (isAccessImportRecordsAtView || isAccessImportRecordsAtCatalog) {
      dropDownButtonItems = [
        {
          text:
            t("batchDeleteRecords.buttons.delete") +
            t("record.groupRecords.count", { count: +recordsCount || 0 }),
          icon: "edition-43",
          onClick: this.btnRecordsBatchDelete,
          disabled: !Number(recordsCount)
        }
      ].concat(dropDownButtonItems);
    }

    // batchUpdate button
    /* ----------------------------------------------------------------------- */

    if (
      isAccessImportRecordsAtView ||
      isAccessImportRecordsAtCatalog ||
      isAccessEditRecordAtViews
    ) {
      dropDownButtonItems = [
        {
          text:
            t("batchUpdateRecords.buttons.update") +
            t("record.groupRecords.count", { count: +recordsCount || 0 }),
          icon: "edition-30",
          onClick: this.btnRecordsBatchUpdate,
          disabled: !Number(recordsCount)
        }
      ].concat(dropDownButtonItems);
    }

    /* ----------------------------------------------------------------------- */

    dropDownButtonItems = [
      {
        text: <TimerLabel scene={this.props.scene} catalogId={this.props.catalogId} />,
        onClick: this.showTimerAutoUpdate
      }
    ].concat(dropDownButtonItems);

    const dropdownMenu =
      dropDownButtonItems.map((item, i) => {
        if (item.import) {
          return {
            key: i,
            className: styles.dropDownButton,
            label: (
              <Upload
                customRequest={item.onClick}
                accept={item.acceptedTypes}
                showUploadList={false}
                className={styles.upload}
              >
                {item.icon ? (
                  <Icon
                    type={"icon " + item.icon}
                    className={styles.settingIcon}
                  />
                ) : null}
                {item.text}
              </Upload>
            )
          };
        } else {
          return {
            key: i,
            className: cn({ [styles.dropDownButton]: !item.disabled }),
            disabled: item.disabled,
            label: (
              <a onClick={item.onClick}>
                {item.icon ? (
                  <Icon
                    type={"icon " + item.icon}
                    className={styles.settingIcon}
                  />
                ) : null}
                {item.text}
              </a>
            )
          };
        }

      });

    return dropdownMenu;
  };

  // create record button
  getCreateButton = () => {
    const { catalog, catalogView, sceneViews } = this.props;
    const views = catalogView
      ? catalogView.merge(sceneViews) // .merge(sceneViews)
      : sceneViews;

    const isAccessCreateRecordAtCatalog = checkAccessOnObject(
      RESOURCE_TYPES.CATALOG,
      catalog,
      PRIVILEGE_CODES.CREATE
      // PRIVILEGE_CODES.EDIT
    );

    const isAccessCreateRecordAtViews =
      views &&
      views.find(v =>
        checkAccessOnObject(RESOURCE_TYPES.VIEW, v, PRIVILEGE_CODES.CREATE)
      );

    if (isAccessCreateRecordAtCatalog || isAccessCreateRecordAtViews) {
      return {
        text: this.props.t("buttons.add"),
        onClick: this.btnAddRecord
      };
    }
  };

  handleOpenMenu = (open) => {
    this.setState({ dropDownButton: open });
  };

  render() {
    const { scene, onClose, withCross } = this.props;

    const dropDownButtonItems = this.state.dropDownButton ? this.getDropDownButtons() : [];
    const createButton = this.getCreateButton();

    return (
      <Row >
        <AutoRefreshButton
          scene={scene}
          btnRefresh={this.btnRefresh}
          catalogId={this.props.catalogId}
        />
        {createButton ?
          (<> <Button
            onClick={this.btnRefresh}
            title={this.props.t("buttons.refresh")}
          >
            <LoadingSpin
              spin={scene && scene.get("loading")}
              className={styles.buttonRefresh}
            />
          </Button>
            <Dropdown.Button
              trigger={["click"]}
              type="primary"
              overlayClassName={styles.dropDownMenu}
              onClick={createButton.onClick}
              onOpenChange={this.handleOpenMenu}
              menu={{ items: dropDownButtonItems }}
              style={{ marginLeft: "10px" }}
              icon={<DownOutlined />}
            >
              <Icon type="icon interface-72" />
              {createButton.text}
            </Dropdown.Button> </>) :
          (<Dropdown.Button
            trigger={["click"]}
            onClick={this.btnRefresh}
            onOpenChange={this.handleOpenMenu}
            menu={{ items: dropDownButtonItems }}
            style={{ marginLeft: "10px" }}
            icon={<DownOutlined />}
          >
            <LoadingSpin
              spin={scene && scene.get("loading")}
              className={styles.buttonRefresh}
            />
          </Dropdown.Button>)}
        {withCross && <ButtonClose onClick={onClose} shiftRight />}
      </Row>
    );
  }
}
export default withTranslation()(
  connect(
    RecordsActivities,
    {
      userSettings: ["userSettings", "catalogs"],
      scenes: ["scenes"]
    },
    (props, { userSettings, scenes }) => {
      const catalogId = props.catalog.get("id");
      const sectionId = props.catalog.get("sectionId");
      const scene = scenes.get(props.sceneId);
      const recordsCount = scene && scene.get("recordsCount");
      const sceneViews = scene && scene.get("views");
      return {
        fieldsOrder: userSettings.getIn([
          catalogId,
          "viewMode",
          "table",
          "fieldsOrder",
          "fieldsOrder"
        ]),
        userSettingsfields: userSettings.getIn([
          catalogId,
          "viewMode",
          "table",
          "fields"
        ]),
        userSettingsTimer: userSettings.getIn([
          catalogId,
          "viewMode",
          "table",
          "options",
          "timer",
          "value"
        ]),
        scene,
        catalogView: props.catalog.get("views"),
        sceneViews,
        catalogId,
        sectionId,
        recordsCount,
        ...props,
        match: null,
      };
    }
  )
);
