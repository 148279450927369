import React, { useEffect } from "react";
import queryString from "query-string";

import PageHeaders from "./PageHeaders";
import routes from "../../routes";
import NavRoute from "../common/router/Route";
import { connect } from "../StateProvider";
import WebForm from "../WebForm";
import AppBody from "./AppBody";
import userSettingsActions from "../../actions/userSettingsActions";

const getParams = queryString.parse(window.location.search);

const LayoutClient = ({ background }) => {

  useEffect(() => {
    userSettingsActions.getKey("ui");
    userSettingsActions.getKey("background");
  }, []);

  if (getParams.action) {
    const __html = `
      #root {
        background: transparent;
        transition: 0s;
      }
      #root:before {
        content: none;
      }
      `;
    return (
      <React.Fragment>
        <style dangerouslySetInnerHTML={{ __html }} />
        <WebForm {...getParams} />
      </React.Fragment>
    );
  } else {
    const __html = `
      #root {
        background: ${background};
      }`;
    return (
      <React.Fragment>
        <style dangerouslySetInnerHTML={{ __html }} />
        <NavRoute route={routes.catalog} component={PageHeaders} />
        <AppBody />
      </React.Fragment>
    );
  }
};

export default connect(
  LayoutClient,
  {
    background: ["userSettings", "background"]
  }
);
