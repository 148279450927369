import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Immutable from "immutable";
import { withTranslation } from "react-i18next";
import { Alert } from "antd";

import Filters from "../../Filters";
import FIELD_TYPES from "../../../configs/fieldTypes";

import styles from "./propertyPanel.less";
import filtersUtils from "../../../utils/filters";

/*
const availableFilters = [
  FIELD_TYPES.DATE
];
*/
const availableFilters = [
  FIELD_TYPES.GROUP,
  FIELD_TYPES.DROPDOWN,
  FIELD_TYPES.SWITCH,
  FIELD_TYPES.BUTTON,
  FIELD_TYPES.CHECKBOXES,
  FIELD_TYPES.RADIOBUTTON,
  FIELD_TYPES.USER,
  FIELD_TYPES.OBJECT,
  FIELD_TYPES.FILE,
  FIELD_TYPES.NUMBER,
  FIELD_TYPES.PROGRESS,
  FIELD_TYPES.STARS
];

class Visibility extends PureComponent {
  static propTypes = {
    catalog: PropTypes.object.isRequired,
    field: PropTypes.object
  };

  state = {
    fields: new Immutable.Map(),
    hasUnsaved: false,
    hasUnselectedResourses: false,
    filters: new Immutable.Map()
  };

  prepareData(field, catalog) {
    if (!field) {
      return;
    }

    // fields list
    let hasUnsaved = false;
    let hasUnselectedResourses = false;
    let fields = catalog.get("fields");
    fields =
      fields &&
      fields
        .filter(f => {
          // allow only available for filter fields
          const type = f.get("type");
          if (!availableFilters.includes(type)) {
            return false;
          }

          const fieldIsGroup =
            field.get("type") && field.get("type") == FIELD_TYPES.GROUP;
          const fieldIsTheSame =
            field.get("id") && field.get("id") == f.get("id");

          // do not allow same field
          if (!fieldIsGroup && fieldIsTheSame) {
            return false;
          }

          // do not allow new fields
          if (!f.get("id")) {
            hasUnsaved = true;
            return false;
          }

          // do not allow fields with new items
          let hasUnsavedItems = false;
          switch (type) {
            case FIELD_TYPES.DROPDOWN:
            case FIELD_TYPES.BUTTON:
            case FIELD_TYPES.CHECKBOXES:
            case FIELD_TYPES.RADIOBUTTON:
              const items = f.getIn(["config", "items"]);
              items &&
                items.forEach(i => {
                  if (!i.get("id")) {
                    hasUnsaved = true;
                    hasUnsavedItems = true;
                  }
                });
              break;

            case FIELD_TYPES.OBJECT:
              const catalogs = f.getIn(["config", "catalogs"]);
              const views = f.getIn(["config", "views"]);
              const emptyRecource =
                (!catalogs || !catalogs.size) && (!views || !views.size);
              if (emptyRecource) {
                hasUnselectedResourses = true;
                hasUnsavedItems = true;
              }
          }
          if (hasUnsavedItems) {
            return false;
          }

          return true;
        })
        .map(f => {
          f = f.set("filterable", true);
          return f;
        });

    const filters = this.convertFilters(field);

    this.setState({ filters, fields, hasUnsaved, hasUnselectedResourses });
  }

  onSave = (filterId, filterFieldId, value) => {
    let field = this.props.field;

    if (!_.isEmpty(value)) {
      field = field.setIn(["visible", filterFieldId], value);
    } else {
      field = field.deleteIn(["visible", filterFieldId]);
    }
    this.props.onSaveField && this.props.onSaveField(field);
  };

  convertFilters(field) {
    let visible = field.get("visible");
    visible = visible && visible.toJS ? visible.toJS() : visible;

    const filters = Immutable.fromJS(
      filtersUtils.convertFiltersToNewFormat(visible)
    );

    return filters;
  }

  componentDidMount() {
    this.prepareData(this.props.field, this.props.catalog);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newField = nextProps.field && nextProps.field.toJS();
    const field = this.props.field && this.props.field.toJS();

    if (!_.isEqual(newField, field)) {
      this.prepareData(nextProps.field, nextProps.catalog);
    }
  }

  render() {
    const { fields, hasUnsaved, hasUnselectedResourses, filters } = this.state;
    const { field, t } = this.props;
    return (
      <React.Fragment>
        {hasUnsaved ? (
          <Alert
            message={t(
              "catalogEditor.fieldProperties.visibleProperties.needSave.title"
            )}
            description={t(
              "catalogEditor.fieldProperties.visibleProperties.needSave.text"
            )}
            type="warning"
          />
        ) : null}
        {hasUnselectedResourses ? (
          <Alert
            message={t(
              "catalogEditor.fieldProperties.visibleProperties.needSelectResource.title"
            )}
            description={t(
              "catalogEditor.fieldProperties.visibleProperties.needSelectResource.text"
            )}
            type="warning"
          />
        ) : null}
        {fields ? (
          <Filters
            ownerId={field.get("id")}
            catalogId={this.props.catalog.get("id")}
            filters={filters}
            fields={fields}
            onSave={this.onSave}
            className={styles.filtersData}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default withTranslation()(Visibility);
