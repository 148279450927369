import React from "react";
import { Button } from "antd";
import cn from "classnames";
import styles from "./buttonTransparent.less";

const ButtonTransparent = props => {
  return (
    <Button
      onClick={props.onClick}
      onMouseDown={props.onMouseDown}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      className={cn(
        props.className,
        styles.btn,
        props.light ? styles.light : styles.dark
      )}
      title={props.title}
    >
      {props.children}
    </Button>
  );
};

export default ButtonTransparent;
