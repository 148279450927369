import React from "react";
import cn from "classnames";

import CatalogHeader from "./CatalogHeader";
import CatalogBody from "./CatalogBody";
import { connect } from "../../../../../StateProvider";

import styles from "./layoutLeftPanel.less";

const LayoutLeftPanel = props => {
  return (
    <div
      className={cn(styles.container, {
        [styles.containerHidden]: !props.leftPanel
      })}
    >
      <div className={styles.header}>
        <CatalogHeader
          catalog={props.catalog}
          sceneId={props.sceneId}
          history={props.history}
          changeViewMenuVisibility={props.changeViewMenuVisibility}
        />
      </div>
      <CatalogBody
        catalog={props.catalog}
        sceneId={props.sceneId}
        leftPanel={props.leftPanel}
      />
    </div>
  );
};

export default connect(
  LayoutLeftPanel,
  {
    catalogs: ["catalogs"]
  },
  (props, { catalogs }) => {
    const { catalogId } = props;
    const catalog = catalogs.get(catalogId);

    return {
      ...props,
      catalog
    };
  }
);
