import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import cn from "classnames";
import Immutable from "immutable";
import { Select } from "antd";
import { withTranslation } from "react-i18next";

import styles from "./ObjectField.less";

const { Option, OptGroup } = Select;

class SelectFilter extends React.PureComponent {
  static propTypes = {
    editingCatalog: PropTypes.object,
    items: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    placeholder: PropTypes.string
  };

  state = {
    list: []
  };

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  parseValue(value, items) {
    // transform
    // [ {fieldId, value: [{fieldId}] },  ] => [ "fieldId=fieldId", ]

    if (!value) {
      return [];
    }

    // filter existed
    value = value.toJS();

    value = _.map(value, v => {
      const sourceFieldId = v.fieldId;
      const filterFieldId = v.value && v.value[0] && v.value[0].fieldId;

      // deleted filters bt deleted fields
      if (
        !items.find(
          item =>
            item.getIn(["sourceField", "id"]) === sourceFieldId &&
            item.getIn(["filterField", "id"]) === filterFieldId
        )
      ) {
        return null;
      }

      // format
      return sourceFieldId + "=" + filterFieldId;
    });
    value = _.filter(value, v => !!v);
    return value;
  }

  formatValue(value) {
    // transform
    // [ "fieldId=fieldId", ] => [ {fieldId, value: [{fieldId}] },  ]

    if (!value) {
      return Immutable.fromJS([]);
    }

    value = _.map(value, v => {
      const keys = v.split("=");
      const sourceFieldId = keys[0];
      const filterFieldId = keys[1];

      return Immutable.fromJS({
        fieldId: sourceFieldId,
        value: Immutable.fromJS([{ fieldId: filterFieldId }])
      });
    });

    return Immutable.fromJS(value);
  }

  render() {
    const { editingCatalog, items, disabled, onChange } = this.props;
    const value = this.parseValue(this.props.value, items);

    return (
      <div>
        <Select
          className={cn(styles.select, styles.selectFilter, {
            [styles.selectDisabled]: disabled
          })}
          mode="multiple"
          onChange={value => onChange(this.formatValue(value))}
          value={value}
          placeholder={this.props.placeholder}
          disabled={disabled}
          getPopupContainer={() => document.getElementById("fieldListEditor")}
        >
          {items &&
            items.map(item => {
              const sourceCatalog = item.get("sourceCatalog");
              const sourceCatalogTitle =
                sourceCatalog && sourceCatalog.get("name");

              const sourceField = item.get("sourceField");
              const sourceFieldId = sourceField && sourceField.get("id");
              const sourceFieldName = sourceField && sourceField.get("name");

              const filterCatalogTitle = editingCatalog
                ? editingCatalog.get("name") + "."
                : "";

              const filterField = item.get("filterField");
              const filterFieldId = filterField && filterField.get("id");
              const filterFieldName = filterField && filterField.get("name");

              const disabledOption = !filterFieldId;

              let key, title;
              if (sourceCatalog) {
                key = sourceFieldId + "=" + filterFieldId;
                title =
                  sourceCatalogTitle +
                  "." +
                  sourceFieldName +
                  " = " +
                  filterCatalogTitle +
                  filterFieldName;
                if (disabledOption) {
                  title =
                    title +
                    " (" +
                    this.props.t("fieldTypes.object.item.filterNeedSave") +
                    ")";
                }
              } else {
                // emptyvalue
                key = "";
                title = " ";
              }

              return (
                <Option
                  key={key}
                  value={key}
                  title={title}
                  disabled={disabledOption}
                >
                  {title}
                </Option>
              );
            })}
        </Select>
      </div>
    );
  }
}

export default withTranslation()(SelectFilter);
