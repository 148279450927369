import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import cn from "classnames";
import { connect } from "../../../StateProvider";
import apiActions from "../../../../actions/apiActions";
import { Select } from "antd";

import FIELD_TYPES from "../../../../configs/fieldTypes";
import fieldTypeIcons from "../../../../configs/fieldTypeIcons";

import Icon from '../../../common/UI/Icon';

import styles from "./ObjectField.less";

const { Option, OptGroup } = Select;
const getCatalogsOnce = _.once(apiActions.getCatalogs);

// beacuse Symbol can't convert to String(error from React)
const virtualGroupId = "$virtualGroupId";

class SelectFields extends React.PureComponent {
  static propTypes = {
    catalog: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    placeholder: PropTypes.string
  };

  state = {
    sections: [],
    value: []
  };

  componentDidMount() {
    getCatalogsOnce();
    this.setSections(this.props.catalog);
    this.getValue(this.props.value);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newCatalog = nextProps.catalog;
    const catalog = this.props.catalog;

    let newValue = nextProps.value;
    const value = this.props.value;

    if (newCatalog !== catalog) {
      this.setSections(newCatalog);
    }

    if (newValue !== value) {
      this.getValue(newValue);
    }
  }

  getValue(value) {
    value = value ? value.toJS() : [];
    value = value.map(f => f.id);

    this.setState({ value });
  }

  onFilter(inputValue, option) {
    const {
      props: { title }
    } = option;

    if (!inputValue) {
      return;
    }

    return title && title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
  }

  formatValue(value) {
    const catalog = this.props.catalog;
    const fields = [];

    value.map(fieldId => {
      const field =
        catalog && catalog.get("fields").find(f => f.get("id") === fieldId);

      fields.push(field);
    });

    return fields;
  }

  setSections(catalog) {
    const fields = catalog && catalog.get("fields");

    let _curGroup;
    const sections = [];

    fields &&
      fields.forEach(config => {
        if (config.get("type") === FIELD_TYPES.GROUP) {
          _curGroup = {
            id: config.get("id"),
            name: config.get("name"),
            fields: []
          };
          sections.push(_curGroup);
          _curGroup.fields.push(config);
        } else {
          if (!_curGroup) {
            _curGroup = {
              id: virtualGroupId,
              name: catalog.get("name"),
              fields: []
            };
            sections.push(_curGroup);
          }
          _curGroup.fields.push(config);
        }
      });

    this.setState({
      sections: sections
    });
  }

  render() {
    const { disabled, onChange } = this.props;
    const { value, sections } = this.state;
    return (
      <React.Fragment>
        <Select
          className={cn(styles.select, styles.selectFields, {
            [styles.selectDisabled]: disabled
          })}
          filterOption={this.onFilter}
          mode="multiple"
          onChange={value => onChange(this.formatValue(value))}
          value={value}
          placeholder={this.props.placeholder}
          disabled={disabled}
          getPopupContainer={() => document.getElementById("fieldListEditor")}
        >
          {sections.map(section => {
            return (
              <OptGroup key={section.id} label={section.name}>
                {section.fields.map(field => {
                  const fieldId = field.get("id");
                  const fieldName = field.get("name");
                  const fieldType = field.get("type");
                  const fieldIcon = fieldTypeIcons[fieldType];
                  return (
                    <Option key={fieldId} value={fieldId} title={fieldName}>
                      <Icon
                        className={styles.selectIcon}
                        type={"icon " + fieldIcon}
                      />
                      {fieldName}
                    </Option>
                  );
                })}
              </OptGroup>
            );
          })}
        </Select>
      </React.Fragment>
    );
  }
}

export default connect(
  SelectFields,
  ["catalogs"],
  ({ catalogId, ...props }, { catalogs }) => {
    const selectedCatalog = catalogs.find(c => c.get("id") === catalogId);
    return {
      catalog: selectedCatalog,
      ...props
      // допущено предположение, что value не может содержать не выбранных или удаленных каталогов
      // также, value не может сожержать удаленных филдов
      // предполагается, что сервак это все вычистит
    };
  }
);
