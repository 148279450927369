import React from "react";
import PropTypes from "prop-types";
import { Row, Button } from "antd";
import { withTranslation } from "react-i18next";

import TextInput from "../../../common/UI/ControlList/controls/common/TextInput";
import Loading from "../../../common/Loading";
import apiActions from "../../../../actions/apiActions";
import keys from "../../../../configs/keys";

import styles from "./history.less";

class NewComment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      sending: false
    };
  }

  onSend = () => {
    if (this.state.comment && !this.state.sending) {
      this.setState({ sending: true });
      apiActions
        .createHistory(
          {},
          {
            catalogId: this.props.catalogId,
            recordId: this.props.recordId,
            type: "COMMENT",
            payload: {
              message: this.state.comment
            }
          },
          /* дописать  */
          { sceneId: this.props.sceneId }
        )
        .then(() => {
          this.setState({
            comment: "",
            sending: false
          });
        });
    }
  };

  onKeyDown = e => {
    //Ctrl + Enter или ⌘ + Enter
    if ((e.ctrlKey || e.metaKey) && e.keyCode === keys.ENTER) {
      e.preventDefault();
      this.onSend();
    }
  };

  onChange = text => {
    this.setState({
      comment: text
    });
  };

  render() {
    const { t } = this.props;
    let content = null;
    if (this.state.sending) {
      content = <Loading fullHeight={false} />;
    } else {
      content = <div className={styles.newCommentHelp}>Ctrl+Enter</div>;
    }
    return (
      <Row
        type="flex"
        justify="space-between"
        className={styles.commentContainer}
      >
        <div className={styles.fieldHeader}>
          <span className={styles.newCommentTitle}>
            {t("record.history.fieldName")}
          </span>
        </div>
        <div className={styles.fieldBody}>
          <TextInput
            value={this.state.comment}
            placeholder={t("record.history.commentPlaceholder")}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            multiline
            minRows={2}
            wrapperClassName={styles.inputWrapper}
          />
          <div>
            <Button
              disabled={!this.state.comment || this.state.sending}
              onClick={this.onSend}
            >
              {t("record.history.sendComment")}
            </Button>
            {content}
          </div>
        </div>
      </Row>
    );
  }
}

NewComment.propTypes = {
  recordId: PropTypes.string.isRequired,
  catalogId: PropTypes.string.isRequired,
  sceneId: PropTypes.string.isRequired
};

export default withTranslation()(NewComment);
