import React, { Fragment } from "react";
import { matchPath, Route } from "react-router";
import { Redirect } from "react-router-dom";

import routes from "../../routes";
import SetPass from "./SetPass";
import Login from "./Login";
import ResetPass from "./ResetPass";
import Activate from "./Activate";
import Register from "./Register";
import PaymentRequired from "./PaymentRequired";
import Companies from "./Companies";

import AuthHeader from "../common/UI/AuthHeader";

const AuthPage = () => {
  const __html = `{
    #root #base{
      position: relative;
    }
  }`
  return (
    <div id="auth">
      <style dangerouslySetInnerHTML={{ __html }} />
      <AuthHeader />
      <Route
        render={props => {
          const { location } = props;
          const matchCompanies = matchPath(location.pathname, {
            path: routes.companies.path,
            exact: false,
            strict: false
          });
          const matchLogin = matchPath(location.pathname, {
            path: routes.login.path,
            exact: false,
            strict: false
          });
          const matchRegister = matchPath(location.pathname, {
            path: routes.register.path,
            exact: false,
            strict: false
          });
          const matchResetPass = matchPath(location.pathname, {
            path: routes.resetPass.path,
            exact: false,
            strict: false
          });
          const matchSetPass = matchPath(location.pathname, {
            path: routes.setPass.path,
            exact: false,
            strict: false
          });
          const matchActivate = matchPath(location.pathname, {
            path: routes.activate.path,
            exact: false,
            strict: false
          });
          const matchPaymentRequired = matchPath(location.pathname, {
            path: routes.paymentRequired.path,
            exact: false,
            strict: false
          });

          if (matchCompanies) {
            return <Companies {...props} />;
          } else if (matchSetPass) {
            return <SetPass {...props} />;
          } else if (matchLogin) {
            return <Login {...props} />;
          } else if (matchRegister) {
            return <Register {...props} />;
          } else if (matchPaymentRequired) {
            return <PaymentRequired />;
          } else if (matchResetPass) {
            return <ResetPass />;
          } else if (matchActivate) {
            return <Activate />;
          } else {
            return <Redirect to="/" />;
          }
        }}
      />
    </div>
  );
};

export default AuthPage;
