import React, { PureComponent } from "react";
import { Menu as AntMenu, Dropdown } from "antd";
import PropTypes from "prop-types";
import _ from "lodash";
import Immutable from "immutable";
import cn from "classnames";
import { withTranslation } from "react-i18next";

import Icon from '../../../common/UI/Icon';
import modalsActions from "../../../../actions/modalsActions";
import apiActions from "../../../../actions/apiActions";
import uiActions from "../../../../actions/uiActions";
import { promptConfirm, prompt } from "../../../common/Modal";
import AbstractMenu from "../../../common/menu/AbstractMenu";
import { connect } from "../../../StateProvider";

import PRIVILEGE_CODES from "../../../../configs/privilegeCodes";
import RESOURCE_TYPES from "../../../../configs/resourceTypes";
import { checkAccessOnObject } from "../../../../utils/rights";

import styles from "./sectionHeader.less";

const log = require("debug")("CRM:Component:Sidebar:ButtonAfterCatalogs");

class SectionHeader extends PureComponent {
  static propTypes = {
    section: PropTypes.object,
    isAccessAdmin: PropTypes.bool.isRequired
  };

  onClickAccess = () => {
    const sectionId = this.props.section.get("id");
    if (sectionId) {
      const isAdmin = checkAccessOnObject(
        RESOURCE_TYPES.SECTION,
        this.props.section,
        PRIVILEGE_CODES.ADMIN
      );
      const readOnly = !checkAccessOnObject(
        RESOURCE_TYPES.SECTION,
        this.props.section,
        PRIVILEGE_CODES.ACCESS
      );
      const object = { sectionId };
      modalsActions.openAccessModal(
        { object },
        RESOURCE_TYPES.SECTION,
        { readOnly, isAdmin },
        true
      );
    }
  };

  openRenameModal = () => {
    const { t } = this.props;
    prompt({
      headerText: t("modals.changeSectionName.headerText"),
      value: this.props.section.get("name"),
      onOk: this.onRename,
      okText: t("buttons.save"),
      cancelText: t("buttons.cancel")
    });
  };

  onRename = newName => {
    apiActions.updateSection(
      {
        sectionId: this.props.section.get("id")
      },
      {
        name: newName
      }
    );
  };

  openRemoveConfirm = () => {
    const { t } = this.props;
    const sectionName = this.props.section.get("name");

    promptConfirm({
      value: sectionName,
      placeHolder: sectionName,
      headerText: t("modals.removeSectionConfirm.headerText"),
      text: (
        <span>
          {t("modals.removeSectionConfirm.firstText")}
          <br />
          {t("modals.removeSectionConfirm.secondText")}
        </span>
      ),
      okText: t("modals.removeSectionConfirm.okText"),
      cancelText: t("modals.removeSectionConfirm.cancelText"),
      onOk: () =>
        apiActions
          .deleteSection({
            sectionId: this.props.section.get("id")
          })
          .then(() => {
            this.props.history.replace({
              pathname: "/",
              search: this.props.location ? this.props.location.search : ""
            });
          })
    });
  };

  fixedSidebar = () => {
    uiActions.sidebarMenuFixing(!this.props.sidebarMenu.get("fixed"));
  };

  render() {
    const { t } = this.props;
    const menuFixed = this.props.sidebarMenu.get("fixed");

    let menu = [
      {
        key: 1,
        label: (
          <a onClick={this.onClickAccess}>
            <Icon type={"icon edition-55"} className={styles.settingIcon} />
            {t("buttons.accessSection")}
          </a>
        )
      },
    ];  

    if (this.props.isAccessAdmin) {
      const adminItemsMenu = [
        {
          key: 2,
          label: (
            <a onClick={this.openRenameModal}>
              <Icon
                type={"icon edition-33"}
                className={styles.settingIcon}
              />
              {t("buttons.renameSection")}
            </a>)
        },
        {
          key: 3,
          label: (
            <a
              onClick={this.openRemoveConfirm}
              className={styles.settingRemove}
            >
              <Icon
                type={"icon edition-41"}
                className={styles.settingIcon}
              />
              {t("buttons.removeSection")}
            </a>)
        }
      ];

      menu = [...menu, ...adminItemsMenu];
    }

    const sectionProperty = (
      <div key="property" className={styles.item}>
        <Icon className={cn(styles.icon)} type="icon setting-10" />
        <span className={styles.text}>{t("section.menu.property")}</span>
      </div>
    );

    let items = [];

    items.push(
      <Dropdown key="dropdown" menu={{items: menu}} trigger={["click"]}>
        {sectionProperty}
      </Dropdown>
    );

    items.push(
      <div
        key="fixedSidebar"
        className={styles.item}
        onClick={this.fixedSidebar}
      >
        <Icon
          className={cn(styles.icon)}
          type={`icon ${menuFixed ? "text-41" : "text-42"}`}
        />
        <span className={styles.text}>
          {menuFixed
            ? t("section.menu.fixedMenuCollapse")
            : t("section.menu.fixedMenuExpand")}
        </span>
      </div>
    );

    return (
      <AbstractMenu
        items={Immutable.List(items)}
        direction="column-reverse"
        vertical={{
          menu: styles.menu
        }}
      />
    );
  }
}

export default withTranslation()(
  connect(
    SectionHeader,
    {
      sidebarMenu: ["ui", "sidebarMenu"]
    }
  )
);
