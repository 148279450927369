// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body .table__table___4OlDw .public_fixedDataTable_header,\nbody .table__table___4OlDw .public_fixedDataTableCell_main {\n  background-color: transparent;\n}\nbody .table__table___4OlDw .fixedDataTableRowLayout_main:hover.public_fixedDataTable_header {\n  background-color: transparent;\n}\nbody .table__table___4OlDw .public_fixedDataTableCell_cellContent {\n  display: block;\n  padding: 0 2px 0 10px;\n}\nbody .table__table___4OlDw .table__labelsColumnCell___rsxyq .public_fixedDataTableCell_cellContent {\n  padding-left: 15px;\n}\n.table__labelTotal___apupp {\n  color: black;\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Reports/Board/Widget/chart/table.less"],"names":[],"mappings":"AAII;;EAGI,6BAAA;AAJR;AACI;EAMI,6BAAA;AAJR;AAFI;EASI,cAAA;EACA,qBAAA;AAJR;AASM;EAEI,kBAAA;AARV;AAeA;EACE,YAAA;EACA,iBAAA;AAbF","sourcesContent":["@import (reference) \"../../../../../styles/helpers/index.less\";\n\nbody {\n  .table {\n    & :global {\n      .public_fixedDataTable_header,\n      .public_fixedDataTableCell_main {\n        background-color: transparent;\n      }\n      .fixedDataTableRowLayout_main:hover.public_fixedDataTable_header {\n        background-color: transparent;\n      }\n      .public_fixedDataTableCell_cellContent {\n        display: block;\n        padding: 0 2px 0 @PADDING_BASE;\n      }\n    }\n\n    .labelsColumnCell {\n      & :global {\n        .public_fixedDataTableCell_cellContent {\n          padding-left: @PADDING_BASE*1.5;\n        }\n      }\n    }\n  }\n}\n\n.labelTotal {\n  color: black;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "table__table___4OlDw",
	"labelsColumnCell": "table__labelsColumnCell___rsxyq",
	"labelTotal": "table__labelTotal___apupp"
};
export default ___CSS_LOADER_EXPORT___;
