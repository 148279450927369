import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import cn from "classnames";
import { withTranslation } from "react-i18next";
import Immutable from "immutable";
import { Tag } from "antd";
import tinycolor from "tinycolor2";

import styles from "./controls.less";

function getValuesMap(values) {
  var map = {};

  Immutable.fromJS(values || []).forEach(v => {
    v = v.toJS ? v.toJS() : v;
    map[v] = true;
  });

  return Immutable.fromJS(map);
}

class DropdownField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      values: getValuesMap(this.props.value)
    };
  }

  onClickItem = itemId => {
    const { onSave, fieldId, readOnly } = this.props;

    if (readOnly) {
      return;
    }

    let newValues = this.state.values;

    newValues = newValues.set(itemId, !this.state.values.get(itemId));

    this.setState({ values: newValues });

    let values = [];
    newValues.forEach((v, id) => {
      if (v) {
        values.push(id);
      }
    });

    onSave(fieldId, values);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!Immutable.is(nextProps.value, this.props.value)) {
      this.setState({
        values: getValuesMap(nextProps.value)
      });
    }
  }

  render() {
    // get deleted values from
    let deletedValues = null;
    const { t } = this.props;

    if (this.state.values) {
      let idsFromFilter = this.state.values.keySeq().toArray();
      idsFromFilter = idsFromFilter.filter(i => i !== "$EMPTY");

      let idsFromFieldConfig = this.props.config
        .get("items")
        .map(item => item.get("id"))
        .toArray();

      deletedValues = _.difference(idsFromFilter, idsFromFieldConfig).map(
        id => {
          return (
            <Tag
              key={id}
              onClick={_.bind(this.onClickItem, this, id)}
              className={cn(styles.tags, styles.tagDeleted)}
            >
              {t("filter.field.removed")}
            </Tag>
          );
        }
      );
    }

    let items = this.props.config.get("items") || Immutable.fromJS([]);
    items = items.push(
      Immutable.fromJS({
        id: "$EMPTY",
        name: t("filter.undefined"),
        color: "ffffff"
      })
    );

    return (
      <div className={styles.tagsContainer}>
        {items.map(item => {
          const id = item.get("id");
          const selected = this.state.values.get(id);
          const backgroundColor = "#" + item.get("color");
          const color = tinycolor(backgroundColor)
            .darken(65)
            .toString();
          return (
            <Tag
              key={id}
              onClick={_.bind(this.onClickItem, this, id)}
              style={
                selected
                  ? {
                    backgroundColor: backgroundColor,
                    border: "1px solid rgba(0,0,0,0.1)",
                    color: color
                  }
                  : { backgroundColor: "transparent" }
              }
              className={cn(styles.tags, { readonly: this.props.readOnly })}
            >
              {item.get("name")}
            </Tag>
          );
        })}
        {deletedValues}
      </div>
    );
  }
}

DropdownField.propTypes = {
  value: PropTypes.object,
  config: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
};

export default withTranslation()(DropdownField);
