import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "antd";
import { withTranslation } from "react-i18next";

import editorActions from "../../../actions/editorActions";

import styles from "./fields.less";

class FileField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      multiselect: !!this.props.field.getIn(["config", "multiselect"])
    };
  }

  onChangeMultiselect = e => {
    var val = e.target.checked;
    this.setState({
      multiselect: val
    });
    editorActions.changeFieldConfig(
      this.props.sectionId,
      this.props.fieldIndex,
      {
        multiselect: val
      }
    );
  };

  render() {
    return (
      <div>
        <Checkbox
          className={styles.checkboxInline}
          disabled={this.props.disabled}
          name="multiselect"
          checked={this.state.multiselect}
          onChange={this.onChangeMultiselect}
          title={this.props.t("fieldTypes.file.multiselectHint")}
        >
          {this.props.t("fieldTypes.file.multiselect")}
        </Checkbox>
      </div>
    );
  }
}

FileField.propTypes = {
  field: PropTypes.object.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  sectionId: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default withTranslation()(FileField);
