import React from "react";
import PropTypes from "prop-types";
import editorActions from "../../../actions/editorActions";
import { Input, Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import TextInput from "../../common/UI/ControlList/controls/common/TextInput";

class NumberField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      min: this.props.field.getIn(["config", "min"]),
      max: this.props.field.getIn(["config", "max"]),
      unit: this.props.field.getIn(["config", "unit"])
    };
  }

  onChangeMin = value => {
    this.onChange("min", value);
  };
  onChangeMax = value => {
    this.onChange("max", value);
  };
  onChangeUnit = e => {
    const value = e.target.value || "";
    this.onChange("unit", value);
  };
  onChange = (param, value) => {
    this.setState(
      {
        [param]: value
      },
      () => {
        editorActions.changeFieldConfig(
          this.props.sectionId,
          this.props.fieldIndex,
          {
            min: this.state.min,
            max: this.state.max,
            unit: this.state.unit
          }
        );
      }
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      min: nextProps.field.getIn(["config", "min"]),
      max: nextProps.field.getIn(["config", "max"]),
      unit: nextProps.field.getIn(["config", "unit"])
    });
  }

  render() {
    const colsConfig = {
      md: 7,
      lg: 7,
      xl: 6,
      xxl: 5
    };
    const { t } = this.props;

    return (
      <Row gutter={[24, 16]}>
        <Col {...colsConfig} style={{ paddingBottom: 0 }}>
          <TextInput
            type="number"
            disabled={this.props.disabled}
            value={this.state.min}
            onChange={this.onChangeMin}
            placeholder={t("fieldTypes.number.min.placeholder")}
            style={{ width: "100%" }}
          />
        </Col>
        <Col {...colsConfig} style={{ paddingBottom: 0 }}>
          <span
            style={{ float: "left", marginLeft: "-18px", marginTop: "5px" }}
          >
            —
          </span>
          <TextInput
            type="number"
            disabled={this.props.disabled}
            value={this.state.max}
            onChange={this.onChangeMax}
            placeholder={t("fieldTypes.number.max.placeholder")}
            style={{ width: "100%" }}
          />
        </Col>
        <Col {...colsConfig} xl={10} md={10} lg={10} xxl={8} style={{ paddingBottom: 0 }}>
          <Input
            type="text"
            disabled={this.props.disabled}
            value={this.state.unit}
            onChange={this.onChangeUnit}
            placeholder={t("fieldTypes.number.unit.placeholder")}
          />
        </Col>
      </Row>
    );
  }
}

NumberField.propTypes = {
  field: PropTypes.object.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  sectionId: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default withTranslation()(NumberField);
