import _ from "lodash";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";

import sortSections from "./helpers/sortSections.js";
import SectionItem from "./SectionItem";
import PopoverLayout from "./PopoverLayout";
import { SEARCH_VISIBLE_COMPANY_COUNT } from "./helpers/const";
import { checkAccessOnObject } from "../../../../utils/rights";
import RESOURCE_TYPES from "../../../../configs/resourceTypes";
import PRIVILEGE_CODES from "../../../../configs/privilegeCodes";
import userSettingsActions from "../../../../actions/userSettingsActions";

const PopoverContent = props => {
  let {
    catalogs,
    company,
    sections,
    companies,
    favoriteCatalogs,
    openCreateModal,
    hidePopover,
    visible,
    handleClickCreacteCompany
  } = props;

  const showSideSearch = companies.size > SEARCH_VISIBLE_COMPANY_COUNT;

  const [showSidebarHeader, setShowSidebarHeader] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredCompanyItems, setFilteredCompanyItems] = useState([]);
  const [searchCompanyText, setSearchCompanyText] = useState("");
  // Can add new sections
  const [sectionsAccess, setSectionsAcces] = useState(false);

  const checkSectionsAccess = _sections => {
    const adminSection = _sections.find(section => {
      return Number(section.get("id")) === 1;
    });

    if (adminSection) {
      const canAdd = checkAccessOnObject(
        RESOURCE_TYPES.SECTION,
        adminSection,
        PRIVILEGE_CODES.ADMIN
      );
      return canAdd;
    }
    return false;
  };

  useEffect(() => {
    if (!favoriteCatalogs) {
      favoriteCatalogs = Immutable.List();
    }

    const canAdd = checkSectionsAccess(sections);
    setSectionsAcces(canAdd);
  }, []);

  useEffect(
    () => {
      const canAdd = checkSectionsAccess(sections);
      setSectionsAcces(canAdd);
    },
    [sections]
  );

  // Filter companies by search input
  useEffect(
    () => {
      companies = companies.filter(_company => {
        const name = _company.get("title");
        const domain = _company.get("domain");
        if (!name || !domain) {
          return true;
        }

        return (
          name.toLowerCase().includes(searchCompanyText.toLowerCase()) ||
          domain.toLowerCase().includes(searchCompanyText.toLowerCase())
        );
      });

      setFilteredCompanyItems(companies, searchCompanyText);
    },
    [companies, searchCompanyText]
  );

  useEffect(
    () => {
      catalogs = catalogs.filter(catalog => {
        const name = catalog.get("name");
        //проверка на то, чтобы права были больше, чем "available"
        //если права уровня "available" и ниже, то скрываем саталог
        const canShow = checkAccessOnObject(
          RESOURCE_TYPES.CATALOG,
          catalog,
          PRIVILEGE_CODES.SEARCH
        );

        if (canShow && name) {
          return name.toLowerCase().includes(searchText.toLowerCase());
        }
      });

      if (showSideSearch) {
        setShowSidebarHeader(true);
      }

      const catalogIds = catalogs.map(catalog => catalog.get("sectionId"));

      sections = sections.filter(section => {
        return catalogIds.includes(section.get("id"));
      });

      // Sort sections using priority system
      sections = sortSections(sections);

      const items = getSectionItems(sections, catalogs);
      setFilteredItems(items);
    },
    [catalogs, searchText, searchCompanyText, favoriteCatalogs, sections]
  );

  const onSearchChange = e => {
    setSearchText(e.target.value);
  };

  const onCompanySearchChange = e => {
    setSearchCompanyText(e.target.value);
  };

  const onFavoriteClick = catalogId => {
    if (_.isUndefined(favoriteCatalogs)) {
      favoriteCatalogs = Immutable.List();
    }

    const index = favoriteCatalogs ? favoriteCatalogs.indexOf(catalogId) : -1;

    /**
     * Depends on item existance in the favorite array
     * delete or push item using index
     */
    favoriteCatalogs =
      index !== -1
        ? favoriteCatalogs.splice(index, 1)
        : favoriteCatalogs.push(catalogId);

    userSettingsActions.setKey(["ui", "favoriteCatalogs"], favoriteCatalogs);
  };

  const getSectionItems = (sections, catalogs) => {
    let sectionItems = [];

    sections.forEach((section, index) => {
      const sectionId = section.get("id");

      // Sort catalogs
      const sectionCatalogs = catalogs
        .sortBy(c => c.get("index"))
        .filter(item => item.get("sectionId") === sectionId);

      // Add section items
      sectionItems.push(
        <SectionItem
          key={index}
          onFavoriteClick={onFavoriteClick}
          sectionCatalogs={sectionCatalogs}
          favoriteCatalogs={favoriteCatalogs}
          hidePopover={hidePopover}
          section={section}
        />
      );
    });
    return sectionItems;
  };

  return (
    <PopoverLayout
      onSearchChange={onSearchChange}
      onCompanySearchChange={onCompanySearchChange}
      sectionsAccess={sectionsAccess}
      showSideSearch={showSideSearch}
      hidePopover={hidePopover}
      company={company}
      companies={companies}
      searchText={searchText}
      searchCompanyText={searchCompanyText}
      filteredItems={filteredItems}
      filteredCompanyItems={filteredCompanyItems}
      openCreateModal={openCreateModal}
      visible={visible}
      showSidebarHeader={showSidebarHeader}
      handleClickCreacteCompany={handleClickCreacteCompany}
    />
  );
};

PopoverContent.propTypes = {
  catalogs: PropTypes.object,
  company: PropTypes.object,
  sections: PropTypes.object,
  companies: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  favoriteCatalogs: PropTypes.object,
  openCreateModal: PropTypes.func,
  hidePopover: PropTypes.func,
  visible: PropTypes.bool,
  handleClickCreacteCompany: PropTypes.func
};

export default PopoverContent;
