import _ from "lodash";
import Immutable from "immutable";

export default {
  setUrlItems(items) {
    const urlItems = this.get("calendar") ? this.get("calendar").toJS() : {};
    const newUrlItems = _.merge(urlItems, items);
    this.set("calendar", Immutable.fromJS(newUrlItems));
    this.changed();
  },

  moveRecordFromTo(fromSceneId, toSceneId, record) {
    const fromScene = this.getIn(["scenes", fromSceneId]);
    const toScene = this.getIn(["scenes", toSceneId]);

    if (!fromScene || !toScene) {
      return console.warn(
        "Error while dragging record to calendar",
        !fromScene ? "fromScene" : "toScene",
        " are Empty"
      );
    }

    if (!record) {
      return console.warn(
        "Error while dragging record to calendar. Event is empty"
      );
    }

    /* работа с источником данных */
    let sourceRecords = fromScene.get("records");

    if (!sourceRecords) return;
    const sourceRecord = sourceRecords.find(
      r => r.get("id") == record.get("id")
    );

    /* конец работы с источником данных */

    /* работа с таргетом данных */
    let targetRecords = toScene.get("records");
    const targetRecordKey = String(targetRecords.size);
    this.setIn(["scenes", toSceneId, "records", targetRecordKey], sourceRecord);

    /* конец работы с таргетом данных */

    this.changed();
  },

  deleteRecordFromScene(sceneId, recordId) {
    const records = this.getIn(["scenes", sceneId, "records"]);
    if (!records) return;

    const recordKey = records.findKey(r => r.get("id") == recordId);

    if (recordKey < 0) return;

    this.deleteIn(["scenes", sceneId, "records", recordKey]);
    this.changed();
  },

  updateRecordBeforeMakeRequest(params = {}, values, sceneId) {
    const records = this.getIn(["scenes", sceneId, "records"]);

    if (!records || !params.recordId) return;
    const recordKey = records.findKey(r => r.get("id") === params.recordId);

    if (recordKey < 0) return;

    let record = records.get(recordKey);
    // если нет values, то записывай originValues потому что мне лень писать новый метод
    values = _.isUndefined(values)
      ? record.get("originValues")
      : Immutable.fromJS(values);
    record = record.mergeIn(["values"], values);

    this.setIn(["scenes", sceneId, "records", recordKey], record);
  }
};
