import React from "react";
import Immutable, { isImmutable } from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import PropTypes from "prop-types";
import cn from "classnames";
import { API_PREFIX } from "../../../configs/records";
import LinkedItem from "../UI/LinkedItem";

import Icon from '../../common/UI/Icon';

import styles from "./dataTypes.less";

function makeLinkOnFile(file) {
  return API_PREFIX + "files/" + file.id;
}

class FileField extends React.PureComponent {
  onClickItem = (e, item) => {
    const event = e.nativeEvent;
    const isKey =
      event.metaKey || event.altKey || event.ctrlKey || event.shiftKey;
    if (isKey) {
      e.stopPropagation(); // open file and prevent show record in sidebar
      if (this.props.removed) {
        e.preventDefault();
      }
    } else {
      e.preventDefault(); // open record in sidebar and prevent load file
      return true;
    }
  };

  render() {
    const values = this.props.value || Immutable.List();
    if (!values.size) {
      return <span />; // для позиционирования в форме поповера для таблицы
    }

    if (this.props.extended) {
      // multi items
      return (
        <ul className={cn(this.props.containerClassName, styles.listField)}>
          {values.map((val, i) => {
            return (
              <li key={i} className={styles.listItem}>
                <LinkedItem
                  item={{
                    icon: "files-13",
                    text: val.get("title")
                  }}
                  type="link"
                  linkProps={{
                    download: true,
                    target: "_blank",
                    onClick: this.onClickItem,
                    href: makeLinkOnFile(val.toJS())
                  }}
                  simple={true}
                />
              </li>
            );
          })}
        </ul>
      );
    } else {
      // simple as 1 item
      const text = values.map(u => u.get("title"));
      return (
        <span className={cn(this.props.containerClassName, styles.simpleField)}>
          <Icon type={"icon files-13"} className={styles.icon} />
          {values.size > 1 ? (
            <span className={styles.prefixData}>{values.size}: </span>
          ) : null}
          {text.join(", ")}
        </span>
      );
    }
  }
}

FileField.propTypes = {
  value: PropTypes.object,
  config: PropTypes.object,
  inContainers: PropTypes.bool,
  removed: PropTypes.bool,
  extended: PropTypes.bool
};

export default FileField;
