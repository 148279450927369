import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Checkbox, Row } from "antd";

import ItemListEditor from "./ItemListEditor";
import editorActions from "../../../actions/editorActions";
import Category from "../../common/UI/Category";

import styles from "./fields.less";

class DropdownField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      multiselect: !!this.props.field.getIn(["config", "multiselect"]),
      defaultValue: !!this.props.field.getIn(["config", "defaultValue"])
    };
  }

  onChangeMultiselect = e => {
    let val = e.target.checked;
    this.setState({
      multiselect: val
    });
    editorActions.changeFieldConfig(
      this.props.sectionId,
      this.props.fieldIndex,
      {
        multiselect: val,
        defaultValue: this.state.defaultValue
      }
    );
  };

  onChangeDefaultValue = e => {
    let defaultValue = e.target.checked;
    this.setState({
      defaultValue: defaultValue
    });
    editorActions.changeFieldConfig(
      this.props.sectionId,
      this.props.fieldIndex,
      {
        multiselect: this.state.multiselect,
        defaultValue: defaultValue
      }
    );
  };

  render() {
    const { mode, t } = this.props;

    if (mode === "view") {
      const config = this.props.field.get("config");
      const items = config.get("items") || [];

      return (
        <div className={styles.wrapperViewMode}>
          <div>
            {items.map((value, i) => {
              const color = `#${value.get("color") || "dfe0e0"}`;
              return (
                <Category
                  key={i}
                  readOnly
                  checked
                  className={styles.dropDownInline}
                  title={value.get("name")}
                  backgroundColor={color}
                />
              );
            })}
            <span className={styles.textChange}>
              { t("catalogEditor.field.lastItemWhenCollapsed") + "..." }
            </span>
          </div>
      </div>
      );
    } else return (
        <div>
          <ItemListEditor
            disabled={this.props.disabled}
            fieldIndex={this.props.fieldIndex}
            sectionId={this.props.sectionId}
            field={this.props.field}
            withColor={true}
          />
          <Checkbox
            className={styles.checkboxInline}
            disabled={this.props.disabled}
            checked={this.state.multiselect}
            onChange={this.onChangeMultiselect}
          >
            {this.props.t("fieldTypes.dropdown.multiselect")}
          </Checkbox>
          <Checkbox
            className={styles.checkboxInline}
            disabled={this.props.disabled}
            checked={this.state.defaultValue}
            onChange={this.onChangeDefaultValue}
          >
            {this.props.t("fieldTypes.dropdown.default")}
          </Checkbox>
        </div>
      );
  }
}

DropdownField.propTypes = {
  field: PropTypes.object.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  sectionId: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default withTranslation()(DropdownField);
