import { fromJS } from "immutable";

const userSettingsMixin = {
  setCatalogUserSettingsToAppState({ catalogId }, userSettingsForCatalog) {
    if (userSettingsForCatalog) {
      this.setIn(
        ["userSettings", "catalogs", catalogId],
        userSettingsForCatalog
      );
      this.changed();
    }
  },
  setUserSettingsToAppState(path, value) {
    value = value && value.toJS ? value : fromJS(value);
    this.setIn(["userSettings"].concat(path), value);
    this.changed();
  }
};

export default userSettingsMixin;
