import React from "react";
import Immutable from "immutable";
import _ from "lodash";

import FIELD_TYPES from "../../configs/fieldTypes";
import { VALUE_STATUSES } from "../../configs/import";

const delimiters = [",", ";", "\n"];

const splitValue = (values, delimiter) => {
  values = _.chain(values)
    .split(delimiter)
    .map(_.trim)
    .value();

  return Immutable.List(values);
};

const findItem = (value, field) => {
  const items = field.getIn(["config", "items"]);

  if (!items) {
    return false;
  }

  value = _.trim(value);

  return items.find(checkBox => {
    const name = _.trim(checkBox.get("name"));
    const id = checkBox.get("id");

    return _.toLower(value) == _.toLower(name) || value == id;
  });
};

export default class CheckBoxes {
  static type = FIELD_TYPES.CHECKBOXES;

  static components = {
    inline: require("../../components/common/dataTypes/CheckboxesField")
      .default,
    control: require("../../components/common/UI/ControlList/controls/CheckboxList")
      .default,
    selector: require("../../components/Fields/selectors/CheckBoxesSelector")
      .default
  };

  static sortValues = (field, values) => values;

  static getComponent = type => {
    return CheckBoxes.components[type];
  };

  static parseValue = (value, field) => {
    value = _.trim(value);

    /* парс пустого значения */
    if (_.isEmpty(value)) {
      value = CheckBoxes.getEmptyValue();
      return { value, status: VALUE_STATUSES.VALID };
    }

    /* парс значения заданного через разделители */
    let valuesByDelimetr = {};

    _.forEach(delimiters, delimiter => {
      /* разделение значения по разделителям */
      const splitedValuesByDelimetr = splitValue(value, delimiter);

      if (
        field &&
        splitedValuesByDelimetr &&
        !splitedValuesByDelimetr.isEmpty()
      ) {
        /* для каждого значения проводим валидацию, это нужно для "мягкого" режима отображения */
        const validatedValues = splitedValuesByDelimetr
          .map(value => {
            const item = findItem(value, field);
            if (
              item &&
              CheckBoxes.validateValue(Immutable.List([item]), field)
            ) {
              return item.get ? item.get("id") : null;
            } else {
              return null;
            }
          })
          .filter(i => !_.isNull(i));

        /* заполняем объект удачно распаршенными значениями */
        if (validatedValues && !validatedValues.isEmpty()) {
          valuesByDelimetr[delimiter] = validatedValues.filter(
            (value, index) => validatedValues.indexOf(value) === index
          ); //uniq
        }
      }
    });

    valuesByDelimetr = _.sortBy(valuesByDelimetr, values => values.size);
    const mostMatchesValues = _.last(valuesByDelimetr);

    if (mostMatchesValues && !mostMatchesValues.isEmpty()) {
      return { value: mostMatchesValues, status: VALUE_STATUSES.VALID };
    }

    return { value, status: VALUE_STATUSES.INVALID };
  };

  static validateValue = (value, field) => {
    if (Immutable.List.isList(value)) {
      return true;
    }

    if (!field) {
      return false;
    }
  };

  static valueIs = (value, type) => {
    return typeof value === type;
  };

  static setValue = (value, newValue) => {
    return newValue ? value.push(newValue) : value;
  };

  static hasItem = (value, item) => {
    return value && value.some(i => i === item);
  };

  static convertIdToValue = (field, id) => id;

  static removeItem = (values, value) => {
    return values.filter(i => i !== value);
  };

  static calcId = value => value;

  static getEmptyValue = () => {
    return Immutable.List();
  };

  static receivePossibleItems = (field, records, filters) => {
    if (filters) {
      let values = records.map(record =>
        record.getIn(["values", field.get("id")])
      );
      values = values.filter(value => value.some(v => filters.includes(v)));
      let items = Immutable.List();
      values.forEach(arrValue => {
        arrValue.forEach(value => {
          if (!items.includes(value)) {
            items = items.push(value);
          }
        });
      });
      return items;
    } else {
      return field.getIn(["config", "items"]).map(item => item.get("id"));
    }
  };

  static isEmpty = value => !(value && value.size);

  static createComponent = (field, value, type) => {
    const Component = CheckBoxes.components[type];

    value = _.isObject(value) ? value : Immutable.List([value]);

    return ({ containerClassName }) => {
      if (CheckBoxes.isEmpty(value)) {
        return null;
      }
      return (
        <Component
          config={field.get("config")}
          value={value}
          containerClassName={containerClassName}
        />
      );
    };
  };

  static compare = (value1, value2) => {
    if (value1 && value1.toJS && value2 && value2.toJS) {
      return value1.equals(value2);
    }

    return _.isEqual(value1, value2);
  };
  static convertFilterToRecordValue = fieldFilters => {
    if (!fieldFilters) return;

    let values = [];

    fieldFilters.forEach(filterValues => {
      if (!filterValues) return;
      const filterValue = filterValues.value;
      values = _.chain(filterValue)
        .filter(i => !!i)
        .uniq()
        .value();
    });

    return values;
  };
  static getDefaultValue = field => {
    const defaultValue =
      field.getIn(["config", "defaultEmptyValue"]);
    return defaultValue;
  };

  static validateRequired = (value) => {
    const empty = !value || (_.isArray(value) && value.length == 0);
    return empty;
  }

  static boardWitchColor = () => {
    return false;
  }

  static visibleRules = (v) => {
    return { $include: v };
  }

  static sortRecordsInCards = (field, records) => {
    const fieldId = field.get("id");
    const fieldConfig = field.getIn(["config", "items"]);

    // sort by order id of item
    let fieldItems = {};
    fieldConfig.map((f, index) => {
      fieldItems[f.get("id")] = index;
    });
    
    return records.sortBy(
      r => fieldItems[r.getIn(["values", fieldId, 0])]
    ); // value is array;
  }
}
