import _ from 'lodash'

import recordMixinCreating from "./recordMixin.creating"
import recordMixinRecieving from "./recordMixin.recieving"
import recordMixinDeleting from "./recordMixin.deleting"  
import recordMixinUpdating from "./recordMixin.updating"
import recordMixinPublic from "./recordMixin.public"
import helpers from "./helpers"

export default _.assign(
  {},
  recordMixinCreating,
  recordMixinPublic,
  recordMixinRecieving,  
  recordMixinDeleting,  
  recordMixinUpdating,  
  helpers
)