import React from "react";
import { Radio } from "antd";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import Icon from '../../../common/UI/Icon';
import { OBJECT_MODS } from "../../../../configs/fieldTypes";

import styles from "./ObjectField.less";

const ObjectModeTabs = props => {
  const { t } = useTranslation();
  const onChange = event => {
    props.onChange && props.onChange(event);
  };

  return (
    <div className={styles.viewTabsContainer}>
      <label htmlFor="mode">{t("fieldTypes.object.mode.label")}</label>
      <Radio.Group
        id={"mode"}
        name={"mode"}
        className={styles.viewTabs}
        onChange={onChange}
        defaultValue={props.view}
        value={props.view}
      >
        <Radio.Button value={OBJECT_MODS.LIST}>
          <Icon type="icon text-38" className={styles.viewTabsIcon} />
          {t("fieldTypes.object.mode.list")}
        </Radio.Button>
        <Radio.Button value={OBJECT_MODS.CARDS}>
          <Icon
            type="icon text-54"
            className={cn(styles.viewTabsIcon, styles.viewTabsIconCards)}
          />
          {t("fieldTypes.object.mode.cards")}
        </Radio.Button>
        <Radio.Button value={OBJECT_MODS.TABLE}>
          <Icon type="icon text-32" className={styles.viewTabsIcon} />
          {t("fieldTypes.object.mode.table")}
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

export default ObjectModeTabs;
