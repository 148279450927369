import React from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import Icon from '../../../common/UI/Icon';
import LinkedItem from "../../../common/UI/LinkedItem";
import RecordFields from "../../../Record/RecordBody/mainTab/RecordFields";
import FIELD_TYPES from "../../../../configs/fieldTypes";

import styles from "./ObjectField.less";

class PreviewLinkedRecord extends React.PureComponent {
  static propTypes = {
    item: PropTypes.object,
    fields: PropTypes.object
  };

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  render() {
    const { item, fields } = this.props;

    let extFields = fields || [];
    let extValues = {};
    extFields = extFields.map(f => {
      f = f.set("type", FIELD_TYPES.TEXT);

      extValues[f.get("id")] = "...";
      return f;
    });
    extValues = Immutable.fromJS(extValues);

    return (
      <div className={styles.preview}>
        {fields && fields.size > 0 ? (
          <div className={styles.linkedItemExtended}>
            <RecordFields
              catalogId={item.get("catalogId")}
              recordId={"0"}
              fields={extFields}
              values={extValues}
              readOnly={true}
              compact={true}
            />
          </div>
        ) : (
          <div className={styles.linkedItem}>
            <LinkedItem
              removed={false}
              removable={false}
              item={{
                icon: <Icon type={"icon " + item.get("icon")} />,
                text: this.props.t("fieldTypes.object.item.previewRecordTitle")
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(PreviewLinkedRecord);
