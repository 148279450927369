import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import Immutable from "immutable";
import { Input, Button, Row } from "antd";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import Icon from "../common/UI/Icon";
import { alert } from "../common/Modal";
import IconsModal from "../common/IconsModal";
import editorActions from "../../actions/editorActions";
import apiActions from "../../actions/apiActions";
import { connect } from "../StateProvider";
import ButtonClose from "../common/UI/ButtonClose";
import { renderModaltoBody } from "../common/Modal";
import TabsMenu from "../common/menu/TabsMenu";

import SelectSection from "./SelectSection";

import styles from "./catalogEditor.less";

class CatalogEditorHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.catalog.get("name") || "",
      sectionId: this.props.catalog.get("sectionId")
    };
    this.inputName = React.createRef();
  }

  onSelectSection = section => {
    const sectionId = section.key;
    this.setState({
      sectionId
    });
    editorActions.setCatalogSection(
      this.props.match.params.sectionId,
      sectionId
    );
  };

  onChangeName = e => {
    let name = e.target.value || "";
    this.setState({ name });
    editorActions.setCatalogName(this.props.match.params.sectionId, name);
  };

  onChangeIcon = icon => {
    editorActions.setCatalogIcon(this.props.match.params.sectionId, icon);
  };

  changeIcon = () => {
    const catalog = this.props.catalog;
    renderModaltoBody(IconsModal, {
      header: catalog.get("name"),
      currentIcon: catalog.get("icon"),
      onOk: this.onChangeIcon
    });
  };

  save = history => {
    // need refactor, take out to BL.
    const { t } = this.props;
    let c = this.props.catalog.toJS();
    const sectionId = c.sectionId || this.props.match.params.sectionId; // for new catalogs if section is not set;

    if (!c.name) {
      alert({
        headerText: t("modals.noCatalogName.headerText"),
        text: t("modals.noCatalogName.text"),
        okText: t("modals.noCatalogName.okText"),
        onOk: () => {
          setTimeout(() => {
            let inputName = this.inputName.current;
            inputName.focus();
          });
        }
      });
      return;
    }

    let data = {
      name: c.name.trim(),
      icon: c.icon,
      sectionId: sectionId,
      fields: c.fields.map(f => {
        return {
          id: f.id,
          name: f.name || " ",
          required: f.required,
          hint: f.hint,
          type: f.type,
          config: f.config,
          visible: f.visible,
          history: f.history,
          filterable: f.filterable,
          apiOnly: f.apiOnly,
          comment: f.comment
        };
      })
    };

    if (c.id) {
      const params = {
        catalogId: c.id,
        sectionId: sectionId
      };

      apiActions.updateCatalog(params, data)
        .then(() => 
          history.push({
            pathname: `/section/${sectionId}/catalog/${c.id}`,
            search: history.location.search
          })
        )
        .catch((e) => 
          alert({
            headerText: t("modals.saveError.headerText"),
            text: _.get(e, "response.text"),
            okText: t("modals.saveError.okText"),
          })
        );
    } else {
      apiActions.createCatalog({ sectionId }, data)
        .then(result => {
          history.push({
            pathname: `/section/${sectionId}/catalog/${result.id}`,
            search: history.location.search
          });
        })
        .catch((e) => 
          alert({
            headerText: t("modals.saveError.headerText"),
            text: _.get(e, "response.text"),
            okText: t("modals.saveError.okText"),
          })
        );
    }
  };

  changeRouteOnClose = history => {
    if (this.props.catalog.get("isNew")) {
      history.push({
        pathname: `/section/${this.props.match.params.sectionId}`,
        search: history.location.search
      });
    } else {
      history.push({
        pathname: `/section/${
          this.props.match.params.sectionId
        }/catalog/${this.props.catalog.get("id")}`,
        search: history.location.search
      });
    }
  };

  close = history => {
    this.changeRouteOnClose(history);
  };

  componentDidMount() {
    const catalogId = this.props.match.params.catalogId;
    const catalog = this.props.catalogs.get(catalogId);

    if (catalog) {
      this.setState({
        name: catalog.get("name"),
        sectionId: catalog.get("sectionId")
      });
    } else {
      this.setState({
        name: "",
        sectionId: this.props.match.params.sectionId
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { t } = this.props;
    const catalogId = this.props.match.params.catalogId;
    const newCatalogId = nextProps.match.params.catalogId;
    if (!(nextProps.catalogs && nextProps.catalogs.size)) {
      return;
    }
    const err =
      this.props.catalogs.getIn([newCatalogId, "updateError"]) ||
      this.props.catalogs.getIn([newCatalogId, "createError"]);
    const wasMutating =
      this.props.catalogs.getIn([catalogId, "updating"]) ||
      this.props.catalogs.getIn([catalogId, "creating"]);
    const isMutating =
      this.props.catalogs.getIn([newCatalogId, "updating"]) ||
      this.props.catalogs.getIn([newCatalogId, "creating"]);
    if (wasMutating && !isMutating && err) {
      alert({
        headerText: t("modals.saveError.headerText"),
        text: t("modals.saveError.text"),
        okText: t("modals.saveError.okText")
      });
    }
  }

  render() {
    const { t } = this.props;
    const catalog = this.props.catalog;
    const icon = (catalog && catalog.get("icon")) || "content-11";
    const name = catalog && catalog.get("name");
    return (
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        className={styles.header}
      >
        <Row type="flex" className={styles.catalogSettings}>
          <SelectSection
            value={this.state.sectionId}
            onChange={this.onSelectSection}
            placeholder={t("catalogEditor.sectionPlaceholder")}
          />
        </Row>
        <Row type="flex" align="middle">
          {/*<TabsMenu items={tabs} className={styles.tabsMenu} />*/}
          <Button
            onClick={this.changeIcon}
            disabled={this.props.disabled}
            className={styles.headerIcon}
          >
            <Icon style={{ fontSize: "1.43em" }} type={`icon ${icon}`} />
          </Button>
          <Input
            ref={this.inputName}
            disabled={this.props.disabled}
            onChange={this.onChangeName}
            value={name}
            style={{ width: "280px" }}
            placeholder={t("catalogEditor.namePlaceholder")}
          />
        </Row>
        <Row type="flex" align="middle" className={styles.catalogActions}>
          <Button
            type="primary"
            disabled={this.props.disabled}
            onClick={() => this.save(this.props.history)}
          >
            {this.props.disabled ? t("buttons.saving") : t("buttons.save")}
          </Button>
          <ButtonClose
            onClick={() => this.close(this.props.history)}
            shiftRight
          />
        </Row>
      </Row>
    );
  }
}

CatalogEditorHeader.propTypes = {
  catalog: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

export default connect(
  CatalogEditorHeader,
  ["catalogs"]
);
