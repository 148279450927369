import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { DragSource } from "react-dnd";
import _ from "lodash";
import { Input, Checkbox } from "antd";
import { withTranslation, useTranslation } from "react-i18next";

import ReactResizeDetector from "react-resize-detector";
import Icon from "../common/UI/Icon";
import dndTargets from "../../configs/dndTargets";
import dragAndDropActions from "../../actions/dragAndDropActions";
import FieldRemoveCross from "./FieldRemoveCross";
import fieldTypeIcons from "../../configs/fieldTypeIcons";
import editorActions from "../../actions/editorActions";
import FIELD_TYPES from "../../configs/fieldTypes";
import ButtonTransparent from "../common/UI/ButtonTransparent";

import styles from "./catalogEditor.less";


const compactFields = [
  FIELD_TYPES.RADIOBUTTON,
  FIELD_TYPES.OBJECT,
  FIELD_TYPES.DROPDOWN,
  FIELD_TYPES.CHECKBOXES,
  FIELD_TYPES.BUTTON,
];

const notRequiredFields = [
  FIELD_TYPES.BUTTON,
];

const fieldNameByType = {};
_.forEach(
  FIELD_TYPES,
  (id, name) => (fieldNameByType[id] = name.toLowerCase())
);

const dragSource = DragSource(
  dndTargets.FIELD,
  {
    beginDrag(props, monitor, component) {
      let item = {
        fieldId: component.props.field.get("id"),
        fieldIndex: component.props.fieldIndex,
        state: component.state
      };
      dragAndDropActions.beginDrag(dndTargets.FIELD, item);
      return item;
    },
    endDrag(props, monitor, component) {
      props.onSelectField(props.fieldIndex);
      dragAndDropActions.endDrag();
    }
  },
  function collect(connect, monitor) {
    return {
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging()
    };
  }
);

const ApiOnlyIcon = () => {
  const { t } = useTranslation();
  return (
    <Icon
      title={t("catalogEditor.field.config.edit.apiOnly")}
      style={{ verticalAlign: "1px" }}
      type="icon edition-55"
    />
  );
};

const VisibleRulesIcon = () => {
  const { t } = useTranslation();
  return (
    <Icon
      title={t("catalogEditor.field.config.edit.visibleRules")}
      style={{ marginRight: "6px" }}
      type="icon edition-29"
    />
  );
};

class FieldWrapper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.field && this.props.field.get("name"),
      iconHovered: false,
      settingsOpened: false
    };
    this.inputNameNode = React.createRef();
    this.fieldRightSide = React.createRef();
    this.timeoutId = React.createRef();
  }

  componentDidMount() {
    this.collapseField();
    
    const inputNameNode = this.inputNameNode.current;

    if (
      inputNameNode && 
      this.props.needFocusOnInputNameCallback &&
      document.activeElement !== inputNameNode
    ) {
      inputNameNode.focus();
      inputNameNode.select();
      this.props.needFocusOnInputNameCallback();
    }

    this.setFieldNameDebounce = _.debounce(name => {
      editorActions.setFieldName(
        this.props.sectionId,
        this.props.fieldIndex,
        name
      );
    }, 200);
    this.setFieldHintDebounce = _.debounce(hint => {
      editorActions.setFieldHint(
        this.props.sectionId,
        this.props.fieldIndex,
        hint
      );
    }, 200);
  }

  componentDidUpdate(prevProps) { 
    const { selected, compact } = this.props;
    if (prevProps.selected !== selected || prevProps.compact !== compact) {
      this.collapseField();
    }
  }

  UNSAFE_ReceiveProps(nextProps) {
    const newField = nextProps.field && nextProps.field.toJS();

    if (!_.isEqual(this.state.name, newField.name)) {
      this.setState({ name: newField.name });
    }
  }

  onRemove = () => {
    this.props.onSelectField(null);
  };

  onChangeName = e => {
    let name = e.target.value;
    this.setState({ name });
    this.setFieldNameDebounce(name);
  };

  onChangeRequired = e => {
    const required = !this.props.field.get("required");
    editorActions.setFieldRequired(
      this.props.sectionId,
      this.props.fieldIndex,
      required
    );
  };

  onChangeHint = e => {
    let hint = e.target.value;
    editorActions.setFieldHint(
      this.props.sectionId,
      this.props.fieldIndex,
      hint
    );
  };

  onChangeApiOnly = e => {
    const { checked } = e.target;
    editorActions.setFieldApiOnly(
      this.props.sectionId,
      this.props.fieldIndex,
      checked
    );
  };

  onMouseEnterIcon = e => this.setState({ iconHovered: true });

  onMouseLeaveIcon = e => this.setState({ iconHovered: false });

  collapseField = () => {
    const { field, compact, selected } = this.props;
    const type = field.get("type");
    const fieldRightSide = this.fieldRightSide.current; 
    const isFieldCompact = compactFields.includes(type);

    if (!fieldRightSide) return;
    if (!isFieldCompact) return;
    
    // если филды свернуты
    if (compact) {
      clearTimeout(this.timeoutId.current);
      
      if (selected) {
        // иногда бывает так, что дочерние элементы fieldRightSide не успевают отрендериться 
        // из-за этого scrollHeight не всегда имеет полную высоту   
        this.timeoutId.current = setTimeout(() => { 
          // тк событие висит на дочернем компоненте fieldRightSide
          const childFieldRightSide = fieldRightSide.children[0];
          if (childFieldRightSide) {
            fieldRightSide.style.height = `${ childFieldRightSide.scrollHeight + 20 }px`; 
          }
        }, 0);
      } else {
        fieldRightSide.style.height = "48px";
      }

    } else {
      fieldRightSide.style.height = "auto";
    }
  }

  toggleFieldSettings = () =>
    this.setState({ settingsOpened: !this.state.settingsOpened });

  onSelectField = () => { 
    const fieldIndex = this.props.fieldIndex;

    this.collapseField();
    this.props.onSelectField(fieldIndex);
  }

  render() {
    const {
      fieldIndex,
      connectDragSource,
      connectDragPreview,
      isDragging,
      field,
      compact,
      t
    } = this.props;
    
    const { 
      name 
    } = this.state;

    let fieldType = field.get("type");
    let isFieldCollapsed = field.get("collapsed");

    const isFieldCompact = compactFields.includes(fieldType);

    let classes = classNames(
      styles.field,
      { [styles.fieldSelected]: this.props.selected },
      { [styles.dragging]: isDragging }, 
      { [styles.firstField]: fieldIndex == 0 }
    );

    let extraIcon = null;
    const visible = field.get("visible") && field.get("visible").toJS();
    if (!_.isEmpty(visible)) {
      extraIcon = <VisibleRulesIcon />;
    } else if (field.get("apiOnly")) {
      extraIcon = <ApiOnlyIcon />;
    }


    return (
      <div 
        className={classes} 
        style={isFieldCollapsed ? { display: "none" } : null}
        onClick={this.onSelectField} 
      >
        {connectDragPreview(
          <div className={styles.fieldData}>
            <div
              className={styles.fieldApiId}
              title={t("catalogEditor.field.config.code.title")}
            >
              {extraIcon}
              {fieldIndex == 0 && field.get("id")
                ? t("catalogEditor.field.config.code.titleShort") + " "
                : ""}
              {field.get("id")}
            </div>

            <div
              className={styles.fieldLeftSide}
            >
              {connectDragSource(
                <div className={styles.fieldDrag}>
                  <span
                    className="anticon-icon interface-42"
                    onMouseEnter={this.onMouseEnterIcon}
                    onMouseLeave={this.onMouseLeaveIcon}
                  />
                </div>
              )}

              <div className={styles.fieldIcon}>
                <span
                  className={classNames(
                    "anticon-icon " + fieldTypeIcons[fieldType]
                  )} //{'field-left-side__icon anticon-icon ' + fieldTypeIcons[fieldType]}
                />
              </div>

              <div className={styles.fieldLeftSideName}>
                <Input
                  ref={this.inputNameNode}
                  disabled={this.props.disabled}
                  placeholder={t(
                    `fieldTypes.${fieldNameByType[fieldType]}.namePlaceholder`
                  )}
                  type="text"
                  onChange={this.onChangeName}
                  value={name}
                />
              </div>

              {
                notRequiredFields.includes(fieldType) 
                  ? <div className={styles.fieldLeftSideOptions}> 
                      <div className={styles.notRequiredField}></div>
                    </div>
                  : <div className={styles.fieldLeftSideOptions}>
                      <ButtonTransparent
                        onClick={this.onChangeRequired}
                        className={
                          field.get("required")
                            ? styles.fieldLabelRequired
                            : styles.fieldLabelNotRequired
                        }
                        title={t(`isRequired`)}
                      >
                        <Icon type="icon keyboard-10" />
                      </ButtonTransparent>
                    </div>
                }
            </div>


            <div
              ref={this.fieldRightSide}
              className={classNames(
                styles.fieldRightSide,
                { [styles.smoothAttenuation]: 
                  compact && isFieldCompact && !this.props.selected 
                },
                )}
              >
              <ReactResizeDetector 
                skipOnMount 
                handleHeight
                refreshRate={100} 
                onResize={this.collapseField()}
              >
                {this.props.children}
              </ReactResizeDetector>
            </div>

            {!this.props.hideCross && !this.props.disabled ? (
              <div className={styles.fieldRightSideRemove}>
                <FieldRemoveCross
                  sectionId={this.props.sectionId}
                  fieldIndex={this.props.fieldIndex}
                  onRemove={this.onRemove}
                  fieldId={field.get("id")}
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

FieldWrapper.propTypes = {
  hideCross: PropTypes.bool,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  compact: PropTypes.bool,
  field: PropTypes.object.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  sectionId: PropTypes.string.isRequired,
  catalogId: PropTypes.string,
  needFocusOnInputNameCallback: PropTypes.func,
  onFieldSelect: PropTypes.func
};

export default withTranslation()(dragSource(FieldWrapper));
