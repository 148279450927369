import React from "react";
import cn from "classnames";
import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";

import { confirm, prompt } from "../../common/Modal";

import Timer from "./timer";
import canEditBoard from "../canEditBoard";
import apiActions from "../../../actions/apiActions";
import NavRedirect from "../../common/router/Redirect";
import routes from "../../../routes";
import Creater from "./BoardCreater";

import styles from "./header.less";
import { capitalize } from "lodash";
import HeaderBoard from "./HeaderBoard";

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      boards: this.getSortedBoards(),
      editableBoardId: null,
      newBoardId: null,
      boardOpportunities: [
        {
          key: "rename",
          icon: "edition-33",
          title: props.t("reports.boards.rename")
        },
        {
          key: "remove",
          title: props.t("reports.boards.remove"),
          icon: "edition-41",
          danger: true
        }
      ]
    };
  }

  renameBoard = value => {
    const board = this.getBoardById(this.state.editableBoardId);
    board.name = value;
    apiActions.updateBoard({ boardId: this.state.editableBoardId }, board);
  };

  removeBoard = () =>
    apiActions.deleteBoard({ boardId: this.state.editableBoardId });

  getBoardById = id => this.props.boards.toJS().find(board => board.id === id);

  openModal = (act, editableBoardId) => {
    const method = this["open" + capitalize(act) + "Modal"];

    this.setState({ editableBoardId }, method);
  };

  getReadonlyFlag = (catalog = this.props.catalog) => !canEditBoard(catalog);

  openRemoveModal = () => {
    const { t } = this.props;
    const modalData = {
      headerText: t("modals.removeDashboard.header"),
      okText: t("modals.removeDashboard.okText"),
      cancelText: t("modals.removeDashboard.cancelText"),
      text: t("modals.removeDashboard.text"),
      onOk: this.removeBoard
    };

    confirm(modalData);
  };

  openRenameModal = () => {
    const { t } = this.props;
    const modalData = {
      headerText: t("modals.renameDashboard.header"),
      okText: t("modals.renameDashboard.okText"),
      cancelText: t("modals.renameDashboard.cancelText"),
      value: null,
      onOk: this.renameBoard
    };

    const editableBoard = this.state.boards.find(
      board => board.get("id") === this.state.editableBoardId
    );

    modalData.value = editableBoard.get("name");

    prompt(modalData);
  };

  openCreateModal = () => {
    const { catalog, viewId, t } = this.props;
    const modalData = {
      headerText: t("modals.createDashboard.header"),
      okText: t("modals.createDashboard.okText"),
      placeHolder: t("modals.createDashboard.placeHolder"),
      cancelText: t("modals.createDashboard.cancelText"),
      onOk: name => {
        paramsForCreating.name = name;
        apiActions.createBoard({}, paramsForCreating).then(res => {
          let href = window.location.href.split("/");
          href[href.length - 1] = res.id;
          window.location.href = href.join("/");
        });
      }
    };
    const paramsForCreating = {};

    paramsForCreating.catalogId = catalog.get("id");

    if (+viewId) {
      paramsForCreating.viewId = viewId;
      modalData.message = (
        <span>
          {t("modals.createDashboard.warning")}
          <br />
          {`${t("modals.createDashboard.advice")}`}
        </span>
      );
    }

    prompt(modalData);
  };

  getSortedBoards(props = this.props) {
    const { catalog, boards, loaded } = props;

    let { viewId } = props;
    viewId =
      viewId &&
      (String(viewId) !== "0" && String(viewId) !== "$new" ? viewId : null);

    const boardArr = boards.toArray();
    const catalogOnlyBoards = boardArr.filter(b => b.get("viewId") == null);
    const viewBoards = viewId
      ? boardArr.filter(b => b.get("viewId") == viewId)
      : [];

    const catalogId = catalog.get("id");
    // workaround: create boards automatically
    if (catalogId && canEditBoard(catalog)) {
      if (!catalogOnlyBoards.length && !this.creatingForCatalog) {
        this.creatingForCatalog = true;
        apiActions
          .createBoard({}, { catalogId })
          .then(() => (this.creatingForCatalog = false));
      }
    }
    return [...viewBoards, ...catalogOnlyBoards];
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ boards: this.getSortedBoards(nextProps) });
  }

  render() {
    const { board, fullScreen, catalog, viewId, t } = this.props;
    const { boards } = this.state;
    const catalogName = catalog && catalog.get("name");

    let Redirect = null;
    if (!board || !boards.find(b => b.get("id") === board.get("id"))) {
      const boardId = boards[0] && boards[0].get("id");
      Redirect = boardId && (
        <NavRedirect route={routes.board} params={{ boardId }} />
      );
    }

    return (
      <Row
        type="flex"
        justify="space-between"
        align="top"
        className={this.props.className}
      >
        {Redirect}
        <Col >
          <Row type="flex">
            {fullScreen.active && (
              <b className={styles.listItemCatalogName}>{catalogName}</b>
            )}
            <div>
              {boards.map(b => {
                return (
                  <HeaderBoard
                    readOnly={this.getReadonlyFlag()}
                    opportunities={this.state.boardOpportunities}
                    key={b.get("id")}
                    board={b}
                    viewId={viewId}
                    selected={b === board}
                    onClick={this.openModal}
                  />
                );
              })}
              {!this.getReadonlyFlag() && (
                <Creater onClick={this.openCreateModal} />
              )}
            </div>
          </Row>
        </Col>
        <Col >
          <Row type="flex" justify="space-between">
            {board && <Timer board={board} />}
            {fullScreen.enabled && (
              <div className={styles.listItem} onClick={fullScreen.toggle}>
                <span
                  className={cn("anticon-icon hardware-1", styles.listItemIcon)}
                />
                <span>
                  {t(
                    "reports.buttons.fullScreen." +
                      (fullScreen.active ? "close" : "open")
                  )}
                </span>
              </div>
            )}
          </Row>
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(Header);
