// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".number__widgetNumber___PDldq {\n  width: 100%;\n  height: 100%;\n  position: relative;\n  overflow: hidden;\n}\n.number__content___ealld {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n}\n.number__value___f5yzG {\n  white-space: nowrap;\n  text-align: center;\n  transition: font-size 0.1s ease-out;\n  line-height: 1.3em;\n}\n.number__label___Ig2nU {\n  white-space: nowrap;\n  text-align: center;\n  transition: font-size 0.1s ease-out;\n  line-height: 1em;\n  opacity: 0.75;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Reports/Board/Widget/chart/number.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;AACF;AAEA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;AAAF;AASA;EALE,mBAAA;EACA,kBAAA;EACA,mCAAA;EAKA,kBAAA;AALF;AAQA;EAVE,mBAAA;EACA,kBAAA;EACA,mCAAA;EAUA,gBAAA;EACA,aAAA;AAJF","sourcesContent":[".widgetNumber {\n  width: 100%;\n  height: 100%;\n  position: relative;\n  overflow: hidden;\n}\n\n.content {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.data() {\n  white-space: nowrap;\n  text-align: center;\n  transition: font-size 0.1s ease-out;\n}\n\n.value {\n  .data();\n  line-height: 1.3em;\n}\n\n.label {\n  .data();\n  line-height: 1em;\n  opacity: 0.75;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widgetNumber": "number__widgetNumber___PDldq",
	"content": "number__content___ealld",
	"value": "number__value___f5yzG",
	"label": "number__label___Ig2nU"
};
export default ___CSS_LOADER_EXPORT___;
