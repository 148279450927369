// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".layoutMiddlePanel__container___VvYNj {\n  overflow: visible;\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  flex-basis: 0;\n  background-color: #ffffff;\n}\n", "",{"version":3,"sources":["webpack://./src/components/App/AppBody/Section/SectionBody/Catalog/layoutMiddlePanel.less"],"names":[],"mappings":"AACA;EACE,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;AAAF","sourcesContent":["@import (reference) \"../../../../../../styles/helpers/index.less\";\n.container {\n  overflow: visible;\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  flex-basis: 0;\n  background-color: @BG_CONTENT;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "layoutMiddlePanel__container___VvYNj"
};
export default ___CSS_LOADER_EXPORT___;
