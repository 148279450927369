import { Button } from 'antd';
import React from 'react';
import cn from "classnames";

import Feedback from './FeedBack';

import styles from "../companyInfo.less"

const LicenseInfo = (props) => {
    const { feedback, setFeedback } = props;
    if (feedback.flag) return <Feedback {...props} setFeedback={setFeedback} />;
    return (
        <div className={styles.containerLicenseAbout}>
            <span className={styles.textLicense}>{props.t("userMenu.license.licenseTitle")}</span>
            <span>{props.t("record.users.count", { count: props.usersCompanyCount })}</span>
            {!!props.recordsLimit && <div>
                <span>{`${props.recordsLimit} ${props.t("userMenu.license.countRecords")} / `}</span>
                {`${props.t("userMenu.license.remainedRecords.count", { count: props.recordsRemained })} = `}
                <span className={cn({
                    [styles.licenseEnds]: props.redTitlePercentage
                })}>{`${props.recordsRemainingPercentage}%`}
                </span>
            </div>}
            <div>
                {props.t("userMenu.license.licenseWorks")}
                {/* <span>{`${props.t("userMenu.license.before")} ${props.licenseDate} / `}</span>
                <span className={cn({
                    [styles.licenseEnds]: props.redTitleDate
                })}>[{`${props.t("userMenu.license.remainedDate.count", { count: props.getNumberFromDate(props.diffInDays) })} ${props.diffInDays}`}]</span> */}
            </div>
            {(props.isAdmin && false) && <div className={styles.containerBtnExtensions}>
                <span>{props.t("userMenu.license.toExtend")}</span>
                <div className={styles.btnGroup}>
                    <Button onClick={() => props.downloadTxtFile()}>{props.t("userMenu.license.buttons.downloadInvoice")}</Button>
                    <Button>{props.t("userMenu.license.buttons.onlinePay")}</Button>
                </div>
            </div>}
            {props.isAdmin && <span onClick={() => setFeedback({ flag: true, message: props.t("userMenu.license.buttons.changeParams") })} className={styles.changeParamsLicense}>{props.t("userMenu.license.buttons.changeParams")}</span>}
        </div>
    );
};

export default LicenseInfo;