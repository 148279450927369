import { Popover } from 'antd';
import React, { useState } from 'react';

import CompanyInfo from './CompanyInfo';
import UserAvatarContainer from './UserAvatar/UserAvatarContainer';
import UserInfoContainer from './UserInfo/UserInfoContainer';

import styles from "./userMenu.less"


const UserMenu = () => {
    const [isOpen, setOpen] = useState(false);
    const [isOpenChildrenPopup, setOpenChildrenPopup] = useState(false);
    const handleVisibleChange = (show) => {
        // при изменении цвета в ColorPicker закрывается окно с юзер меню поэтому если окно с ColorPicker открыто, то мы ничего не делаем.
        if (isOpenChildrenPopup) return;
        setOpen(show);
    };

    const listenerIsTheChildPopupOpen = (isOpenChildrenPopup) => {
        setOpenChildrenPopup(isOpenChildrenPopup);
    };

    return (
        <Popover
            title={<UserInfoContainer />}
            content={<CompanyInfo listenerIsTheChildPopupOpen={listenerIsTheChildPopupOpen} />}
            placement="bottomRight"
            overlayClassName={styles.dropdownItems}
            trigger="click"
            onOpenChange={handleVisibleChange}
            open={isOpen}
        >
            <div className={styles.containerUserMenu} onClick={() => handleVisibleChange(true)}>
                <UserAvatarContainer />
            </div>
        </Popover>
    );
};

export default UserMenu;