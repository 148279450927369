import React from "react";
import Immutable from "immutable";
import { withRouter } from "react-router";
import TabsMenu from "../../common/menu/TabsMenu";
import DefaultRedirect from "../../common/router/DefaultRedirect";
import routes from "../../../routes";
import mapRouteParams from "../../common/router/mapRouteParams";
import apiActions from "../../../actions/apiActions";
import { connect } from "../../StateProvider";
import { withTranslation } from "react-i18next";

import { prompt } from "../../common/Modal";

import PRIVILEGE_CODES from "../../../configs/privilegeCodes";
import RESOURCE_TYPES from "../../../configs/resourceTypes";
import { checkAccessOnObject } from "../../../utils/rights";

import styles from "./appHeader.less";

const IconForSectionDefault = "content-3";

class SectionsMenu extends React.PureComponent {
  state = {
    reordering: false
  };

  componentDidMount() {
    apiActions.getSections();

    this.setState({
      sections: this.sortSections(this.props.sections)
    });
  }

  // todo remove this workaround
  // when api with "'available' privilege" feature becomes
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.sections.get("1") &&
      !nextProps.sectionsLoading.get("1") &&
      !nextProps.sectionsLoaded.get("1")
    ) {
      apiActions.getSection({ sectionId: "1" });
    }

    if (nextProps.sections !== this.props.sections && !this.state.reordering) {
      this.sortSections(nextProps.sections);
    }
  }

  // Check if language changed to push "addSection button"
  // with right translation
  componentDidUpdate(prevProps) {
    if (this.props.t !== prevProps.t) {
      this.sortSections(this.props.sections);
    }
  }

  sortSections = sections => {
    let hasAdd = false;

    sections = sections
      .valueSeq()
      .sortBy(s => s.get("priority"))
      .map(s => s.remove("icon"))
      .map(s => s.set("title", s.get("name")))
      // show sections with privilegeCode > available (search, view, ...)
      .filter(section => section.get("privilegeCode") !== "available");

    const adminSection = sections.find(section => {
      return Number(section.get("id")) === 1;
    });

    if (adminSection) {
      hasAdd = checkAccessOnObject(
        RESOURCE_TYPES.SECTION,
        adminSection,
        PRIVILEGE_CODES.ADMIN
      );
    }

    if (hasAdd) {
      sections = sections.concat([
        Immutable.Map({
          id: "addButton",
          icon: "interface-72",
          name: this.props.t("section.menu.create"),
          onClick: this.openCreateModal
        })
      ]);
    }

    this.setState({ sections });
  };

  openCreateModal = () => {
    const { t } = this.props;
    if (this.props.sectionCreating) {
      return;
    }
    prompt({
      headerText: t("modals.createSection.headerText"),
      placeHolder: t("modals.createSection.inputPlaceHolder"),
      okText: t("buttons.create"),
      cancelText: t("buttons.cancel"),
      onOk: this.onCreateSection
    });
  };

  onCreateSection = name => {
    apiActions
      .createSection(
        {},
        {
          name: name,
          icon: IconForSectionDefault
        }
      )
      .then(res => {
        this.props.history.push({
          pathname: `/section/${res.id}`,
          search: this.props.location.search
        });
      });
  };

  filterFn = (idArr, textSearch) => {
    let arr = [];
    idArr.forEach(id => {
      this.props.sections.forEach(section => {
        if (section.get("id") === id) {
          if (
            section
              .get("name")
              .toLowerCase()
              .indexOf(textSearch) !== -1
          ) {
            arr.push(id);
          }
        }
      });
    });
    arr.push("addButton");
    return arr;
  };

  canDrag = key => {
    if (key === "addButton") {
      return false;
    }

    const sections = this.state.sections;
    const section = sections.find(section => {
      return section.get("id") === key;
    });

    const isAdmin =
      section &&
      checkAccessOnObject(
        RESOURCE_TYPES.SECTION,
        section,
        PRIVILEGE_CODES.ADMIN
      );

    return isAdmin;
  };

  saveToServer = (orderedList, key) => {
    let sections = this.state.sections;

    // calc new priority
    orderedList = orderedList.toJS();
    orderedList = orderedList.filter(i => i != "addButton");
    let index = orderedList.findIndex(i => i == key);
    if (index > 0) {
      const prevKey = orderedList[index - 1]; // get key of previous section
      const section = sections.find(section => {
        return section.get("id") === prevKey;
      });
      index = section.get("priority") + 1;
    }

    // update in local
    sections = this.props.sections;
    let prevIndex;
    sections = sections.map(section => {
      if (section.get("id") == key) {
        prevIndex = section.get("priority");
        section = section.set("priority", index - 0.1); // to be sure be in need place
      }
      return section;
    });
    this.sortSections(sections);
    this.setState({ reordering: true });
    //console.log(sections.toJS().map(s => s.id))

    // save
    apiActions
      .updateSection(
        {
          sectionId: key
        },
        {
          priority: index
        }
      )
      .then(() => {
        this.setState({ reordering: false });
      })
      .catch(() => {
        this.setState({ reordering: false });
        // put in previous place
        sections = this.props.sections;
        sections = sections.map(section => {
          if (section.get("id") == key) {
            section = section.set("priority", prevIndex);
          }
          return section;
        });
        this.sortSections(sections);
      });
  };

  render() {
    const sections = this.state.sections || Immutable.List();
    const firstSection = sections.get(0);

    return (
      <div className={styles.menuContainer}>
        <DefaultRedirect
          route={routes.section}
          params="sectionId"
          object={firstSection}
        />
        <TabsMenu
          route={routes.section}
          params="sectionId"
          items={sections}
          dragType="section"
          canDrag={this.canDrag}
          onDragEnd={this.saveToServer}
          filterFn={this.filterFn}
          theme="light"
        />
      </div>
    );
  }
}

export default connect(
  mapRouteParams(withTranslation()(SectionsMenu), routes.section),
  ["sections", "sectionsLoading", "sectionsLoaded", "sectionCreating"]
);
