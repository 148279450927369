import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { DragSource } from "react-dnd";
import _ from "lodash";
import { Input, Checkbox } from "antd";
import { PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";
import { withTranslation, useTranslation } from "react-i18next";

import Icon from "../common/UI/Icon";
import dndTargets from "../../configs/dndTargets";
import dragAndDropActions from "../../actions/dragAndDropActions";
import FieldRemoveCross from "./FieldRemoveCross";
import fieldTypeIcons from "../../configs/fieldTypeIcons";
import editorActions from "../../actions/editorActions";
import FIELD_TYPES from "../../configs/fieldTypes";

import styles from "./catalogEditor.less";

const fieldNameByType = {};
_.forEach(
  FIELD_TYPES,
  (id, name) => (fieldNameByType[id] = name.toLowerCase())
);

const dragSource = DragSource(
  dndTargets.FIELD,
  {
    beginDrag(props, monitor, component) {
      let item = {
        fieldId: component.props.section.get("id"),
        fieldIndex: component.props.sectionIndex,
        collapsed: component.props.section.get("collapsed"),
        state: component.state
      };
      dragAndDropActions.beginDrag(dndTargets.FIELD, item);
      return item;
    },
    endDrag(props, monitor, component) {
      props.onSelect(props.sectionIndex);
      dragAndDropActions.endDrag();
    }
  },
  function collect(connect, monitor) {
    return {
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging()
    };
  }
);

const VisibleRulesIcon = () => {
  const { t } = useTranslation();
  return (
    <Icon
      title={t("catalogEditor.field.config.edit.visibleRules")}
      style={{ marginRight: "6px" }}
      type="icon edition-29"
    />
  );
};

class SectionWrapper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.section && this.props.section.get("name"),
      iconHovered: false,
      settingsOpened: false
    };
    this.inputNameNode = React.createRef();
  }

  componentDidMount() { 
    const inputNameNode = this.inputNameNode.current;

    if (
      inputNameNode && 
      this.props.needFocusOnInputNameCallback &&
      document.activeElement !== inputNameNode
    ) {
      inputNameNode.focus();
      inputNameNode.select();
      this.props.needFocusOnInputNameCallback();
    }

    this.setFieldNameDebounce = _.debounce(name => {
      editorActions.setFieldName(
        this.props.sectionId,
        this.props.sectionIndex,
        name
      );
    }, 200);
    this.setFieldHintDebounce = _.debounce(hint => {
      editorActions.setFieldHint(
        this.props.sectionId,
        this.props.sectionIndex,
        hint
      );
    }, 200);
  }

  UNSAFE_ReceiveProps(nextProps) {
    const newSection = nextProps.section && nextProps.section.toJS();

    if (!_.isEqual(this.state.name, newSection.name)) {
      this.setState({ name: newSection.name });
    }
  }

  onRemove = () => {
    this.props.onSelect(null);
  };

  onChangeName = e => {
    let name = e.target.value;
    this.setState({ name });
    this.setFieldNameDebounce(name);
  };

  onMouseEnterIcon = e => this.setState({ iconHovered: true });

  onMouseLeaveIcon = e => this.setState({ iconHovered: false });


  onSelect = () => { 
    const sectionIndex = this.props.sectionIndex;
    this.props.onSelect(sectionIndex);
  }

  onCollapse = () => {
    const { section } = this.props;
    this.props.onCollapse(section);
  }

  calculateFieldsCollapsed = () => { 
    const { fields, section } = this.props;
    const uuid = section.get("uuid");
    let lengthCollapsed = 0; 

    if (!fields || !section) {
      return lengthCollapsed;
    }

    // ищем индекс текущей секции в массиве филдов
    const currIndexSection = fields.findIndex(f => f.get("uuid") === uuid);

    if (currIndexSection === -1) {
      return lengthCollapsed;
    }

    // начинаем итерацию с первого филда текущей секции и считаем все филды внутри этой секции
    for (let i = currIndexSection + 1; i < fields.length; i++) { 
      // считаем все филды, пока не наткнемся на следующую секцию
      if (fields[i].get("type") === FIELD_TYPES.GROUP) { 
        break;
      } else {
        ++lengthCollapsed;
      }
    }

    return lengthCollapsed;
  }

  render() {
    const {
      sectionIndex,
      connectDragSource,
      connectDragPreview,
      isDragging,
      section,
      t
    } = this.props;

    const { 
      name 
    } = this.state;

    let isCollapsed = section.get("collapsed");

    let classes = classNames(
      styles.field, 
      styles.fieldSection,
      { [styles.fieldSelected]: this.props.selected },
      { [styles.dragging]: isDragging }, 
    );

    const quantityCollapsedFields = isCollapsed ? this.calculateFieldsCollapsed() : 0;

    let extraIcon = null;
    const visible = section.get("visible") && section.get("visible").toJS();
    if (!_.isEmpty(visible)) {
      extraIcon = <VisibleRulesIcon />;
    }

    return (
      <div 
        className={classes}
        onClick={this.onSelect} 
      >
        {connectDragPreview(
          <div className={classNames(
              styles.fieldData, 
              { [styles.collapsed]: isCollapsed && quantityCollapsedFields }
            )}
          >
            <div className={styles.extraIcon}>
              {extraIcon}
            </div>
            <div className={styles.btnCollapseSection}>
              {
                isCollapsed 
                ? <PlusSquareOutlined onClick={this.onCollapse} title={t("catalogEditor.fields.expand")}/>
                : <MinusSquareOutlined onClick={this.onCollapse} title={t("catalogEditor.fields.collapse")}/>
              }
            </div>
            <div
              className={styles.fieldLeftSide}
              style={{ width: "100%" }}
            >
              {connectDragSource(
                <div className={styles.fieldDrag}>
                  <span
                    className="anticon-icon interface-42"
                    onMouseEnter={this.onMouseEnterIcon}
                    onMouseLeave={this.onMouseLeaveIcon}
                  />
                </div>
              )}

              <div className={styles.fieldIcon}>
                <span
                  className={classNames(
                    "anticon-icon " + fieldTypeIcons[FIELD_TYPES.GROUP]
                  )}
                />
              </div>

              {
                isCollapsed 
                ? (
                    <>
                      <div className={styles.sectionName}>
                        {name}
                      </div>
                      <div className={styles.countFields}>
                        {t("record.groupFields.count", { count: quantityCollapsedFields })}
                      </div>
                    </>
                  )
                : (
                    <div className={styles.fieldLeftSideName}>
                    <Input
                      ref={this.inputNameNode}
                      disabled={this.props.disabled}
                      placeholder={t(
                        `fieldTypes.${fieldNameByType[FIELD_TYPES.GROUP]}.namePlaceholder`
                      )}
                      type="text"
                      onChange={this.onChangeName}
                      value={name}
                    />
                  </div>
                )
              }
            </div>

            {!this.props.hideCross && !this.props.disabled ? (
              <div className={styles.fieldRightSideRemove}>
                <FieldRemoveCross
                  sectionId={this.props.sectionId}
                  fieldIndex={this.props.sectionIndex}
                  onRemove={this.onRemove}
                  fieldId={section.get("id")}
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

SectionWrapper.propTypes = {
  hideCross: PropTypes.bool,
  selected: PropTypes.bool,
  section: PropTypes.object.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  sectionId: PropTypes.string.isRequired,
  catalogId: PropTypes.string,
  needFocusOnInputNameCallback: PropTypes.func,
  onSelect: PropTypes.func,
  onCollapse: PropTypes.func
};

export default withTranslation()(dragSource(SectionWrapper));
