// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".board__container___U6NdR {\n  height: calc(100%-35px);\n  overflow-y: auto;\n  overflow-x: hidden;\n  padding-bottom: 200px;\n  background-color: #f7f8fa;\n}\n.board__addWidget___rhznI {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  width: 100%;\n  height: 100%;\n  text-align: center;\n  color: #5ca7ca;\n}\n.board__addWidget___rhznI:hover {\n  background-color: rgba(206, 209, 214, 0.3);\n}\n.board__gridWrapper___Gjjtb {\n  position: relative;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Reports/Board/board.less"],"names":[],"mappings":"AAEA;EACE,uBAAA;EACA,gBAAA;EACA,kBAAA;EACA,qBAAA;EACA,yBAAA;AADF;AAIA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,cAAA;AAFF;AAGE;EACE,0CAAA;AADJ;AAKA;EACE,kBAAA;AAHF","sourcesContent":["@import (reference) \"../../../styles/helpers/index.less\";\n\n.container {\n  height: calc(~\"100%\"- @RECORD_DATA_HEADER);\n  overflow-y: auto;\n  overflow-x: hidden;\n  padding-bottom: 200px;\n  background-color: @BG_WORKPLACE;\n}\n\n.addWidget {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  width: 100%;\n  height: 100%;\n  text-align: center;\n  color: @TEXT_LINK;\n  &:hover {\n    background-color: @BG_HOVERED;\n  }\n}\n\n.gridWrapper {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "board__container___U6NdR",
	"addWidget": "board__addWidget___rhznI",
	"gridWrapper": "board__gridWrapper___Gjjtb"
};
export default ___CSS_LOADER_EXPORT___;
