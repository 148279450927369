import React from "react";
import { Row } from "antd";
import { useTranslation } from 'react-i18next';

import Icon from '../../UI/Icon';
import Logo from "../../../App/AppHeader/Logo";
import location from "../../../../utils/location";
import { connect } from "../../../StateProvider";
import styles from "./authHeader.less";
import ButtonTransparent from "../ButtonTransparent";
import LanguageSwitcher from "../../widgets/LanguageSwitcher";
import UserMenu from "../../../App/AppHeader/UserMenu";

const AuthHeader = ({ user }) => {
  const { t } = useTranslation();

  const onClickExit = () => {
    location.logout();
  };

  return (
    <Row
      type="flex"
      justify="start"
      align="middle"
      className={styles.container}
    >
      <Row type="flex" align="middle" className={styles.logoContainer}>
        <Logo />
      </Row>
      <Row type="flex" align="middle" className={styles.containerSubmenu}>
        <LanguageSwitcher />
        <UserMenu />
        {user && (
          <React.Fragment>
            <p className={styles.username}>{user.get("email")}</p>
            <ButtonTransparent
              onClick={onClickExit}
              light
              title={t("buttons.exit")}
            >
              <Icon type="icon interface-13" />
            </ButtonTransparent>
          </React.Fragment>
        )}
      </Row>
    </Row>
  );
};

export default connect(
  AuthHeader,
  ["user"]
);
