import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Checkbox } from "antd";

import editorActions from "../../../actions/editorActions";
import { confirm } from "../../common/Modal";

import styles from "./fields.less";

class DateField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      withTime: !!this.props.field.getIn(["config", "time"]),
      defaultValue: !!this.props.field.getIn(["config", "defaultValue"])
    };
  }

  _onChangeWithTime = newVal => {
    this.setState({
      withTime: newVal
    });
    editorActions.changeFieldConfig(
      this.props.sectionId,
      this.props.fieldIndex,
      { time: newVal }
    );
  };

  onChangeWithTime = e => {
    let newVal = e.target.checked;
    let { originalField, t } = this.props;
    let originalValue =
      originalField && originalField.getIn(["config", "time"]);

    if (!newVal && originalValue) {
      confirm({
        headerText: t("modals.removeTimeFromDateConfirm.headerText"),
        text: t("modals.removeTimeFromDateConfirm.text"),
        okText: t("modals.removeTimeFromDateConfirm.okText"),
        cancelText: t("modals.removeTimeFromDateConfirm.cancelText"),
        useRedOk: true,
        onOk: () => this._onChangeWithTime(newVal)
      });
    } else {
      this._onChangeWithTime(newVal);
    }
  };

  onChangeDefaultValue = e => {
    let defaultValue = e.target.checked;
    this.setState({
      defaultValue: defaultValue
    });
    editorActions.changeFieldConfig(
      this.props.sectionId,
      this.props.fieldIndex,
      {
        defaultValue: defaultValue
      }
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      withTime: nextProps.field.getIn(["config", "time"]),
      defaultValue: nextProps.field.getIn(["config", "defaultValue"])
    });
  }

  render() {
    return (
      <div className={styles.fieldWrapper}>
        <div>
          <Checkbox
            className={styles.checkboxInline}
            disabled={this.props.disabled}
            checked={this.state.withTime}
            onChange={this.onChangeWithTime}
          >
            {this.props.t("fieldTypes.date.withTime")}
          </Checkbox>
          <Checkbox
            className={styles.checkboxInline}
            disabled={this.props.disabled}
            checked={this.state.defaultValue}
            onChange={this.onChangeDefaultValue}
          >
            {this.props.t("fieldTypes.date.default")}
          </Checkbox>
        </div>
      </div>
    );
  }
}

DateField.propTypes = {
  originalField: PropTypes.object,
  field: PropTypes.object.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  sectionId: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default withTranslation()(DateField);
