import React from "react";
import PropTypes from "prop-types";
import debug from "debug";
import _ from "lodash";
import cn from "classnames";

import Icon from '../../common/UI/Icon';

import styles from "./controls.less";

const log = debug("CRM:filters:StarsField");

class StarsField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: (this.props.value && this.props.value.toJS()) || []
    };
  }

  onClickStar = newVal => {
    if (this.props.readOnly) {
      return;
    }

    let value = this.state.value;
    if (value.indexOf(newVal) == -1) {
      value.push(newVal);
    } else {
      _.remove(value, i => i == newVal);
    }

    this.setState(
      {
        value: _.clone(value)
      },
      () => {
        setTimeout(() => {
          this.onSave(value);
        }, 0);
      }
    );
  };

  onSave = value => {
    const { onSave, fieldId } = this.props;

    onSave(fieldId, value);
  };

  render() {
    var stars = [];

    for (var i = 1; i <= 5; i++) {
      stars.push(
        <Icon
          key={i}
          type="icon vote-38"
          className={cn(styles.starsField, {
            [styles.starsFieldSelected]: this.state.value.indexOf(i) !== -1,
            readonly: this.props.readOnly
          })}
          onClick={_.bind(this.onClickStar, this, i)}
        />
      );
    }

    return <div className={styles.starsContainer}>{stars}</div>;
  }
}

StarsField.propTypes = {
  value: PropTypes.object,
  config: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
};

export default StarsField;
