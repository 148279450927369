import { InputMappers, OutputMappers } from "../helpers/mappers";
import i18n from "../../../../../configs/i18n";

const Properties = (field, t = i18n.t) => {
  const config = field.get("config");
  const trsPrefix =
    "catalogEditor.fieldProperties.configProperties.field." +
    field.get("type") +
    ".";

  return {
    properties: [
      {
        id: "configPropertiesSection",
        name: t("catalogEditor.fieldProperties.configProperties.section"),
        type: "group"
      },
      {
        id: "config.multiselect",
        name: t(trsPrefix + "multiselect.label"),
        type: "checkboxes",
        readOnly: false,
        config: {
          items: [
            {
              id: "true",
              name: t(trsPrefix + "multiselect.value")
            }
          ]
        }
      }
    ],

    values: {
      "config.multiselect": InputMappers.boolToCheckbox(
        config.get("multiselect")
      )
    },

    getValues: (propery, value) => {
      switch (propery) {
        case "config.multiselect":
          value = OutputMappers.checkboxToBool(value);
          break;
      }
      return { [propery]: value };
    }
  };
};

export default Properties;
