import React from "react";
import { Form } from "antd";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import apiActions from "../../actions/apiActions";

import styles from "./styles.less";

class PaymentRequiredForm extends React.Component {
  constructor() {
    super();

    this.state = {
      company: {},
      user: {}
    };
  }

  componentDidMount() {
    apiActions.getLicense();

    apiActions.payment().then(res => {
      const { user, company } = res.body;
      this.setState({ company, user });
    });
  }

  render() {
    const { company, user } = this.state;
    const { t } = this.props;

    return (
      <div className={styles.formContainer}>
        <div className={styles.formInner}>
          <Form 
            name="horizontal_login"
            className={styles.form} 
            onSubmit={this.handleSubmit}
          >
            <h1>{t("auth.paymentRequired")}</h1>

            <div className={styles.paymentDescription}>
              {company && (
                <p>
                  {t("auth.messages.companyLimited", { company: company.name })}
                </p>
              )}

              {company &&
                company.creator && (
                  <p class="payment-content__block">
                    {t("auth.payment.domainControls")}:{company.creator}.
                  </p>
                )}

              {user && (
                <p>
                  {t("auth.payment.enterAs")} {user.email}
                </p>
              )}
            </div>

            <Form.Item>
              <div className={styles.buttonGroup}>
                <div className="styles.loginButton">
                  <Link to="/auth/login">{t("auth.reenter")}</Link>
                </div>
                <div>
                  <Link to="/auth/activate">
                    {t("auth.activate.activate")}
                  </Link>
                </div>
                <div>
                  <Link to="/">{t("auth.cancel")}</Link>
                </div>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(PaymentRequiredForm);
