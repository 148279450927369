import _ from "lodash";
import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import moment from "moment";
import Immutable from "immutable";
import { DatePicker, Select } from "antd";
import { withTranslation } from "react-i18next";

import AppState from "../../../appState";
import DebouncedInput from "../../common/DebouncedInput";
import { dateFilters } from "../../../configs/filterKeys";

import styles from "./controls.less";
import getKeyRange from "../../../models/utils/getKeyRange";

const DATA_PICKER_FORMAT = "DD.MM.YYYY HH:mm";
const MOMENT_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";
const MOMENT_TIME_FORMAT = "HH:mm";
const MOMENT_DATE_FORMAT = "DD.MM.YYYY";
const DAY = "day";

const DEFAULT_START_TIME = moment().startOf(DAY);
const DEFAULT_END_TIME = moment().endOf(DAY);

const { Option, OptGroup } = Select;

const FIXED = "fixed";
const RELATIVE = "relative";

function isEqual(value1, value2) {
  // normalize
  value1 = Immutable.fromJS(value1);
  value2 = Immutable.fromJS(value2);

  return Immutable.is(value1, value2);
}

class DateRangeField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = getKeyRange(this.props.value);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (isEqual(this.currentValue, nextProps.value)) {
      return;
    }

    if (nextProps.value) {
      this.setState(getKeyRange(nextProps.value));
    }
  }

  onSave = value => {
    const { onSave, fieldId } = this.props;
    let { value: propsValue } = this.props;

    propsValue = propsValue && propsValue.toJS ? propsValue.toJS() : propsValue;

    value = _.assign({}, propsValue, value);
    value = _.transform(value, (res, v, k) => {
      if (v) res[k] = v;
    });
    value = _.isEmpty(value) ? null : value;

    this.currentValue = value;
    onSave(fieldId, value);
  };

  onStartDateChange = value => {
    if (!value) {
      return this.onSave({
        at: value
      });
    }

    const withTime = this.props.config.get("time");

    if (withTime) {
      value = moment(value).format(MOMENT_FORMAT);
    } else {
      value = moment(value)
        .startOf(DAY)
        .format(MOMENT_FORMAT);
    }

    return this.onSave({
      at: value
    });
  };

  onEndDateChange = value => {
    if (!value) {
      return this.onSave({
        to: value
      });
    }

    const withTime = this.props.config.get("time");

    if (withTime) {
      value = moment(value).format(MOMENT_FORMAT);
    } else {
      value = moment(value)
        .endOf(DAY)
        .format(MOMENT_FORMAT);
    }

    return this.onSave({
      to: value
    });
  };

  onSelectRange = keyRange => {
    let saveValue = keyRange;
    const { onSave, fieldId } = this.props;

    /*if ([FIXED, RELATIVE].indexOf(keyRange) > -1) {
      saveValue = null;
    }*/

    this.currentValue = saveValue;
    onSave(fieldId, saveValue);

    this.setState({ keyRange });
  };

  render() {
    let startDate, endDate;
    let value = this.props.value;
    const { t } = this.props;
    const withTime = this.props.config.get("time");

    if (value && _.isObject(value)) {
      startDate = value.get("at");
      endDate = value.get("to");
    }

    return (
      <section className="filter-item__date-field__container filter-date">
        <div className="filter-date__item">
          <Select
            className={styles.selectRange}
            value={this.state.keyRange}
            onChange={this.onSelectRange}
            disabled={this.props.readOnly}
          >
            {dateFilters.map((df) => {
              if (!df.title) {
                return df.values.map((item) =>            
                      <Option key={item.key} value={item.key}>
                        {item.value}
                      </Option>
                  );
              } else return (
                <OptGroup key={df.title}>
                  {
                    df.values.map((item) =>            
                      <Option key={item.key} value={item.key}>
                        {item.value}
                      </Option>
                    )
                  }
                </OptGroup> 
              );
            })}
          </Select>
        </div>

        {this.state.keyRange === FIXED ? (
          <div className={styles.item}>
            <span className={styles.rangeInput}>
              <DatePicker
                className={styles.datePicker}
                popupClassName={styles.datePickerPopup}
                value={
                  startDate && moment(startDate).isValid()
                    ? moment(startDate)
                    : null
                }
                format={withTime ? DATA_PICKER_FORMAT : MOMENT_DATE_FORMAT}
                showTime={
                  withTime && {
                    format: MOMENT_TIME_FORMAT,
                    defaultValue: DEFAULT_START_TIME
                  }
                }
                placeholder={t("fieldTypes.date.fromText")}
                disabled={this.props.readOnly}
                onChange={this.onStartDateChange}
                onSelect={this.onStartDateChange}
              />
            </span>
            <span className={styles.spanDash}>—</span>
            <span className={styles.rangeInput}>
              <DatePicker
                className={styles.datePicker}
                popupClassName={styles.datePickerPopup}
                value={
                  endDate && moment(endDate).isValid() ? moment(endDate) : null
                }
                format={withTime ? DATA_PICKER_FORMAT : MOMENT_DATE_FORMAT}
                showTime={
                  withTime && {
                    format: MOMENT_TIME_FORMAT,
                    defaultValue: DEFAULT_END_TIME
                  }
                }
                placeholder={t("fieldTypes.date.toText")}
                disabled={this.props.readOnly}
                onChange={this.onEndDateChange}
                onSelect={this.onEndDateChange}
              />
            </span>
          </div>
        ) : null}
        {this.state.keyRange === RELATIVE ? (
          <div className={styles.item}>
            <span className={styles.rangeInput}>
              <DebouncedInput
                type="number"
                value={startDate}
                onSave={value => {
                  this.onSave({ at: value });
                }}
                placeholder={t("fieldTypes.date.fromRelativeText")}
                readOnly={this.props.readOnly}
              />
            </span>
            <span className={styles.spanDash}>—</span>
            <span className={styles.rangeInput}>
              <DebouncedInput
                type="number"
                value={endDate}
                onSave={value => {
                  this.onSave({ to: value });
                }}
                placeholder={t("fieldTypes.date.toRelativeText")}
                readOnly={this.props.readOnly}
              />
            </span>
          </div>
        ) : null}
      </section>
    );
  }
}

export default withTranslation()(DateRangeField);
