import _ from "lodash";
import React from "react";
import { Form, Input, Button } from "antd";
import cn from "classnames";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import routes from "../../../../routes";
import {
  PASSPORT_USERNAME_FIELD,
  PASSPORT_PASSWORD_FIELD
} from "../../helpers/const";

import styles from "../../styles.less";

class LoginForm extends React.Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      token: null,
      redirect: false
    };
  }

  handleSubmit = async (values) => {
    if (values.email && values.password) {
      this.setState({ loading: true });
      await this.props.authUser(values.email, values.password);
    }
  };

  onFinishFailed = (e) => {}

  render() {
    const { t } = this.props;

    return (
      <Form 
        className={styles.form} 
        name="normal_login" 
        onFinish={this.handleSubmit}
        onFinishFailed={this.onFinishFailed} 
        >
        <h1>{t("auth.enter")}</h1>
        <Form.Item
          name={PASSPORT_USERNAME_FIELD}
          className={cn(styles.formItem)}
          rules={[{ required: true, message: t("auth.fieldHintMessage") }]}
        >
          <div>
            <div className={styles.formLabel}>{t("auth.username")}</div>    
            <Input className={styles.formField} placeholder="" />
          </div>
        </Form.Item>
        <Form.Item
          name={PASSPORT_PASSWORD_FIELD}
          className={cn(styles.formItem)}
          rules= {[{ required: true, message: t("auth.fieldHintMessage") }]}
        >
          <div>
            <div className={styles.formLabel}>{t("auth.password")}</div>
            <Input.Password className={styles.formField} type="password" placeholder="" />
          </div>
        </Form.Item>

        <Form.Item>
          <div className={styles.buttonGroup}>
            <Button
              tabIndex={0}
              key="submit"
              type="primary"
              className={styles.loginButton}
              htmlType="submit"
            >
              {t("auth.login")}
            </Button>
            <div>
              <Link to={routes.resetPass.path} className={styles.authLink}>
                {t("auth.resetPassword")}
              </Link>
            </div>
          </div>
        </Form.Item>
      </Form>
    );
  }
}

export default withTranslation()(LoginForm);
