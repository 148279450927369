import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { withTranslation } from "react-i18next";

import styles from "./ObjectField.less";

class ObjectItemNew extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className={cn(styles.itemContainer, styles.itemContainerNew)}
        onClick={this.props.onClick}
      >
        {this.props.t("fieldTypes.object.addSource")}
      </div>
    );
  }
}

ObjectItemNew.propTypes = {
  onClick: PropTypes.func
};

export default withTranslation()(ObjectItemNew);
