import _ from "lodash";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import PopoverSideMenu from "./PopoverSideMenu.jsx";
import { connect } from "../../../StateProvider";

import styles from "./companies.less";

const getItemGroups = menuItems => {
  let groups = [];

  !_.isEmpty(menuItems) &&
    Object.entries(menuItems).forEach(([title, items]) => {

      const tempItem = {
        className: styles.companiesGroup,
        type: "group",
        key: title,
        label: title, 
        children: items.map(item => item)
      };

      groups.push(tempItem);
    });
  return groups;
};

const addMenuItems = companies => {
  let groupedItems = {};

  companies = companies.sort
    ? companies.sort((a, b) => a.get("title").localeCompare(b.get("title")))
    : companies;

  companies.forEach((company, index) => {
    const user = company.get("user");

    const item = { 
      className: styles.popoverSideMenuItem,
      key: company.get("domain"), 
      label: (
        <a
          href={company.get("url")}
          title={`${company.get("title")} ${company.get("domain")}`}
          className={styles.popoverSideMenuItemLink}
        >
          <span className={styles.companyTitle}>{company.get("title")}</span>
          <span className={styles.companyDomain}>{company.get("domain")}</span>
        </a>
      ), 
    };

    groupedItems[user]
      ? groupedItems[user].push(item)
      : (groupedItems[user] = [item]);
  });

  return groupedItems;
};

const Menu = props => {
  const { companies, showSideSearch, showAddCompanyButton, company } = props;
  const [currentDomain, setCurrentDomain] = useState("");

  useEffect(
    () => {
      if (companies.length || companies.size) {
        setCurrentDomain(company.get("domain"));
      }
    },
    [companies]
  );

  if (!!companies) {
    let currentLocation = window.location.origin;

    let groupedItems = addMenuItems(companies);

    if (currentLocation.split(":").length > 1) {
      currentLocation = currentLocation.slice(0,currentLocation.lastIndexOf(":")
      );
    }

    const menuGroups = getItemGroups(groupedItems);

    return (
      <React.Fragment>
        <PopoverSideMenu
          selectedItems={currentDomain}
          menuItems={menuGroups}
          showSideSearch={showSideSearch}
          showAddCompanyButton={showAddCompanyButton}
        />
      </React.Fragment>
    );
  } else {
    return null;
  }
};

Menu.propTypes = {
  companies: PropTypes
    .oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  showSideSearch: PropTypes.bool,
  showAddCompanyButton: PropTypes.bool
};

export default React.memo(
  connect(
    Menu,
    { siteUrl: ["vendor", "site"] }
  )
);
