import React from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";

import viewActions from "../../../../../../../actions/viewActions";
import sceneActions from "../../../../../../../actions/sceneActions";
import { connect } from "../../../../../../StateProvider";
import ViewHeader from "./ViewHeader";
import ViewBody from "./ViewBody";

import styles from "./view.less";

class View extends React.Component {
  static propTypes = {
    sceneId: PropTypes.string,
    catalogId: PropTypes.string,
    viewId: PropTypes.string
  };

  loadView(viewId, catalogId, sceneId) {
    if (viewId && catalogId) {
      viewActions.preGetView({ viewId, catalogId, sceneId });
    }
  }

  checkDateFieldsInCatalog = () => {
    const fields = this.props.fields;
    return fields && fields.some(field => field.get("type") === "date");
  };

  getFieldsToRender = () => {
    let { userSettingsFields, fields } = this.props;
    if (!fields) {
      fields = Immutable.List();
    }
    if (!userSettingsFields) {
      return fields;
    }
    const res = fields.filter(field => {
      let visible =
        userSettingsFields &&
        userSettingsFields.getIn([field.get("id"), "visible", "visible"]);

      return visible === undefined ? true : visible;
    });
    return res;
  };

  UNSAFE_componentWillMount() {
    const { viewId, catalogId, sceneId } = this.props;
    this.loadView(viewId, catalogId, sceneId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const prevCatalogId = this.props.catalogId;
    const catalogId = nextProps.catalogId;
    const prevViewId = this.props.viewId;
    const viewId = nextProps.viewId;

    const sceneId = nextProps.sceneId;

    if (
      (catalogId && catalogId !== prevCatalogId) ||
      (viewId && viewId !== prevViewId)
    ) {
      this.loadView(viewId, catalogId, sceneId);
    }
  }

  componentDidUpdate(prevProps) {
    const { shouldClose, isActive, sceneId } = this.props;

    if (shouldClose && isActive) {
      sceneActions.deleteScene(sceneId);
    }
  }

  render() {
    const {
      catalogId,
      sceneId,
      viewId,
      onClose,
      openRecordsInModal,
      isWebForm,
      defaultViewMode
    } = this.props;
    const visibleFields = this.getFieldsToRender();
    const calendarExists = this.checkDateFieldsInCatalog();
    return (
      <div className={styles.container}>
        <ViewHeader
          getFieldsToRender={this.getFieldsToRender}
          catalogId={catalogId}
          sceneId={sceneId}
          viewId={viewId}
          onClose={onClose}
          visibleFields={visibleFields}
          calendarExists={calendarExists}
          openRecordsInModal={openRecordsInModal}
          isWebForm={isWebForm}
          defaultViewMode={defaultViewMode}
        />
        <ViewBody
          catalogId={catalogId}
          sceneId={sceneId}
          viewId={viewId}
          getFieldsToRender={this.getFieldsToRender}
          calendarExists={calendarExists}
          /* если нужно рендерить с крестиком, то это значит что нужно открывать все данные в модальном окне */
          openRecordsInModal={openRecordsInModal}
        />
      </div>
    );
  }
}

export default connect(
  View,
  {
    userSettings: ["userSettings", "catalogs"],
    catalogs: ["catalogs"],
    scenes: ["scenes"]
  },
  (props, { userSettings, catalogs, scenes }) => {
    const { sceneId, catalogId } = props;
    const fields = catalogs.getIn([catalogId, "fields"]);
    const shouldClose = scenes.getIn([sceneId, "shouldClose"]);
    const userSettingsFields =
      catalogId &&
      userSettings.getIn([catalogId, "viewMode", "catalogHistory", "fields"]);
    const defaultViewMode = userSettings.getIn([catalogId, "options", "defaultViewMode"])
    return {
      ...props,
      catalogId,
      fields,
      userSettingsFields,
      defaultViewMode,
      shouldClose,
      scenes
    };
  }
);
