import React from "react";
import { Form, Input, Button, message } from "antd";
import cn from "classnames";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import LoadingSpin from "../../../common/LoadingSpin";
import apiActions from "../../../../actions/apiActions";
import { EMAIL_FIELD, SERVER_ERROR } from "./../../helpers/const";

import styles from "../../styles.less";

class ResetPassForm extends React.Component {
  constructor(_) {
    super();

    this.state = {
      loading: false,
      email: null,
      formSent: false,
    };
    this.formRef = React.createRef();
  }

  changeEmail = () => {
    this.setState({ formSent: false });
    this.props.form.setFields({
      [EMAIL_FIELD]: {
        value: this.state.email
      }
    });
  };

  handleSubmit = async () => {
    const { t } = this.props;
    const email = this.formRef.current.getFieldValue(EMAIL_FIELD);

    const cleanedEmail = email.trim();

    this.setState({ loading: true });
    apiActions.resetPass(cleanedEmail).then(response => {
      this.setState({ loading: false });
      if (response.body.error) {
        const { error } = response.body;
        if (error === SERVER_ERROR.EMAIL_NOT_REGISTERED) {
          message.error(t("auth.errors.emailNotRegistered"));
        } else {
          message.error(t("auth.errors.somethingWrong"));
        }
      }
      if (response.body.result) {
        this.setState({ formSent: true, email });
      } else {
        // this.formRef.current.setFields([{
        //     name: EMAIL_FIELD,
        //     errors: [response.body.error]
        //   }]
        // );
        this.formRef.current.setFields([{
          name: EMAIL_FIELD,
          errors: [t("auth.errors.wrongCode")]
        }]);
      }
    });
  };

  render() {
    const { formSent, email, loading } = this.state;
    const { t } = this.props;

    return (
      <Form 
        ref={this.formRef}
        name="ResetPassForm" 
        className={cn(styles.form, styles.resetForm)} 
        onFinish={this.handleSubmit}
      >
        <h1>{t("auth.resetingPassword")}</h1>
        {formSent ? (
          <React.Fragment>
            <p>
              {t("auth.messages.instructionsSent", { email })}
            </p>

            <Form.Item>
              <div className={styles.buttonGroup}>
                <div
                  key="submit"
                  type="primary"
                  onClick={() => this.changeEmail()}
                  className={styles.changeEmail}
                  htmlType="submit"
                >
                  {t("auth.changeAddress")}
                </div>
                <div>
                  <Link to="/auth/login">{t("auth.enter")}</Link>
                </div>
              </div>
            </Form.Item>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Form.Item
              className={cn(styles.formItem)}
              name={EMAIL_FIELD}
              rules={[{ required: true, message: t("auth.fieldHintMessage")}]}
            >
              <div>
                <div className={styles.formLabel}>{t("auth.username")}</div>
                <Input
                  disabled={loading}
                  className={styles.formField}
                  placeholder=""
                />
              </div>
            </Form.Item>
            <Form.Item>
              <div className={styles.buttonGroup}>
                <Button
                  disabled={loading}
                  tabIndex={0}
                  key="submit"
                  type="primary"
                  className={styles.loginButton}
                  htmlType="submit"
                >
                  {t("auth.resetPassword")}
                </Button>
                <div>
                  <Link disabled={loading} to="/auth/login" className={styles.authLink}>
                    {t("auth.cancel")}
                  </Link>
                </div>
              </div>
              {loading && <div style={{ marginTop: "10px" }}>
                <LoadingSpin spin={loading} />{" "}{t("loadingText")}
              </div>}
            </Form.Item>
          </React.Fragment>
        )}
      </Form>
    );
  }
}

export default withTranslation()(ResetPassForm);
