import React from "react";
import cn from "classnames";
import Immutable from "immutable";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Icon from '../../../../../../../common/UI/Icon';
import userSettingsActions from "../../../../../../../../actions/userSettingsActions";
import DefaultRedirect from "../../../../../../../common/router/DefaultRedirect";
import StateRedirect from "../../../../../../../common/router/StateRedirect";
import NavRoute from "../../../../../../../common/router/Route";
import ViewsMenuItem from "./ViewsMenuItem";
import routes from "../../../../../../../../routes";
import { connect } from "../../../../../../../StateProvider";
import { decode } from "../../../../../../../../utils/queryParams";

import styles from "./viewsMenu.less";

class ViewsMenu extends React.Component {
  static propTypes = {
    catalogId: PropTypes.string,
    views: PropTypes.object
  };

  state = {
    views: Immutable.Map()
  };

  componentDidMount() {
    const { views } = this.props;
    this.getViewsOrderedList(views);
  }

  componentDidUpdate(prevProps) {
    const { views } = this.props;
    const { views: prevViews } = prevProps;

    if (views !== prevViews) {
      this.getViewsOrderedList(views);
    }
  }

  getDefaultVeiw() {
    let { defaultViewId, match } = this.props;

    defaultViewId = defaultViewId && defaultViewId.get("value");
    const matchViewId = _.get(match, ["params", "viewId"]);

    if (matchViewId) {
      return Immutable.Map({ id: matchViewId });
    }

    if (defaultViewId) {
      return Immutable.Map({ id: defaultViewId });
    }

    return Immutable.Map({ id: "0" });
  }

  setDefaultViewId = viewId => {
    const { catalogId } = this.props;

    userSettingsActions.setOption({
      catalogId: catalogId,
      viewMode: undefined,
      option: "defaultViewId",
      value: viewId
    });
  };

  getViewsOrderedList(views) {
    let viewsList = (views && views.valueSeq()) || new Immutable.Seq();
    if (viewsList && viewsList.size > 1) {
      // sort by name keeping 0 view first
      const allRecordsView = viewsList.find(v => v.get("id") === "0");
      const newView = viewsList.find(v => v.get("id") === "$new");

      viewsList = viewsList.filter(
        v => v.get("id") !== "0" && v.get("id") !== "$new"
      );

      // separate and filter coomon and rights views
      let { rightsViews, commonViews } = viewsList.reduce(
        (result, view) => {
          if (!view.get("displayName")) {
            return result;
          } else {
            if (view.get("isAdmin") && view.get("forRights")) {
              result.rightsViews.push(view);
              return result;
            } else {
              result.commonViews.push(view);
              return result;
            }
          }
        },
        { rightsViews: [], commonViews: [] }
      );

      // sort views by display name
      rightsViews = _.sortBy(rightsViews, c => c.get("displayName"));
      commonViews = _.sortBy(commonViews, c => c.get("displayName"));

      // viewsList = viewsList.filter(c => !!c.get("displayName")).sortBy(c => {
      //   if (c.get("isAdmin") && c.get("forRights")) {
      //     return c.get("displayName");
      //   } else {
      //     return " " & c.get("displayName");
      //   }
      // });

      viewsList = [...commonViews, ...rightsViews];
      viewsList = allRecordsView ? [allRecordsView, ...viewsList] : viewsList;
      viewsList = newView ? [...viewsList, newView] : viewsList;
      viewsList = Immutable.fromJS(viewsList);
    }

    this.setState({ views: viewsList });
  }

  toggleVisibleRightsViews = () => {
    const rightsViewsVisible = !this.state.rightsViewsVisible;
    this.setState({ rightsViewsVisible });
  };

  render() {
    const { views } = this.state;
    const newView =
      this.props.views && this.props.views.find(v => v.get("id") === "$new");
    const isNewView = !!newView;
    const defaultView = this.getDefaultVeiw();

    if (!views) {
      return null;
    }

    // right views
    let rightsViewsVisible = this.state.rightsViewsVisible;
    const hasRightsViewsToAdmin =
      views &&
      views.filter(view => view.get("isAdmin") && view.get("forRights")).size;
    return (
      <div className={styles.container}>
        {/* при маунте компонента, отправляет на роут видов */}
        <DefaultRedirect
          route={routes.view}
          params="viewId"
          object={defaultView}
        />

        {/* если переходим на несуществующий вид, то переходим на дефолтный */}
        {
          // переадресация на дефолтный вид
          <NavRoute route={routes.view}>
            {({ match, location }) => {
              // ищем вид из доступных
              const { views } = this.props;

              const matchedViewId = _.get(match, ["params", "viewId"]);
              const viewExist =
                views &&
                matchedViewId &&
                views.find(view => view.get("id") === matchedViewId);

              // фильтры могут задаваться не только видом, но и параметрами строки
              // поэтому в случае если вид еще не создан, но при этом
              // фильтры есть, мы не редиректим на дефолтный вид
              const locationParams = location && decode(location.search);
              const locationFilters = locationParams && locationParams.filters;
              const filtersExist = !_.isEmpty(locationFilters);

              if (views && !viewExist && !filtersExist) {
                return (
                  <StateRedirect
                    route={routes.view}
                    params={{ viewId: defaultView && defaultView.get("id") }}
                  />
                );
              }
              return null;
            }}
          </NavRoute>
        }

        <ul
          className={cn("ant-menu-inline", { [styles.nonNewView]: !isNewView })}
        >
          {(views && views.size) > 0
            ? views.map((view, i) => {
                if (
                  view.get("isAdmin") &&
                  view.get("forRights") &&
                  !rightsViewsVisible
                ) {
                  return null;
                }
                return (
                  <ViewsMenuItem
                    key={view.get("id")}
                    catalogId={this.props.catalogId}
                    sceneId={this.props.sceneId}
                    rights={view.get("forRights")}
                    view={view}
                    catalogPrivilegeCode={this.props.catalogPrivilegeCode}
                    onClick={() => this.setDefaultViewId(view.get("id"))}
                  />
                );
              })
            : null}
          {hasRightsViewsToAdmin && !rightsViewsVisible ? (
            <li
              className={cn(styles.menuItem, styles.menuItemShowRightViews)}
              onClick={this.toggleVisibleRightsViews}
            >
              <div className="ant-menu-item">
                <Icon type={"icon edition-55"} className={styles.forRights} />
                {this.props.t("views.list.showRightViews")}
              </div>
            </li>
          ) : null}
        </ul>
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(
    connect(
      ViewsMenu,
      {
        userSettings: ["userSettings", "catalogs"],
        scenes: ["scenes"]
      },
      (props, { userSettings, scenes }) => {
        const { catalog, sceneId } = props;

        /* сделано для добавления нового вида из сцены, так как в каталог новый вид не сохраяняется, а список видов отображается по видам каталога */
        const sceneViews =
          scenes.getIn([sceneId, "views"]) || Immutable.Map({});

        let views = catalog && catalog.get("views");
        const viewsLoaded = catalog && catalog.get("viewsLoaded");
        views = viewsLoaded && views && views.merge(sceneViews);

        const catalogId = catalog && catalog.get("id");
        const defaultViewId = userSettings.getIn([
          catalogId,
          "options",
          "defaultViewId"
        ]);

        const catalogPrivilegeCode = catalog.get("privilegeCode");

        return {
          defaultViewId,
          catalogId,
          views,
          catalogPrivilegeCode,
          viewsLoaded,
          ...props
        };
      }
    )
  )
);
