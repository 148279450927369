// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cards__container___mnQHE {\n  width: 100%;\n  height: 100%;\n  background-color: #f7f8fa;\n}\n.cards__header___1FCXu {\n  min-height: 35px;\n  padding-left: 15px;\n  padding-right: 15px;\n  padding-top: 5px;\n  padding-bottom: 5px;\n  background-color: #f7f8fa;\n}\n.cards__body___4wWa- {\n  height: 100%;\n  overflow-y: hidden;\n  overflow-x: hidden;\n  background-color: #eff1f4;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Cards/cards.less"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;AADF;AAIA;EACE,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;AAFF;AAKA;EACE,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;AAHF","sourcesContent":["@import (reference) \"../../styles/helpers/index.less\";\n\n.container {\n  width: 100%;\n  height: 100%;\n  background-color: @BG_WORKPLACE;\n}\n\n.header {\n  min-height: @RECORD_DATA_HEADER;\n  padding-left: 3 * @PADDING_BASE / 2;\n  padding-right: 3 * @PADDING_BASE / 2;\n  padding-top: @PADDING_BASE / 2;\n  padding-bottom: @PADDING_BASE / 2;\n  background-color: @BG_WORKPLACE;\n}\n\n.body {\n  height: 100%;\n  overflow-y: hidden;\n  overflow-x: hidden;\n  background-color: @BG_HEADER;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "cards__container___mnQHE",
	"header": "cards__header___1FCXu",
	"body": "cards__body___4wWa-"
};
export default ___CSS_LOADER_EXPORT___;
